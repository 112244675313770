import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import { useDispatch } from "react-redux";

const ScheduleTable = ({ slabs = [], bookingId, setPaymentSlabDetails }) => {
  const [data, setData] = useState([]);
  const [markAsPaidIndex, setMarkAsPaidIndex] = useState(0);
  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function updateData() {
      // Initialize newData with default values
      const newData = slabs.map((slab) => ({
        scheduleId: slab?.schedule,
        slabId: slab?._id,
        bookingId: bookingId,
        title: slab?.title || "",
        description: slab?.description || "",
        paymentPercentage: slab?.paymentPercentage || 0,
        cumulativePercentage: slab?.cumulativePercentage || 0,
        type: slab?.type,
        dueDate: slab?.dueDate,
        isPaid: slab?.isPaid || false,
        isCompleted: slab?.isCompleted || false,
      }));
      
      // Fetch data from API
      let fetchedData = [];
      for (let slab of slabs) {
        const payload = {
          scheduleId: slab?.schedule,
          slabId: slab?._id,
          bookingId: bookingId,
          title: slab?.title,
        };

        let data = await Api.post(
          "listing/booking/schedule/slab/details/create",
          payload
        );

        if (data) {
          fetchedData.push(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Something went wrong",
            },
          });
        }
      }

      // Update the respective fields in newData
      const updatedData = newData.map((slab) => {
        const fetchedComponent = fetchedData.find(
          (item) => item.slabId === slab.slabId
        );
        if (fetchedComponent) {
          return {
            ...slab,
            _id: fetchedComponent._id,
            isPaid: fetchedComponent?.isPaid || false,
          };
        }
        return slab;
      });

      setData(updatedData);
      setLoading(false);
    }

    if (slabs.length > 0) {
      updateData();
    }
  }, [slabs]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPaymentSlabDetails(data);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [data]);

  const markAsPaidHandler = async (index) => {
    if (!index && index !== 0) return;

    const slab = data[index];
    const responseData = await Api.patch(
      "listing/booking/schedule/slab/mark-as-paid",
      {
        slabDetailsId: slab._id || "",
      }
    );
    if (responseData.data) {
      // TODO: update the data

      setData((prevData) => {
        // Create a new data array with the updated slab
        const newData = prevData.map((item) => {
          if (item._id === slab._id) {
            return { ...item, isPaid: true };
          }
          return item;
        });
        return newData;
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginBottom: "20px",
      }}
    >
      <Typography variant="h6">Slab Table</Typography>
      <TableContainer
        columns={[
          "TYPE",
          "TITLE",
          "DESCRIPTION",
          "PERCENTAGE OF PAYMENT",
          "AMOUNT PAID",
        ]}
        loading={loading}
        data={slabs}
        containerHeight="auto"
        headerAlignment={"center"}
      >
        {data.map((slab, index) => {
          if (slab.type !== "aggrement") return null;
          return (
            <tr key={index} style={{ borderBottom: "1px solid #e0e0e0" }}>
              <td style={{ padding: "16px", textAlign: "center" }}>
                {slab?.type?.toUpperCase() || "Untitled"}
              </td>
              <td style={{ padding: "16px", textAlign: "center" }}>
                {slab?.title || "Untitled"}
              </td>
              <td style={{ padding: "16px", textAlign: "center" }}>
                {slab?.description || "N/A"}
              </td>
              <td style={{ padding: "16px", textAlign: "center" }}>
                <span
                  style={{
                    backgroundColor: "#e6f4ea",
                    color: "#1e8e3e",
                    padding: "4px 8px",
                    borderRadius: "12px",
                    fontSize: "14px",
                  }}
                >
                  {slab?.paymentPercentage || "0"}%
                </span>
              </td>
              <td style={{ padding: "16px", textAlign: "center" }}>
                {slab.isPaid ? (
                  <span
                    style={{
                      backgroundColor: "#e6f4ea",
                      color: "#1e8e3e",
                      padding: "4px 8px",
                      borderRadius: "12px",
                      fontSize: "14px",
                    }}
                  >
                    Paid
                  </span>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setMarkAsPaidIndex(index);
                      setIsComfirmationDialogOpen(true);
                    }}
                  >
                    Mark as Paid
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            setPaymentSlabDetails(data);
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: true,
                message: "Slab Details updated",
              },
            });
          }}
        >
          Update
        </Button>
      </Box>
      <ConfirmationDialog
        title={"Are you absolutely sure?"}
        cancelButtonText={"cancel"}
        cancelListener={() => setIsComfirmationDialogOpen(false)}
        message={"Are you sure you want to mark this Slab as Complete?"}
        open={isComfirmationDialogOpen}
        setOpen={setIsComfirmationDialogOpen}
        successButtonText={"Confirm"}
        successListener={() => markAsPaidHandler(markAsPaidIndex)}
      />
    </Box>
  );
};

export default ScheduleTable;
