import React, { useEffect } from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import { Box } from "@mui/material";
import ProjectHomeListings from "../team/Project.Home.Listings";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";

const Listings = () => {
  const { profileId } = useParams();
  console.log(profileId);
  const [project, setProject] = React.useState([]);
  const dispatch = useDispatch();

  const fetchProject = async () => {
    const data = await Api.post(`/project/get/${profileId}`);
    if (data) {
      console.log(data);
      setProject(data.project);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    if (profileId) {
      fetchProject();
    }
  }, [profileId]);

  console.log(project);

  return (
    <Box>
      <ProjectHomeListings projectId={project._id} />
    </Box>
  );
};

export default Listings;
