import React, { useCallback, useEffect, useMemo, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import HorizBox from "../../styled/generic/HorizBox";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import TextField from "../../styled/generic/TextField";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import BankAccountPicker from "../../styled/generic/BankAccountPickerDropdown";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import ListingPicker from "../../styled/generic/ListingPicker";
import ProjectBlockAndUnitPickerDropdown from "../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../styled/generic/DatePicker";
import dayjs from "dayjs";
import UserSearcher from "../../styled/generic/UserSearcher";
import { GoPlus } from "react-icons/go";
import { BiTrash } from "react-icons/bi";
import { AccountBalance, Close, EditOutlined } from "@mui/icons-material";
import PaymentTable from "./PaymentTable";
import ScheduleTable from "./ScheduleTable";
import SearchEntityDialog from "../../styled/CommonComponents/SearchEntityDrawer";
import { object, string, date, mixed } from "yup";
import { allStatusOptions } from "../../../helpers/allStatuses";
import { Add } from "@material-ui/icons";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import { getProfileByWallet } from "../../finance2o/commonComponent/transaction/api";

let bookingSchema = object({
  project: object().required(),
  listing: string().required(),
  projectBlock: object().required(),
  bookingStatus: string().required(),
  registrationDate: date().required(),
  unit: mixed().required(),
  closingManager: string(),
  bookingDate: date().required(),
  customerProfile: string().required(),
});

const BookingEdit = () => {
  const history = useHistory();
  const { bookingId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // States for the form fields
  const [selectedListing, setSelectedListing] = useState("");
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const [bookingStatus, setBookingStatus] = useState("Pending");
  const [sanctionedUnsanctioned, setSanctionedUnsanctioned] =
    useState("Sanctioned");
  const [registrationDate, setRegistrationDate] = useState(dayjs());
  const [unitType, setUnitType] = useState("1BHK");
  const [closingManager, setClosingManager] = useState();
  const [bookingDate, setBookingDate] = useState(dayjs());
  const [carParking, setCarParking] = useState(false);
  const [targets, setTargets] = useState("");
  const [basicValue, setBasicValue] = useState("");
  const [ratePSF, setRatePSF] = useState("");
  const [regNo, setRegNo] = useState("");
  const [areaCarpet, setAreaCarpet] = useState("");
  const [pdfdsf, setPdfdsf] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [bankerName, setBankerName] = useState("");
  const [bankName, setBankName] = useState("");
  const [infrastructureCharges, setInfrastructureCharges] = useState("");
  const [bankerContactNumber, setBankerContactNumber] = useState("");
  const [infraBalance, setInfraBalance] = useState("");
  const [infraReceived, setInfraReceived] = useState("");
  const [possessionDate, setPossessionDate] = useState("");
  const [gstOnInfra, setGstOnInfra] = useState("");
  const [tdsReminderEmail, setTdsReminderEmail] = useState("");
  const [saleableArea, setSaleableArea] = useState(0);
  const [constructionFinanceNOC, setConstructionFinanceNOC] = useState("");
  const [carParkDevelopmentCharges, setCarParkDevelopmentCharges] =
    useState("");
  const [totalReraCarpet, setTotalReraCarpet] = useState("");
  const [newMapping, setNewMapping] = useState("");

  const [selectedProjectBlock, setSelectedProjectBlock] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [booking, setBooking] = useState();
  const [initialListing, setInitialListing] = useState();
  const [initialBlock, setInitialBlock] = useState();
  const [initialUnit, setInitialUnit] = useState();
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState();
  const [initialPaymentTable, setInitialPaymentTable] = useState();

  const [schedules, setSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [selectedSlab, setSelectedSlab] = useState();
  const [initialPaymentSchedule, setInitialPaymentSchedule] = useState();
  const [initialPaymentSlab, setInitialPaymentSlab] = useState();
  const [showUpdatePalDrawer, setShowUpdatePalDrawer] = useState(false);
  const [slabs, setSlabs] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [owner, setOwner] = useState(null);
  const [role, setRole] = useState("");
  const [ownerSeletor, setOwnerSeletor] = useState("organization");
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationProfileId, setOrganizationProfileId] = useState("");
  const [userList, setUserList] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [paymentComponents, setPaymentComponents] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [paymentScheduleSlabs, setPaymentScheduleSlabs] = useState([]);
  const [area, setArea] = useState(0);
  const [paymentSlabDetails, setPaymentSlabDetails] = useState([]);
  const [status, setStatus] = useState("Draft");
  const [initialStatus, setInitialStatus] = useState("Draft");
  const [accountManagers, setAccountManagers] = useState([]);
  const [project, setProject] = useState("");
  const [brokers, setBrokers] = useState([]);
  const [isBrokerInvolved, setIsBrokerInvolved] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeams] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [profile, setProfile] = useState(null);
  const [initialBank, setInitialBank] = useState(null);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const fetchBillingAccounts = async () => {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      {
        sharedIds: [profile._id],
      }
    );
    if (response) {
      setBillingAccounts(response.billingAccounts);
    } else {
      console.log("Error fetching billing accounts");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error fetching billing accounts",
        },
      });
    }
  };

  useEffect(() => {
    if (profile) {
      fetchBillingAccounts();
    }
  }, [profile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await getProfileDataPopulate({
          profileId: project?.profile?._id,
        });

        console.log("data", data.parent.wallet._id);
        data = await getProfileByWallet({
          walletId: data.parent.wallet._id,
        });
        data = await getProfileDataPopulate({
          profileId: data,
        });
        setProfile(data);
        console.log("project", data);
      } catch (error) {
        console.log("Error fetching profile data populate", error);
      }
    };
    if (project?.profile) {
      // wallet
      fetchData();
    }
  }, [project]);

  console.log("project", profile);

  const PAYMENT_STATUS_OPTIONS = [
    "Draft",
    "Checking",
    "Confirmed",
    "In Progress",
    "Cancelled",
    "Paid",
  ];

  const agreementValue = useMemo(() => {
    return paymentDetails?.reduce((total, component) => {
      if (component?.componentRef?.aggrementBased) {
        return total + parseInt(component?.chargeAmount);
      }
      return total;
    }, 0);
  }, [paymentDetails]);

  function calculateBrokerComission(index) {
    if (!brokers.length > 0) {
      return;
    }

    const updateBroker = brokers[index];
    if (updateBroker?.isUserInput) {
      return;
    }

    updateBroker.chargeAmount =
      (agreementValue * parseInt(updateBroker?.chargePercentage)) / 100;
    setBrokers((prev) =>
      prev.map((broker, i) => (i === index ? updateBroker : broker))
    );
  }

  useEffect(() => {
    brokers.map((_, i) => calculateBrokerComission(i));
  }, [...brokers.map((broker) => broker.chargePercentage), paymentDetails]);
  console.log("selectedBankAccount", selectedBankAccount);
  console.log("selectedBillingAccount", selectedBillingAccount?._id);
  const updateBooking = async () => {
    const payload = {
      listing: selectedListing,
      projectBlock: selectedProjectBlock,
      unit: selectedUnit,
      selectedBookingType,
      organization: organization?._id,
      bankAccountId: selectedBankAccount,
      billingAccount: selectedBillingAccount?._id,
      team: selectedTeam,
      bookingStatus,
      project: project?._id,
      agreementValue,
      paymentDetails: paymentDetails.map((x) => x?._id || ""),
      slabDetails: paymentSlabDetails.map((x) => x?._id || ""),
      area,
      status: status,
      sanctionedUnsanctioned: sanctionedUnsanctioned === "Sanctioned",
      registrationDate,
      unitType,
      closingManager,
      bookingDate,
      accountManagers: accountManagers.map((manager) => manager?.profile),
      carParking,
      isBrokerInvolved,
      targets,
      paymentTable: selectedTable,
      schedule: selectedSchedule,
      slab: selectedSlab,
      customerProfile: customer?.finrel?.parent?._id || customer?._id,
      ownerProfile:
        owner?.finrel?.parent?._id ||
        owner?.profile ||
        organizationProfileId ||
        owner?._id,
    };

    const validation = await bookingSchema
      .validate({
        project,
        listing: selectedListing,
        projectBlock: selectedProjectBlock,
        bookingDate,
        status: status,
        unit: selectedUnit,
        selectedBookingType,
        bookingStatus,
        unitType,
        registrationDate,
        customerProfile: customer?.finrel?.parent?._id || customer?._id,
      })
      .catch((error) => {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: error.message,
          },
        });
      });
    if (!validation) {
      return;
    }
    const data = await Api.post("/listing/booking/update", {
      bookingId: bookingId,
      bookingData: payload,
      brokerData: [
        ...brokers.map((item) => ({
          profile: item?.profile?.finrel?.parent?._id || item?.profile?._id,
          chargeAmount: item?.chargeAmount,
          chargePercentage: item?.chargePercentage,
          transaction: item?.transaction,
          isTransaction: item?.transaction ? true : false,
        })),
      ],
      aggrementData: {
        basicValue,
        ratePSF,
        regNo,
        areaCarpet,
        pdfdsf,
        registrationStatus,
        bankerName,
        bankName,
        infrastructureCharges,
        bankerContactNumber,
        infraBalance,
        infraReceived,
        possessionDate,
        gstOnInfra,
        tdsReminderEmail,
        saleableArea,
        constructionFinanceNOC,
        carParkDevelopmentCharges,
        totalReraCarpet,
        newMapping,
      },
    });
    if (data) {
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Booking updated successfully!",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getBooking = async () => {
    setPageLoading(true);
    if (!bookingId) {
      return;
    }
    const { data } = await Api.post("/listing/booking/get-by-id", {
      bookingId: bookingId,
    });
    if (data) {
      setBooking(data);
      setSelectedListing(data?.listing?._id);
      setInitialListing(data?.listing);
      setSelectedUnit(data?.unit?._id);
      setOrganization(data?.organization?._id);
      setProject(data?.project?._id);
      setInitialUnit(data?.unit);
      setInitialBank(data?.bankAccountId);
      setSelectedBillingAccount(data?.billingAccount);
      setInitialBlock(data?.projectBlock);
      setSelectedBookingType(data?.bookingType?._id);
      setBookingStatus(data?.bookingStatus);
      setInitialPaymentSchedule(data?.schedule);
      setInitialPaymentSlab(data?.slab);
      setSanctionedUnsanctioned(
        data?.sanctionedUnsanctioned ? "Sanctioned" : "Unsanctioned"
      );
      setRegistrationDate(dayjs(data?.registrationDate));
      setUnitType(data?.unitType);
      setBookingDate(data?.bookingData);
      setCustomer(data.customerProfile);

      setSelectedTeams(data?.team);
      setAccountManagers(
        data?.accountManagers.map((item) => ({
          _id: item._id,
          displayName: item?.parent?.displayName,
          displayPicture: item?.parent?.displayPicture,
          profile: item?._id,
        }))
      );
      setInitialStatus((prev) =>
        data?.status?.length > 0 ? data?.status : prev
      );
      setSelectedSchedule(data?.schedule?._id);
      setProject(data?.project);
      setIsBrokerInvolved(data?.isBrokerInvolved);
      setSelectedTable(data?.paymentTable?._id);
      setSelectedSchedule(data?.paymentSchedule?._id);
      setOwner(data?.ownerProfile);
      setClosingManager(data?.closingManager);
      setBookingDate(dayjs(data?.bookingDate));
      setCarParking(data?.carParking);
      setTargets(data?.targets);
      setBasicValue(data?.aggrement?.basicValue);
      setRatePSF(data?.aggrement?.ratePSF);
      setRegNo(data?.aggrement?.regNo);
      setAreaCarpet(data?.aggrement?.areaCarpet);
      setPdfdsf(data?.aggrement?.pdfdsf);
      setRegistrationStatus(data?.aggrement?.registrationStatus);
      setBankerName(data?.aggrement?.bankerName);
      setBankName(data?.aggrement?.bankName);
      setInfrastructureCharges(data?.aggrement?.infrastructureCharges);
      setBankerContactNumber(data?.aggrement?.bankerContactNumber);
      setInfraBalance(data?.aggrement?.infraBalance);
      setInfraReceived(data?.aggrement?.infraReceived);
      setPossessionDate(data?.aggrement?.possessionDate);
      setGstOnInfra(data?.aggrement?.gstOnInfra);
      setTdsReminderEmail(data?.aggrement?.tdsReminderEmail);
      setSaleableArea(data?.aggrement?.saleableArea);
      setConstructionFinanceNOC(data?.aggrement?.constructionFinanceNOC);
      setCarParkDevelopmentCharges(data?.aggrement?.carParkDevelopmentCharges);
      setTotalReraCarpet(data?.aggrement?.totalReraCarpet);
      setNewMapping(data?.aggrement?.newMapping);
      setInitialPaymentTable(data?.paymentTable);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getBooking().finally(() => {
      setPageLoading(false);
    });
  }, []);

  const searchUsers = async () => {
    const response = await Api.post(`/users/get/pagination`, {
      name: userSearch,
    });
    if (response.data.length > 0) {
      setUserList(response.data);
    } else {
      setUserList([]);
    }
  };

  useEffect(() => {
    if (!accountManagers.length > 0 && userList.length > 0) {
      setAccountManagers((prev) => [
        userList.find((item) => item._id === user._id),
      ]);
    }
  }, [accountManagers, userList]);

  useEffect(() => {
    const userSearchTimeout = setTimeout(() => {
      searchUsers();
    }, 800);
    return () => {
      clearTimeout(userSearchTimeout);
    };
  }, [userSearch]);

  useEffect(() => {
    const searchOrganization = async () => {
      const org = await Api.post("/organization/get", {
        user: user._id,
      });
      setOrganizationList(org);
    };

    if (ownerSeletor === "organization") {
      searchOrganization();
    } else if (ownerSeletor === "user") {
      searchUsers();
    }
  }, [ownerSeletor]);

  const getTables = useCallback(async () => {
    try {
      if (!booking) {
        return;
      }
      const { data } = await Api.post("/listing/booking/payment/table/get", {
        libraryId: booking?.library,
      });
      if (data) {
        setTables(data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [booking]);

  useEffect(() => {
    getTables();
  }, [booking]);

  const getSchedules = async () => {
    if (!booking) {
      return;
    }
    const { data } = await Api.post("/listing/booking/schedule/get", {
      libraryId: booking?.library,
      tableId: selectedTable,
    });
    if (data) {
      setSchedules(data);
    } else
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
  };

  useEffect(() => {
    getSchedules();
  }, [booking, selectedTable]);

  useEffect(() => {
    let schedule = schedules.find(
      (item) => String(item?._id) === String(selectedSchedule)
    );
    setSlabs(schedule?.slabs || []);
  }, [selectedSchedule]);

  const getPaymentTableComponents = async () => {
    if (!selectedTable) {
      return;
    }

    const data = await Api.post("listing/booking/payment/component/get", {
      tableId: selectedTable,
    });
    if (data) {
      setPaymentComponents(data.data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }
  };

  useEffect(() => {
    if (selectedTable) {
      getPaymentTableComponents();
    }
  }, [selectedTable]);

  const getPaymentScheduleComponents = async () => {
    if (!selectedSchedule) {
      return;
    }
    const data = await Api.post("listing/booking/schedule/get-by-id", {
      scheduleId: selectedSchedule,
    });
    if (data) {
      setPaymentScheduleSlabs(data.data.slabs);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }
  };

  useEffect(() => {
    getPaymentScheduleComponents();
  }, [selectedSchedule]);

  const getTeams = async () => {
    try {
      const { data } = await Api.post(`/issue/template/getAll`, {
        profileId: user?.profile,
      });
      if (data) {
        const filterTeams = data.filter((item) => item?.isCRM === false);
        setTeams(filterTeams);
      } else {
        setTeams([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.profile) {
      getTeams();
    }
  }, [user]);

  console.log("billing acount", selectedBillingAccount);

  const handleBrokerTransactionSubmit = async (broker) => {
    console.log("broker", broker);
    return;
    const payload = {};
    const data = await Api.post(
      "/listing/booking/broker-transaction/create",
      payload
    );
    if (data) {
      console.log("transaction data", data);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Broker transaction created successfully!",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }
  };

  const memoizedPaymentTable = useMemo(
    () => (
      <PaymentTable
        setComponents={setPaymentComponents}
        components={paymentComponents}
        setPaymentDetails={setPaymentDetails}
        bookingId={bookingId}
        slabs={paymentScheduleSlabs}
        area={parseInt(area)}
      />
    ),
    [paymentComponents, paymentScheduleSlabs, bookingId, area]
  );

  return (
    <>
      <StandardContainer
        showAppBar={true}
        loading={pageLoading}
        appBarTitle="Booking"
        appBarActions={
          <Select
            size="small"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            fullWidth={true}
            sx={{
              borderRadius: "8px",
              minWidth: "200px",
            }}
          >
            {allStatusOptions["MultiFamilySalesBooking"][initialStatus]?.map(
              (status, index) => (
                <MenuItem value={status} key={index}>
                  {status}
                </MenuItem>
              )
            )}
            {PAYMENT_STATUS_OPTIONS.map((status, index) => (
              <MenuItem
                value={status}
                key={index}
                sx={{
                  display: "none",
                }}
              >
                {status}
              </MenuItem>
            ))}
          </Select>
        }
      >
        <StandardAppContainerRounded>
          <SpaceBetween
            marginTop={"1rem"}
            sx={{
              alignItems: "flex-start",
            }}
            left={
              <FormBox label="Project">
                <Box sx={{ py: 1 }}>
                  {initialListing ? (
                    <HorizBox
                      sx={{
                        borderRadius: "8px",
                        border: "1px solid #D8DEE4",
                        backgroundColor: "#F5F7FA",
                        display: "inline-flex",
                        px: "10px",
                        py: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "600",
                          color: "#54555A",
                        }}
                      >
                        {initialListing?.title || "Untitled"}
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setInitialListing()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M13.5 4.5L4.5 13.5"
                            stroke="#717171"
                            stroke-width="1.125"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.5 4.5L13.5 13.5"
                            stroke="#717171"
                            stroke-width="1.125"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </HorizBox>
                  ) : (
                    <ListingPicker
                      project={project}
                      setProject={setProject}
                      selectedListing={selectedListing}
                      setSelectedListing={setSelectedListing}
                      setOrganization={setOrganization}
                      hideLabel={true}
                    />
                  )}
                </Box>
              </FormBox>
            }
            right={
              <FormBox label="Project Block & Units">
                <Box sx={{ py: 1 }}>
                  {initialBlock && initialUnit ? (
                    <HorizBox>
                      <HorizBox
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D8DEE4",
                          backgroundColor: "#F5F7FA",
                          display: "inline-flex",
                          px: "10px",
                          py: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            color: "#54555A",
                          }}
                        >
                          {initialBlock?.name}
                        </span>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setInitialBlock()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M13.5 4.5L4.5 13.5"
                              stroke="#717171"
                              stroke-width="1.125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.5 4.5L13.5 13.5"
                              stroke="#717171"
                              stroke-width="1.125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </HorizBox>
                      <HorizBox
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D8DEE4",
                          backgroundColor: "#F5F7FA",
                          display: "inline-flex",
                          px: "10px",
                          py: "10px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            color: "#54555A",
                          }}
                        >
                          {initialUnit?.name}
                        </span>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setInitialUnit()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M13.5 4.5L4.5 13.5"
                              stroke="#717171"
                              stroke-width="1.125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.5 4.5L13.5 13.5"
                              stroke="#717171"
                              stroke-width="1.125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </HorizBox>
                    </HorizBox>
                  ) : (
                    <ProjectBlockAndUnitPickerDropdown
                      listingId={selectedListing}
                      hideLabel={true}
                      selectedProjectBlock={selectedProjectBlock}
                      setSelectedProjectBlock={setSelectedProjectBlock}
                      selectedProjectUnit={selectedUnit}
                      setSelectedProjectUnit={setSelectedUnit}
                    />
                  )}
                </Box>
              </FormBox>
            }
          />

          <SpaceBetween
            marginTop={"1rem"}
            sx={{
              alignItems: "flex-start",
            }}
            left={
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <FormBox label={"Add Customer"}>
                  <Box
                    sx={{
                      width: "100%",
                      border: "2px solid #e5e7eb",
                      padding: "10px",
                      borderRadius: "15px",
                    }}
                  >
                    {customer ? (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          border: "2px solid #e5e7eb",
                          padding: "10px",
                          borderRadius: "15px",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "10px",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: "45px",
                              height: "45px",
                            }}
                            src={customer?.parent?.displayPicture?.url}
                            alt="A"
                          />
                          <Box>
                            <Typography variant="h6">
                              {customer?.parent?.displayName}
                            </Typography>
                            <Typography variant="body2">
                              {customer?.parent?.phone}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setShowUpdatePalDrawer(true);
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setCustomer(null);
                            }}
                          >
                            <BiTrash />
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<GoPlus />}
                        sx={{
                          width: "100%",
                          padding: "10px 20px",
                          borderRadius: "15px",
                        }}
                        onClick={() => {
                          setShowLeadsDrawer(true);
                          setRole("Customer");
                        }}
                      >
                        Add Customer
                      </Button>
                    )}
                  </Box>
                </FormBox>
                <FormBox label={"Add Owner"}>
                  <Box
                    sx={{
                      width: "100%",
                      border: "2px solid #e5e7eb",
                      padding: "10px",
                      borderRadius: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "flex-start",
                        border: "2px solid #e5e7eb",
                        padding: "10px",
                        borderRadius: "15px",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Box>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Type
                        </FormLabel>
                        <RadioGroup
                          row
                          defaultValue={ownerSeletor}
                          onChange={(e) => setOwnerSeletor(e.target.value)}
                        >
                          <FormControlLabel
                            value="organization"
                            control={<Radio />}
                            label="Organization"
                          />
                          <FormControlLabel
                            value="user"
                            control={<Radio />}
                            label="User"
                          />
                          <FormControlLabel
                            value="pal"
                            control={<Radio />}
                            label="Create new"
                          />
                        </RadioGroup>
                      </Box>

                      {!owner && ownerSeletor === "organization" && (
                        <Select
                          value={organizationProfileId}
                          onChange={(e) =>
                            setOrganizationProfileId(e.target.value)
                          }
                          fullWidth={true}
                        >
                          {organizationList &&
                            organizationList.map((org) => (
                              <MenuItem value={org?.profile}>
                                {org?.displayName ?? "Undefined"}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                      {!owner && ownerSeletor === "user" && (
                        <Autocomplete
                          disablePortal
                          options={userList}
                          disableClearable={true}
                          inputValue={userSearch}
                          onInputChange={(event, value) => {
                            setUserSearch(value);
                          }}
                          value={selectedUser}
                          onChange={(event, newValue) => {
                            setSelectedUser(newValue);
                          }}
                          getOptionLabel={(option) => option?.displayName ?? ""}
                          renderInput={(params) => (
                            <TextField {...params} label="Users" />
                          )}
                          renderOption={(props, option) => {
                            return (
                              <MenuItem
                                {...props}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                onClick={() => setOwner(option)}
                              >
                                <Avatar
                                  sx={{ width: "45px", height: "45px" }}
                                  src={option?.displayPicture?.url}
                                  alt="A"
                                />
                                <Typography variant="h6">
                                  {option?.displayName}
                                </Typography>
                              </MenuItem>
                            );
                          }}
                          fullWidth={true}
                        />
                      )}

                      {!owner && ownerSeletor === "pal" && (
                        <Button
                          variant="outlined"
                          startIcon={<GoPlus />}
                          sx={{
                            width: "100%",
                            padding: "10px 20px",
                            borderRadius: "15px",
                          }}
                          onClick={() => {
                            setRole("Owner");
                            setShowLeadsDrawer(true);
                          }}
                        >
                          Add Owner
                        </Button>
                      )}

                      {owner && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "2px solid #e5e7eb",
                            padding: "10px",
                            borderRadius: "15px",
                            marginBottom: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              gap: "5px",
                            }}
                          >
                            <Avatar
                              sx={{
                                width: "45px",
                                height: "45px",
                              }}
                              src={
                                owner?.parent?.displayPicture?.url ||
                                owner?.displayPicture?.url
                              }
                              alt="A"
                            />
                            <Box>
                              <Typography variant="h6">
                                {owner?.parent?.displayName ??
                                  owner?.displayName}
                              </Typography>
                              <Typography variant="body2">
                                {owner?.parent?.phone}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setShowUpdatePalDrawer(true);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOwner(null);
                              }}
                            >
                              <BiTrash />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </FormBox>
              </Box>
            }
            right={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <FormBox label={"Add Account Managers"}>
                  <Box
                    sx={{
                      width: "100%",
                      border: "2px solid #e5e7eb",
                      padding: "10px",
                      borderRadius: "15px",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      options={userList}
                      disableClearable={true}
                      inputValue={userSearch}
                      onInputChange={(event, value) => {
                        setUserSearch(value);
                      }}
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                      }}
                      getOptionLabel={(option) => option?.displayName ?? ""}
                      renderInput={(params) => (
                        <TextField {...params} label="Users" />
                      )}
                      renderOption={(props, option) => {
                        if (accountManagers.includes({ _id: option._id })) {
                          return null;
                        }
                        return (
                          <MenuItem
                            {...props}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                            onClick={() =>
                              setAccountManagers((prev) => [...prev, option])
                            }
                          >
                            <Avatar
                              sx={{ width: "45px", height: "45px" }}
                              src={option?.displayPicture?.url}
                              alt="A"
                            />
                            <Typography variant="h6">
                              {option?.displayName}
                            </Typography>
                          </MenuItem>
                        );
                      }}
                      fullWidth={true}
                    />
                    {accountManagers.length > 0 &&
                      accountManagers.map((manager, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "2px solid #e5e7eb",
                            padding: "10px",
                            borderRadius: "15px",
                            marginTop: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              gap: "10px",
                            }}
                          >
                            <Avatar
                              sx={{
                                width: "45px",
                                height: "45px",
                              }}
                              src={manager?.displayPicture?.url}
                              alt="A"
                            />
                            <Box>
                              <Typography variant="h6">
                                {manager?.displayName}
                              </Typography>
                              <Typography variant="body2">
                                {manager?.phone}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setShowUpdatePalDrawer(true);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setAccountManagers(
                                  accountManagers.filter(
                                    (x) => x._id !== manager._id
                                  )
                                );
                              }}
                            >
                              <BiTrash />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </FormBox>
                <Box>
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isBrokerInvolved}
                          value={isBrokerInvolved}
                          onChange={() => setIsBrokerInvolved((prev) => !prev)}
                        />
                      }
                      label="Add Broker"
                    />
                  </MenuItem>
                  {isBrokerInvolved && (
                    <FormBox label={"Add Broker"}>
                      <Box
                        sx={{
                          width: "100%",
                          border: "2px solid #e5e7eb",
                          padding: "10px",
                          borderRadius: "15px",
                        }}
                      >
                        {brokers.map((broker, index) => (
                          <Box
                            key={index}
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              border: "2px solid #e5e7eb",
                              padding: "10px",
                              borderRadius: "15px",
                              gap: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  gap: "10px",
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: "45px",
                                    height: "45px",
                                  }}
                                  src={
                                    broker?.profile?.parent?.displayPicture?.url
                                  }
                                  alt="A"
                                />
                                <Box>
                                  <Typography variant="h6">
                                    {broker?.profile?.parent?.displayName}
                                  </Typography>
                                  <Typography variant="body2">
                                    {broker?.profile?.parent?.phone}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    setShowUpdatePalDrawer(true);
                                    setRole("Broker");
                                  }}
                                >
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setBrokers((prev) =>
                                      prev.filter(
                                        (item) =>
                                          item.profile._id !==
                                          broker.profile._id
                                      )
                                    );
                                  }}
                                >
                                  <BiTrash />
                                </IconButton>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Tooltip
                                title={
                                  "Mark Checked if you want to calculate manually"
                                }
                                enterDelay={2000}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={broker?.isUserInput}
                                      onChange={(e) =>
                                        setBrokers(
                                          brokers.map((b, i) =>
                                            i === index
                                              ? {
                                                  ...b,
                                                  isUserInput: e.target.checked,
                                                }
                                              : b
                                          )
                                        )
                                      }
                                    />
                                  }
                                  label="Manually"
                                />
                              </Tooltip>
                              <TextField
                                placeholder="Comission percentage"
                                type="number"
                                size="small"
                                value={broker?.chargePercentage || 0}
                                prefix="$"
                                onChange={(e) => {
                                  calculateBrokerComission(index);
                                  setBrokers(
                                    brokers.map((b, i) =>
                                      i === index
                                        ? {
                                            ...b,
                                            chargePercentage: e.target.value,
                                          }
                                        : b
                                    )
                                  );
                                }}
                                sx={{
                                  flex: 1,
                                }}
                              />
                              <TextField
                                disabled={!broker?.isUserInput}
                                placeholder="Comission amount"
                                type="number"
                                size="small"
                                value={broker.chargeAmount || 0}
                                onChange={(e) => {
                                  calculateBrokerComission(index);
                                  setBrokers(
                                    brokers.map((b, i) =>
                                      i === index
                                        ? { ...b, chargeAmount: e.target.value }
                                        : b
                                    )
                                  );
                                }}
                                sx={{
                                  flex: 1,
                                }}
                              />
                              <Button
                                variant="outlined"
                                sx={{
                                  borderRadius: 2,
                                }}
                                startIcon={<GoPlus />}
                                onClick={() =>
                                  handleBrokerTransactionSubmit(broker)
                                }
                              >
                                Transaction
                              </Button>
                            </Box>
                          </Box>
                        ))}
                        <Button
                          variant="outlined"
                          startIcon={<GoPlus />}
                          sx={{
                            width: "100%",
                            padding: "10px 20px",
                            borderRadius: "15px",
                          }}
                          onClick={() => {
                            setShowLeadsDrawer(true);
                            setRole("Broker");
                          }}
                        >
                          Add Broker
                        </Button>
                      </Box>
                    </FormBox>
                  )}
                </Box>
              </Box>
            }
          />

          <SpaceBetween
            left={
              <FormBox label={"Bank Account"}>
                <BankAccountPicker
                  selectedBankAccount={selectedBankAccount}
                  setSelectedBankAccount={(value) =>
                    setSelectedBankAccount(value)
                  }
                />
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              initialBank ? (
                <MenuItem>
                  <AccountBalance
                    sx={{
                      mr: 1,
                    }}
                  />
                  <Box
                    sx={{
                      mr: 1,
                    }}
                  >
                    <Typography variant="body1">
                      {initialBank?.bankName}
                    </Typography>
                    <Typography variant="body2">
                      {initialBank?.accountNumber}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => {
                      setInitialBank(null);
                    }}
                  >
                    <Close />
                  </IconButton>
                </MenuItem>
              ) : (
                <FormBox label="Teams">
                  <Select
                    value={selectedTeam}
                    defaultValue={selectedTeam}
                    onChange={(e) => setSelectedTeams(e.target.value)}
                    fullWidth={true}
                  >
                    {teams
                      ?.filter((team) => team.title)
                      .map((team) => {
                        return (
                          <MenuItem value={team._id}>{team.title}</MenuItem>
                        );
                      })}
                  </Select>
                </FormBox>
              )
            }
            right={
              <FormBox label={"Billing Account"}>
                <Select
                  fullWidth
                  defaultValue={selectedBillingAccount?._id}
                  value={selectedBillingAccount?._id}
                  onChange={(e) => {
                    const billingAccount = billingAccounts.find(
                      (item) => item._id === e.target.value
                    );
                    setSelectedBillingAccount(billingAccount);
                  }}
                  sx={{
                    padding: "0px",
                  }}
                >
                  {billingAccounts.map((billingAccount) => (
                    <MenuItem value={billingAccount._id}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="body1">
                          {billingAccount.name}
                        </Typography>
                        <Typography variant="body2">
                          {billingAccount.phone || billingAccount.email}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <FormBox label="Booking Status">
                <Select
                  value={bookingStatus}
                  onChange={(e) => setBookingStatus(e.target.value)}
                  fullWidth={true}
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormBox>
            }
            right={
              <FormBox label="Sanctioned/Unsanctioned">
                <Select
                  value={sanctionedUnsanctioned}
                  onChange={(e) => setSanctionedUnsanctioned(e.target.value)}
                  fullWidth={true}
                >
                  <MenuItem value="Sanctioned">Sanctioned</MenuItem>
                  <MenuItem value="Unsanctioned">Unsanctioned</MenuItem>
                </Select>
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <FormBox label="Registration Date">
                <DatePicker
                  value={registrationDate}
                  onChange={(date) => setRegistrationDate(date)}
                  fullWidth={true}
                />
              </FormBox>
            }
            right={
              <FormBox label="Unit Type">
                <Select
                  value={unitType}
                  onChange={(e) => setUnitType(e.target.value)}
                  fullWidth={true}
                >
                  <MenuItem value="1BHK">1BHK</MenuItem>
                  <MenuItem value="2BHK">2BHK</MenuItem>
                  <MenuItem value="3BHK">3BHK</MenuItem>
                  <MenuItem value="4BHK">4+BHK</MenuItem>
                </Select>
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <FormBox label="Closing Manager">
                <UserSearcher
                  defaultValue={closingManager}
                  onSelect={(val) => setClosingManager(val)}
                />
              </FormBox>
            }
            right={
              <FormBox label="Booking Date">
                <DatePicker
                  value={bookingDate}
                  onChange={(date) => setBookingDate(date)}
                  fullWidth={true}
                />
              </FormBox>
            }
          />

          <SpaceBetween
            right={
              <FormBox label="Payment Table">
                {initialPaymentTable ? (
                  <HorizBox
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D8DEE4",
                      backgroundColor: "#F5F7FA",
                      display: "inline-flex",
                      px: "10px",
                      py: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#54555A",
                      }}
                    >
                      {initialPaymentTable?.title}
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setInitialPaymentTable();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M13.5 4.5L4.5 13.5"
                          stroke="#717171"
                          stroke-width="1.125"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.5 4.5L13.5 13.5"
                          stroke="#717171"
                          stroke-width="1.125"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </HorizBox>
                ) : (
                  <Select
                    value={selectedTable}
                    onChange={(e) => {
                      if (paymentComponents.length > 0) {
                        if (
                          window.confirm(
                            "Your previous table will be deleted"
                          ) === true
                        ) {
                          setSelectedTable(e.target.value);
                        }
                        e.target.value = selectedTable;
                      } else {
                        setSelectedTable(e.target.value);
                      }
                    }}
                    fullWidth={true}
                  >
                    {tables?.map((item) => (
                      <MenuItem value={item?._id}>{item?.title}</MenuItem>
                    ))}
                  </Select>
                )}
              </FormBox>
            }
            left={
              <FormBox label="Payment Schedule">
                {initialPaymentSlab ? (
                  <HorizBox
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D8DEE4",
                      backgroundColor: "#F5F7FA",
                      display: "inline-flex",
                      px: "10px",
                      py: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#54555A",
                      }}
                    >
                      {initialPaymentSlab?.title} [
                      {initialPaymentSchedule?.title || "Untitled Schedule"}]
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setInitialPaymentSlab()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M13.5 4.5L4.5 13.5"
                          stroke="#717171"
                          stroke-width="1.125"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.5 4.5L13.5 13.5"
                          stroke="#717171"
                          stroke-width="1.125"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </HorizBox>
                ) : (
                  <HorizBox>
                    <Select
                      value={selectedSlab}
                      defaultValue={selectedSlab}
                      onChange={(e) => setSelectedSchedule(e.target.value)}
                      fullWidth={true}
                    >
                      {schedules
                        ?.filter((item) => item?.title?.length > 0)
                        .map((item) => (
                          <MenuItem value={item?._id}>{item?.title}</MenuItem>
                        ))}
                    </Select>
                  </HorizBox>
                )}
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <FormBox label={"Total Area"}>
                <TextField
                  fullWidth={true}
                  value={area}
                  variant="outlined"
                  type="number"
                  placeholder="Total land area (In Sqft.)"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setArea(e.target.value);
                    }
                  }}
                />
              </FormBox>
            }
            right={
              <FormBox label={"Total Saleable Area"}>
                <TextField
                  fullWidth={true}
                  value={saleableArea}
                  variant="outlined"
                  type="number"
                  placeholder="Total land saleble area (In Sqft.)"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      setSaleableArea(e.target.value);
                    }
                  }}
                />
              </FormBox>
            }
          />

          {selectedSchedule && (
            <ScheduleTable
              slabs={paymentScheduleSlabs}
              setPaymentSlabDetails={setPaymentSlabDetails}
              bookingId={bookingId}
            />
          )}

          {selectedTable && memoizedPaymentTable}

          <DuoButtonGroup
            primaryButtonText="Save"
            primaryButtonListener={() => updateBooking()}
            secondaryButtonText="Cancel"
            secondaryButtonListener={() => {
              history.goBack();
            }}
          />
        </StandardAppContainerRounded>
      </StandardContainer>
      <SearchEntityDialog
        orgtype={"User"}
        givenFilterOption={
          role === "Broker"
            ? [
                {
                  option: "Contact",
                  types: ["Broker", "Other"],
                },
              ]
            : role === "Customer"
            ? [
                {
                  option: "Contact",
                  types: ["Customer", "Other"],
                },
              ]
            : [
                {
                  option: "Contact",
                  types: ["Owner", "Other"],
                },
              ]
        }
        entity={"User"}
        multiple={false}
        curEntityId={user._id}
        givenProfileIdArr={[organization?.profile?._id, project?.profile?._id]}
        onSave={(entity) => {
          if (role === "Owner") {
            setOwner(entity);
          } else if (role === "Customer") {
            setCustomer(entity);
          } else if (role === "Broker") {
            setBrokers((prev) => [
              ...prev,
              {
                profile: entity,
                chargeAmount: 0,
                chargePercentage: 0,
                transaction: "",
                isTransaction: false,
              },
            ]);
          }
          setShowLeadsDrawer(false);
        }}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title={
          role === "Customer"
            ? "Select Customer"
            : role === "Owner"
            ? "Select Owner"
            : "Select Broker"
        }
        financialRelationType={role}
      />
    </>
  );
};

export default BookingEdit;
