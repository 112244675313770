import { Box, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
// import { getSalaryComponents } from "./editsalarycomponentforms/api";

const CalculatorBox = ({ expression = "", setExpression, components }) => {
  const mapDisplayExpressionToExpression = (displayExpr) => {
    let expr = displayExpr;
    components.forEach((component) => {
      const componentName = component.title;
      const componentId = component._id;
      expr = expr.replace(new RegExp(` ${componentName} `, "g"), componentId);
    });
    return expr;
  };

  const mapExpressionToDisplayExpression = (expr) => {
    let displayExpr = expr;
    components.forEach((component) => {
      const componentTitle = component.title;
      const componentId = component._id;

      displayExpr = displayExpr.replace(
        new RegExp(componentId, "g"),
        ` ${componentTitle} `
      );
    });
    //console.log("Display expression is: ", displayExpr,components);
    return displayExpr;
  };

  const { organizationId } = useParams();
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");
  var newDisplayExpression = mapExpressionToDisplayExpression(expression);
  //console.log(newDisplayExpression,' original value');
  const [displayExpression, setDisplayExpression] =
    useState(newDisplayExpression);

  useEffect(() => {
    if (expression) {
      let tempDisplayExp = mapExpressionToDisplayExpression(expression);
      setDisplayExpression(tempDisplayExp);
    }
  }, [components]);

  let keys = [
    "1",
    "2",
    "3",
    "+",
    ">",
    "CE",
    "4",
    "5",
    "6",
    "-",
    "<",
    "MAX",
    "7",
    "8",
    "9",
    "*",
    ">=",
    "ROUND",
    ".",
    "0",
    ",",
    "/",
    "<=",
    "FLOOR",
    "(",
    ")",
    "IF",
    "^",
    "!=",
    "CEIL",
  ];

  const getCursorPosition = () => {
    return inputRef.current.selectionStart;
  };

  const setCursorPosition = (pos) => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(pos, pos);
    }
  };

  const validateExpression = (expr) => {
    const allowedFunctions = ["IF", "ROUND", "CEIL", "MAX", "FLOOR"];
    const comparisonOperators = [">", "<", ">=", "<=", "!="];

    // Replace MongoDB IDs with a placeholder number
    const modifiedExpr = expr.replace(/[a-f\d]{24}/gi, "1");
    // Check parentheses balance
    const stack = [];
    for (let i = 0; i < modifiedExpr.length; i++) {
      if (modifiedExpr[i] === "(") {
        stack.push("(");
      } else if (modifiedExpr[i] === ")") {
        if (stack.length === 0) {
          return;
        }
        stack.pop();
      }
    }
    if (stack.length !== 0) {
      setErrorMessage("Unbalanced parentheses");
      setIsValid(false);
      return;
    }

    // Check for invalid characters (excluding white spaces and MongoDB IDs)
    // const invalidChars = modifiedExpr.replace(/[\w+\-*/,^=!<>()]/g, "");
    // if (invalidChars.length > 0) {
    //   setErrorMessage("Invalid characters in expression");
    //   setIsValid(false);
    //   return;
    // }

    // Check for operators preceding closing parenthesis
    if (/[+\-*/^][\s]*\)/g.test(modifiedExpr)) {
      setErrorMessage("Invalid operator preceding closing parenthesis");
      setIsValid(false);
      return;
    }

    // Check for allowed functions and comparison operators
    const words = modifiedExpr.split(/[\s()+\-,*/^=!<>]+/);
    for (let word of words) {
      if (
        word.length > 0 &&
        !/^\d+(\.\d+)?$/.test(word) &&
        !allowedFunctions.includes(word) &&
        !comparisonOperators.includes(word)
      ) {
        setErrorMessage(`Invalid word: ${word}`);
        setIsValid(false);
        return;
      }
    }
    setErrorMessage("");
    setIsValid(true);
  };

  const handleCalculatorButtonClick = (key) => {
    const cursorPosition = getCursorPosition();

    setDisplayExpression((prevDisplayExpr) => {
      let newDisplayExpression;
      if (key === "CE") {
        setDisplayExpression("");
        setExpression("");
        return "";
      }

      switch (key) {
        case "(":
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            " () " +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 3);
          break;
        case "CEIL":
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            " CEIL() " +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 7);
          break;
        case "IF":
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            " IF(,,) " +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 7);
          break;
        case "MAX":
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            " MAX() " +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 6);
          break;
        case "ROUND":
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            " ROUND(,) " +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 9);
          break;
        case "FLOOR":
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            " FLOOR() " +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 8);
          break;
        default:
          newDisplayExpression =
            prevDisplayExpr.slice(0, cursorPosition) +
            key +
            prevDisplayExpr.slice(cursorPosition);
          setCursorPosition(cursorPosition + 1);
      }

      const newExpression =
        mapDisplayExpressionToExpression(newDisplayExpression);
      setExpression(newExpression);
      validateExpression(newExpression);
      return newDisplayExpression;
    });
  };

  const handleComponentSelection = (componentId) => {
    const cursorPosition = getCursorPosition();
    const component = components.find((comp) => comp._id === componentId);

    setSelectedComponent(componentId);
    setDisplayExpression((prevDisplayExpr) => {
      const newDisplayExpression =
        prevDisplayExpr.slice(0, cursorPosition) +
        ` ${component.title} ` +
        prevDisplayExpr.slice(cursorPosition);
      const newExpression =
        mapDisplayExpressionToExpression(newDisplayExpression);
      setExpression(newExpression);
      validateExpression(newExpression);
      return newDisplayExpression;
    });
    setCursorPosition(cursorPosition + component.title.length + 2);
  };

  const handleExpressionChange = (e) => {
    const newDisplayExpression = e.target.value;
    //console.log('handleExpressionChange called ',newDisplayExpression)
    setDisplayExpression(newDisplayExpression);

    const newExpression =
      mapDisplayExpressionToExpression(newDisplayExpression);
    setExpression(newExpression);
    validateExpression(newExpression);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const cursorPosition = getCursorPosition();
      setDisplayExpression((prevDisplayExpr) => {
        const newDisplayExpression =
          prevDisplayExpr.slice(0, cursorPosition - 1) +
          prevDisplayExpr.slice(cursorPosition);
        const newExpression =
          mapDisplayExpressionToExpression(newDisplayExpression);
        setExpression(newExpression);
        return newDisplayExpression;
      });
      setCursorPosition(cursorPosition - 1);
    }
  };
  //console.log(expression, errorMessage, " is the expression");
  //console.log(displayExpression, errorMessage, " is the display expression");

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <input
          ref={inputRef}
          value={displayExpression}
          onChange={handleExpressionChange}
          onKeyDown={handleKeyDown}
          fullWidth
          multiline
          style={{
            width: "100%",
            border: `5px inset ${isValid ? "rgba(0, 0, 0, 0.1)" : "red"}`,
            padding: "10px 15px",
            fontSize: "1.3rem",
            fontWeight: "bold",
            borderRadius: "8px",
            boxSizing: "border-box",
            outline: "none",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        />
        {isValid ? <div style={{ color: "red" }}>{errorMessage}</div> : null}
      </Box>
      <Box mt={1}>
        <Select
          value={selectedComponent}
          onChange={(e) => handleComponentSelection(e.target.value)}
          displayEmpty
          fullWidth={true}
        >
          <MenuItem value="">Select Component</MenuItem>
          {components?.map((comp) => (
            <MenuItem key={comp?._id} value={comp?._id}>
              {`${comp?.title} (₹ ${parseInt(comp?.value) || 0})`}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box mt={1}>
        <Grid container spacing={0}>
          {keys?.map((item, index) => (
            <Grid item xs={2} key={index}>
              <Box
                onClick={() => {
                  handleCalculatorButtonClick(item);
                }}
                sx={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.2)",
                  cursor: "pointer",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "#FAFAFA",
                  },
                  borderRadius:
                    index === 0
                      ? "5px 0px 0px 0px"
                      : index === 4
                      ? "0px 5px 0px 0px"
                      : index === keys.length - 5
                      ? "0px 0px 0px 5px"
                      : index === keys.length
                      ? "0px 0px 5px 0px"
                      : "0px 0px 0px 0px",
                }}
              >
                {item}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CalculatorBox;
