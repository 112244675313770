import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import Button from "../../styled/generic/Button";
import FormBox from "../../styled/generic/FormBox";
import Select from "../../styled/generic/Select";
import BankAccountPicker from "../../styled/generic/BankAccountPickerDropdown";
import TextField from "../../styled/generic/TextField";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import { Add, Delete } from "@mui/icons-material";
import DemandDeedSettingTable from "./DemandDeedSettingTable";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import { getProfileByWallet } from "../../finance2o/commonComponent/transaction/api";

const DemandDeedSettingsCreate = ({ libraryId }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [schedules, setSchedules] = useState([]);
  const [paymentTables, setPaymentTables] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [selectedPaymentTable, setSelectedPaymentTable] = useState({});
  const [usedComponentIds, setUsedComponentIds] = useState([]);
  const [title, setTitle] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [components, setComponents] = useState([]);
  const [subject, setSubject] = useState("");
  const { profileId } = useParams();

  const [billingAccounts, setBillingAccounts] = useState([]);
  const [selectedBillingAccounts, setSelectedBillingAccounts] = useState(null);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [profile, setProfile] = useState(null);
  const [useDefaultFinancialDetails, setUseDefaultFinancialDetails] =
    useState(true);

  const fetchBillingAccounts = async () => {
    const response = await Api.post(
      `wallet/billing/billing-account/sharedIds/get`,
      {
        sharedIds: [profile?._id],
      }
    );
    if (response) {
      setBillingAccounts(response.billingAccounts);
    } else {
      console.log("Error fetching billing accounts");
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error fetching billing accounts",
        },
      });
    }
  };

  useEffect(() => {
    if (profile) {
      fetchBillingAccounts();
    }
  }, [profile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await getProfileDataPopulate({
          profileId: profileId,
        });

        console.log("data", data.parent.wallet._id);
        data = await getProfileByWallet({
          walletId: data.parent.wallet._id,
        });
        data = await getProfileDataPopulate({
          profileId: data,
        });
        setProfile(data);
        console.log("project", data);
      } catch (error) {
        console.log("Error fetching profile data populate", error);
      }
    };
    if (profileId) {
      // wallet
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!Array.isArray(transactions) || transactions.length === 0) {
      console.log("No transactions available");
      setUsedComponentIds([]);
      return;
    }

    const uniqueComponentIds = new Set();
    transactions.forEach((transaction) => {
      if (Array.isArray(transaction?.billItems)) {
        transaction.billItems.forEach((billItem) => {
          uniqueComponentIds.add(billItem.component);
        });
      }
    });
    const usedComponentIds = Array.from(uniqueComponentIds);
    setUsedComponentIds(usedComponentIds);
  }, [transactions]);

  // Fetch all table and schedules
  const fetchTableAndSchedule = async () => {
    const [paymentTablesData, schedulesData] = await Promise.all([
      Api.post("/listing/booking/payment/table/get", {
        libraryId: libraryId,
      }),
      Api.post("/listing/booking/schedule/get", {
        libraryId,
      }),
    ]);
    setSchedules(schedulesData.data);
    setPaymentTables(paymentTablesData.data);
  };

  useEffect(() => {
    if (libraryId) {
      fetchTableAndSchedule();
    }
  }, []);

  const getComponents = async () => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/component/get",
        {
          tableId: selectedPaymentTable,
        }
      );
      if (data) {
        setComponents(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedPaymentTable) {
      getComponents();
    }
  }, [selectedPaymentTable]);

  const saveDemandDeedSettings = async () => {
    try {
      const data = await Api.post(
        "/listing/booking/payment/demanddeedsetting/create",
        {
          paymentTable: selectedPaymentTable?._id,
          paymentSchedule: selectedSchedule?._id,
          project: selectedSchedule?.project?._id,
          projectBlock: selectedSchedule?.projectBlock?._id,
          billingAccount: selectedBillingAccounts._id,
          title: title,
          subject: subject,
          library: libraryId,
          transactions: transactions,
        }
      );
      if (data) {
        setComponents([]);
        setUsedComponentIds([]);
        setSelectedPaymentTable("");
        setSelectedSchedule("");
        setTitle("");
        setTransactions([]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Demand Deed Settings created successfully!",
          },
        });
        history.push(`/booking/settings/${profileId}?view=demanddeedsettings`);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  const handleDelete = (index) => {
    const filteredTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(filteredTransactions);
  };

  const updateTransactionBillItems = (updatedBillItems, transactionIndex) => {
    setTransactions((prevTransactions) => {
      const updatedTransactions = [...prevTransactions];
      updatedTransactions[transactionIndex] = {
        ...updatedTransactions[transactionIndex],
        billItems: updatedBillItems,
      };
      return updatedTransactions;
    });
  };

  return (
    <Box>
      <FormBox label="Demand Deed Setting Name">
        <TextField
          fullWidth={true}
          label="Enter Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormBox>

      <BoxSpaceBetween
        sx={{
          display: "flex",
          gap: "15px",
          marginY: "1rem",
        }}
      >
        <FormBox label={"Select Schedule"}>
          <Select
            placeholder={"Schedule"}
            fullWidth={true}
            onChange={(e) => {
              setSelectedSchedule(e.target.value);
            }}
          >
            {schedules.map((schedule, index) => {
              if (!schedule?.title) return null;
              return (
                <MenuItem
                  key={index}
                  value={schedule}
                  sx={{
                    backgroundColor:
                      selectedSchedule._id === schedule._id && "primary",
                  }}
                >
                  {schedule?.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormBox>
        <FormBox label={"Select Payment Table"}>
          <Select
            label={"Payment Table"}
            fullWidth={true}
            onChange={(e) => {
              setSelectedPaymentTable(e.target.value);
            }}
          >
            {paymentTables?.map((paymentTable, index) => {
              if (!paymentTable?.title) return null;
              return (
                <MenuItem
                  key={index}
                  value={paymentTable}
                  sx={{
                    backgroundColor:
                      selectedPaymentTable._id === paymentTable?._id &&
                      "primary",
                  }}
                >
                  {paymentTable?.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormBox>
      </BoxSpaceBetween>

      <SubjectInput
        subject={subject}
        setSubject={setSubject}
        title={"Demand deed subject"}
        demandDeed={true}
      />

      <Box
                sx={{
                  marginTop: "1rem",
                }}
              >
                <FormBox label={"Billing account"}>
                  <Select
                    fullWidth
                    onChange={(e) => {
                      const newBil = billingAccounts.find(
                        (item) => item._id === e.target.value
                      );
                      setSelectedBillingAccounts(newBil);
                    }}
                  >
                    {billingAccounts.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormBox>
              </Box>

      <BoxSpaceBetween sx={{ my: 2 }}>
        <Typography variant="h5">Transactions</Typography>
        <Button
          startIcon={<Add />}
          color="primary"
          variant="outlined"
          size="large"
          sx={{
            width: "fit-content",
            borderStyle: "dotted",
          }}
          onClick={() => {
            setTransactions((prev) => [
              ...prev,
              {
                subject: "",
                bankAccountId: "",
                billItems: [],
                paymentTable: selectedPaymentTable,
                paymentSchedule: selectedSchedule,
              },
            ]);
          }}
        >
          Add Transaction
        </Button>
      </BoxSpaceBetween>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "1rem",
          padding: "20px",
          paddingTop: "0px",
          borderRadius: "15px",
          boxShadow: "2px 1px 8px 0px #3465FF1F",
        }}
      >
        {transactions.map((transaction, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography variant="h6">Transaction {index + 1}</Typography>
                <Tooltip title={"Delete transaction"} enterDelay={2000}>
                  <Button
                    color="error"
                    variant={"outlined"}
                    startIcon={<Delete />}
                    onClick={() => handleDelete(index)}
                    sx={{
                      borderStyle: "dotted",
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  width: "100%",
                  marginTop: "1rem",
                }}
              >
                <SubjectInput
                  title={"Transaction Subject"}
                  subject={transaction.subject}
                  setSubject={(subject1) => {
                    const updatedTransactions = [...transactions];
                    updatedTransactions[index] = {
                      ...updatedTransactions[index],
                      subject: subject1,
                    };
                    setTransactions(updatedTransactions);
                  }}
                />
                <FormBox
                  label="Select Bank"
                  sx={{
                    flex: 3,
                  }}
                >
                  <BankAccountPicker
                    selectedBankAccount={transaction.bankAccountId}
                    setSelectedBankAccount={(id) => {
                      const updatedTransactions = [...transactions];
                      updatedTransactions[index] = {
                        ...updatedTransactions[index],
                        bankAccountId: id,
                      };
                      setTransactions(updatedTransactions);
                    }}
                  />
                </FormBox>
              </Box>

              <DemandDeedSettingTable
                components={components}
                usedComponentIds={usedComponentIds}
                billItems={transaction.billItems}
                onBillItemsChange={(updatedBillItems) =>
                  updateTransactionBillItems(updatedBillItems, index)
                }
              />
            </Box>
          );
        })}
      </Box>
      <DuoButtonGroup
        primaryButtonText="Save"
        primaryButtonListener={() => {
          saveDemandDeedSettings();
        }}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => {
          history.goBack();
        }}
      />
    </Box>
  );
};

export default DemandDeedSettingsCreate;

export const SubjectInput = ({
  subject,
  setSubject,
  title,
  demandDeed = false,
}) => {
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [error, setError] = useState(false);
  const quillRef = useRef(null);
  const validateRegex =
    /^([\w\s,.''""]*|\{\{\s*[a-zA-Z0-9]+\s*\}\}[\w\s,.''""]*)*$/;

  const allMenuItems = [
    { value: "{{ customerName }}", label: "Add Customer Name" },
    { value: "{{ slabName }}", label: "Add Slab Name" },
    { value: "{{ projectName }}", label: "Add Project Name" },
    { value: "{{ unitName }}", label: "Add Unit Name" },
    { value: "{{ dueDate }}", label: "Add Due Date" },
    { value: "{{ amount }}", label: "Add Amount" },
    { value: "{{ numTransaction }}", label: "Add Number of Transactions" },
  ];

  const menuItems = demandDeed ? allMenuItems.slice(0, 5) : allMenuItems;

  const handleSubjectVariable = (variable) => {
    if (!selectedVariables.includes(variable)) {
      setSelectedVariables((prevSelected) => [...prevSelected, variable]);

      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      if (range) {
        // If there's a selection, insert the variable at the cursor position
        editor.insertText(range.index, variable + " ", "user");
      } else {
        // If there's no selection, insert at the end
        editor.insertText(editor.getLength() - 1, variable + " ", "user");
      }
      editor.focus();
    }
  };

  const handleDelete = (variableToDelete) => {
    setSelectedVariables((prevSelected) =>
      prevSelected.filter((variable) => variable !== variableToDelete)
    );

    const editor = quillRef.current.getEditor();
    const content = editor.getText();
    const newContent = content.replace(variableToDelete + " ", "");
    editor.setText(newContent);
  };

  useEffect(() => {
    setError(!validateRegex.test(subject));
  }, [subject]);

  useEffect(() => {
    if (subject.length > 0) {
      const regex = /\{\{\s*([a-zA-Z0-9]+)\s*\}\}/g;
      const variables = subject.match(regex) || [];
      setSelectedVariables(variables);
    } else {
      setSelectedVariables([]);
    }
  }, [subject]);

  return (
    <FormBox
      label={title}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={subject}
        onChange={setSubject}
        style={{
          border: !error ? "1px solid red" : undefined,
        }}
      />
      <FormControl
        fullWidth
        sx={{
          my: "15px",
        }}
      >
        <InputLabel id="select-label">Add Variable</InputLabel>
        <Select
          labelId="select-label"
          id="variable-select"
          label="Add Variable"
          onChange={(e) => handleSubjectVariable(e.target.value)}
          value=""
          renderValue={() => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedVariables.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => handleDelete(value)}
                />
              ))}
            </Box>
          )}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
                backgroundColor: selectedVariables.includes(item.value)
                  ? "secondary"
                  : "inherit",
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FormBox>
  );
};
