import { Box, MenuItem, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../styled/generic/Button";
import { Add } from "@mui/icons-material";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
import Select from "../../styled/generic/Select";
import { set } from "lodash";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import TableContainer from "../../styled/generic/TableContainer";
import dayjs from "dayjs";

const PaymentTables = ({ libraryId }) => {
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const history = useHistory();

  const [openTablesDrawer, setOpenTablesDrawer] = useState(false);
  const [tableName, setTableName] = useState("");

  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);

  const saveTable = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/listing/booking/payment/table/create", {
        title: tableName,
        profile: profileId,
        library: libraryId,
      });
      if (data) {
        setOpenTablesDrawer(false);
        setTableName("");
        setTables([...tables, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Payment table created successfully",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getTables = async () => {
    try {
      const { data } = await Api.post("/listing/booking/payment/table/get", {
        libraryId: libraryId,
      });
      if (data) {
        setTables(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTables();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"flex-start"}
        marginBottom={"1.5rem"}
      >
        <Typography variant="h5">Payment Table</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setOpenTablesDrawer(true);
          }}
        >
          New Table
        </Button>
      </Box>
      <TableContainer
        columns={["Title", "Created At"]}
        data={tables}
        loading={loading}
      >
        {tables.map((table) => (
          <tr
            onClick={() => {
              history.push(
                `/booking/settings/${profileId}?view=paymenttable&tableId=${table?._id}`
              );
            }}
          >
            <td>{table.title}</td>
            <td>{dayjs(table?.createdAt).format("DD/MM/YYYY")}</td>
          </tr>
        ))}
      </TableContainer>
      <DrawerContainer
        title="Create Table"
        open={openTablesDrawer}
        onClose={() => setOpenTablesDrawer(false)}
        setOpen={setOpenTablesDrawer}
      >
        <FormBox label="Table Name">
          <TextField
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
            fullWidth={true}
            placeholder="Enter"
          />
        </FormBox>
        <DuoButtonGroup
          primaryButtonText="Save"
          primaryButtonListener={() => saveTable()}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setOpenTablesDrawer(false)}
          loadingPrimary={loading}
        />
      </DrawerContainer>
    </Box>
  );
};

export default PaymentTables;
