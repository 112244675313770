import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";

const PaymentTable = ({
  components = [],
  displayOnly = false,
  setPaymentDetails,
  bookingId,
  slabs,
  area = 0,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const totalCompletePercentage = Number(
    slabs[
      slabs?.filter((slab) => slab?.type === "aggrement" || slab?.isCompleted)
        ?.length - 1
    ]?.cumulativePercentage || 0
  );

  const [tableLoading, setTableLoading] = useState(true);
  const properties = [
    "chargeAmount",
    "rebateAmount",
    "penaltyDue",
    "netReceivable",
    "amountReceived",
    "amountReceivable",
    "currentDue",
    "dueReceivable",
  ];

  useEffect(() => {
    async function updateData() {
      // Initialize newData with default values
      const newData = components.map((component) => ({
        table: component?.table,
        title: component.title || "Undefined",
        ratePerSqFt: 0,
        componentRef: component._id,
        intApp: false,
        chargeAmount: 0,
        rebateAmount: 0,
        penaltyDue: 0,
        amountReceivable: 0,
        currentDue: 0,
        dueReceivable: 0,
        amountReceived: 0,
        netReceivable: 0,
        bookingId: bookingId,
        hideInTable: component?.hideInTable || false,
        type: component?.type,
        milestoneCalculation: component?.milestoneCalculation || false,
      }));

      // Prepare the payload
      const payload = {
        tableId: components[0]?.table,
        componentIds: components.map((component) => component._id),
        bookingId: bookingId,
        titles: components.map((component) => component?.title),
      };

      // Fetch data from API in a single call
      const response = await Api.post(
        "listing/booking/payment/table/detail/get-or-create",
        payload
      );

      if (response) {
        const fetchedData = response;

        // Update the respective fields in newData
        const updatedData = newData.map((component) => {
          const fetchedComponent = fetchedData.find(
            (item) => item.componentRef === component.componentRef
          );

          if (fetchedComponent) {
            return {
              ...component,
              _id: fetchedComponent._id || "",
              ratePerSqFt:
                fetchedComponent.ratePerSqFt ||
                fetchedComponent.chargeAmount / area,
              chargeAmount:
                fetchedComponent.chargeAmount || component.chargeAmount,
              rebateAmount:
                fetchedComponent.rebateAmount || component.rebateAmount,
              penaltyDue: fetchedComponent.penaltyDue || component.penaltyDue,
              amountReceivable:
                fetchedComponent.amountReceivable || component.amountReceivable,
              currentDue:
                fetchedComponent.currentDue || component.currentDue || 0,
              dueReceivable:
                fetchedComponent.dueReceivable ||
                fetchedComponent.currentDue + fetchedComponent.penaltyDue,
              amountReceived:
                fetchedComponent.amountReceived || component.amountReceived,
              netReceivable:
                fetchedComponent.netReceivable || component.netReceivable,
              milestoneCalculation: component.milestoneCalculation,
            };
          }
          return component;
        });

        setData(updatedData);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Something went wrong.",
          },
        });
      }

      setTableLoading(false);
    }

    if (components.length > 0) {
      updateData();
    }
  }, [components]);

  const handleInputChange = (index, field, value) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: Number(value) ? Number(value) : value,
      };
      updatedData[index].netReceivable =
        Number(updatedData[index].chargeAmount) +
        Number(updatedData[index].penaltyDue) -
        Number(updatedData[index].rebateAmount);

      updatedData[index].amountReceivable =
        Number(updatedData[index].netReceivable) -
        Number(updatedData[index].amountReceived);

      updatedData[index].currentDue = updatedData[index].milestoneCalculation
        ? ((Number(updatedData[index].chargeAmount) -
            Number(updatedData[index].rebateAmount)) *
            totalCompletePercentage) /
          100
        : Number(updatedData[index].chargeAmount);

      updatedData[index].dueReceivable =
        updatedData[index].chargeAmount - updatedData[index].amountReceived;

      updatedData[index].ratePerSqFt = updatedData[index].chargeAmount / area;
      return updatedData;
    });
  };

  const calculateTotal = (property) => {
    const totalAmount = data
      .reduce((total, component) => {
        return total + (parseFloat(component[property]) || 0);
      }, 0)
      .toFixed(2);
    return totalAmount || 0;
  };

  async function handleSubmit() {
    setLoading(true);
    const responseData = await Api.patch(
      "listing/booking/payment/table/update",
      {
        data,
      }
    );
    if (responseData) {
      setPaymentDetails(responseData);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }

    setLoading(false);
    return;
  }

  useEffect(() => {
    if (displayOnly) return;
    const submitTimeout = setTimeout(() => {
      handleSubmit();
    }, 2000);
    return () => clearTimeout(submitTimeout);
  }, [...data.map((item) => item.amountReceived)]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography variant="h6">Payment Table</Typography>
      <TableContainer
        data={data}
        forceResponsive={true}
        width={"280px"}
        columns={[
          "Charge Type",
          "Charge Amount",
          "Discount Amount",
          "Delayed Interest",
          "Total Payable Amount",
          "Received Amount",
          "Receivable Amount",
          "Current Due",
          "Receivable Due",
        ]}
        loading={tableLoading}
        containerHeight="auto"
        headerAlignment={"start"}
      >
        <tbody>
          {data.map((component, index) => {
            if (component?.hideInTable) return null;
            return (
              <tr
                key={index}
                style={{
                  backgroundColor: "#f8f9fa",
                  transition: "background-color 0.3s ease",
                }}
              >
                <td
                  style={{
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                    borderLeft: "1px solid #dee2e6",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    minWidth: "280px",
                  }}
                >
                  {component.title}
                </td>
                <td
                  style={{
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                    minWidth: "280px",
                  }}
                >
                  <TextField
                    disabled={displayOnly}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Charge Amount"
                    value={component.chargeAmount || 0}
                    onChange={(e) =>
                      handleInputChange(index, "chargeAmount", e.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>
                <td
                  style={{
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                    minWidth: "280px",
                  }}
                >
                  <TextField
                    disabled={displayOnly}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Discount Amount"
                    value={component.rebateAmount || 0}
                    onChange={(e) =>
                      handleInputChange(index, "rebateAmount", e.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>

                <td
                  style={{
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    minWidth: "280px",

                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                  }}
                >
                  <TextField
                    disabled={displayOnly}
                    variant="outlined"
                    fullWidth={true}
                    placeholder="Enter Delayed Interest"
                    value={component.penaltyDue || 0}
                    type="number"
                    onChange={(e) =>
                      handleInputChange(index, "penaltyDue", e.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>
                <td
                  style={{
                    padding: "12px 16px",
                    minWidth: "280px",

                    verticalAlign: "middle",
                    borderTop: "1px solid #dee2e6",
                    borderBottom: "1px solid #dee2e6",
                    borderRight: "1px solid #dee2e6",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <TextField
                    disabled={true}
                    value={component.netReceivable || 0}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Total Payable Amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>
                <td
                  style={{
                    padding: "12px 16px",
                    minWidth: "280px",
                    verticalAlign: "middle",
                    border: "1px solid #dee2e6",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <TextField
                    disabled={displayOnly}
                    value={component.amountReceived || 0}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Total Received Amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleInputChange(
                        index,
                        "amountReceived",
                        e.target.value
                      );
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>
                <td
                  style={{
                    minWidth: "280px",
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    border: "1px solid #dee2e6",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <TextField
                    disabled={true}
                    value={component.amountReceivable || 0}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Total Receivable Amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>
                <td
                  style={{
                    minWidth: "280px",
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    border: "1px solid #dee2e6",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <TextField
                    disabled={true}
                    value={component.currentDue || 0}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Total Current due"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>

                <td
                  style={{
                    padding: "12px 16px",
                    verticalAlign: "middle",
                    border: "1px solid #dee2e6",
                    borderTopRightRadius: "5px",
                    minWidth: "280px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <TextField
                    disabled={true}
                    value={component.dueReceivable || 0}
                    variant="outlined"
                    fullWidth={true}
                    type="number"
                    placeholder="Enter Total Due Receivable"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      handleInputChange(
                        index,
                        "amountReceived",
                        e.target.value
                      );
                    }}
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot
          style={{
            backgroundColor: "#e9ecef",
            fontWeight: "bold",
            borderTop: "2px solid #dee2e6",
          }}
        >
          <tr>
            <td style={{ padding: "16px", borderBottom: "1px solid #dee2e6" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Total:
              </Typography>
            </td>
            {properties.map((property, index) => (
              <td
                key={index}
                style={{ padding: "16px", borderBottom: "1px solid #dee2e6" }}
              >
                <TextField
                  disabled
                  value={calculateTotal(property)}
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      backgroundColor: "white",
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 14px",
                      color: "#000",
                    },
                    "& .MuiInputAdornment-root": {
                      marginRight: "8px",
                    },
                  }}
                />
              </td>
            ))}
          </tr>
        </tfoot>
      </TableContainer>
      {!displayOnly && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton
            variant="contained"
            size="small"
            onClick={handleSubmit}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default PaymentTable;
