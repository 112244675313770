import React, { useEffect, useState } from "react";
import TableContainer from "../../styled/generic/TableContainer";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import {
  ArrowBack,
  Print,
  AccountBalance,
  Apartment,
  Home,
  BookOnline,
  AttachMoney,
  ReceiptLong,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import Api from "../../../helpers/Api";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import StatusHandler from "../../styled/generic/StatusHandler";
import { useDispatch } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import ImageViewer from "../../global/ImageViewer";
import PageLoader from "../../global/PageLoader";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
}));

const TransactionPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const properties = ["totalAmountReceivable", "receivedAgainstCurrentDue"];
  const { transactionId } = useParams();
  const [transaction, setTransaction] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  const projectName = transaction?.booking?.projectBlock?.project?.displayName;
  const blockName = transaction?.booking?.projectBlock?.name;
  const unitName = transaction?.booking?.unit?.name;
  const bookingCode = transaction?.booking?.inviteCode;
  const bankName = transaction?.bankAccountId?.bankName;

  useEffect(() => {
    async function fetchTransaction() {
      const response = await Api.post(
        `/listing/booking/transaction/${transactionId}`
      );
      if (!response) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to fetch transaction",
          },
        });
        setPageLoading(false);
        return;
      }
      setTransaction(response);
      setPageLoading(false);
    }
    if (transactionId) {
      fetchTransaction();
    } else {
      history.goBack();
    }
  }, []);

  const calculateTotal = (property) => {
    const totalAmount = transaction?.billItems
      ?.reduce((total, component) => {
        return total + (parseFloat(component[property]) || 0);
      }, 0)
      .toFixed(2);
    return totalAmount || 0;
  };

  const handlePrint = () => {
    alert("This feature hasn't been implemented yet");
  };

  if (pageLoading) {
    return <PageLoader boxHeight="80vh" />;
  }

  return (
    <Box sx={{ bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="black"
            aria-label="back"
            sx={{ mr: 2 }}
            onClick={() => history.goBack()}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Transaction Details
          </Typography>
          <Button
            startIcon={<Print />}
            variant="outlined"
            color="primary"
            onClick={handlePrint}
          >
            Print
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <StyledPaper>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                <span
                  dangerouslySetInnerHTML={{
                    __html: transaction?.subject || "Transaction Details",
                  }}
                  style={{
                    fontWeight: "semibold",
                    fontSize: "inherit",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Booking ID: #{transaction?.booking?._id}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Avatar
                  src={
                    transaction?.booking?.customerProfile?.parent
                      ?.displayPicture.url
                  }
                  sx={{ width: 56, height: 56, mr: 2 }}
                />
                <Box>
                  <Typography variant="subtitle1">
                    {transaction?.booking?.customerProfile?.parent
                      ?.displayName || "Unknown"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Date:{" "}
                    {transaction?.createdAt
                      ? new Date(transaction?.createdAt).toLocaleDateString()
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </StyledPaper>

        <StyledPaper>
          <Typography
            variant="h6"
            gutterBottom
            color={"primary"}
            sx={{
              marginBottom: "1rem",
            }}
          >
            Transaction Information
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <InfoItem
                icon={<Apartment color="primary" />}
                label="Project"
                value={projectName}
              />
              <InfoItem
                icon={<Home color="primary" />}
                label="Block"
                value={blockName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <InfoItem
                icon={<BookOnline color="primary" />}
                label="Unit"
                value={unitName}
              />
              <InfoItem
                icon={<ReceiptLong color="primary" />}
                label="Booking"
                value={bookingCode}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <InfoItem
                icon={<AccountBalance color="primary" />}
                label="Bank"
                value={bankName}
              />
              <InfoItem
                icon={<AttachMoney color="primary" />}
                label="Amount"
                value={`₹ ${transaction?.amountPayable}`}
              />
            </Grid>
          </Grid>
        </StyledPaper>

        <StyledPaper>
          <Typography
            variant="h6"
            gutterBottom
            color={"primary"}
            sx={{
              marginBottom: "1rem",
            }}
          >
            Payment Table
          </Typography>
          <TableContainer
            data={transaction.billItems}
            columns={[
              "CHARGE TYPE",
              "Charge Amount",
              "Total Amount Received",
              "Current Due",
              "CURRENT PENALTY",
              "CURRENT Discount",
              "Total Payable Amount",
              "RECEIVED AGAINST CURRENT DUE",
            ]}
            loading={pageLoading}
            forceResponsive={true}
            width={"200px"}
            containerHeight=""
          >
            {transaction.billItems?.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td style={{ minWidth: "200px" }}>
                    <Typography>{transaction?.chargeType}</Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>₹{transaction?.chargeAmount}</Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>₹{transaction?.amountReceived}</Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>₹{transaction?.currentDue}</Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>₹{transaction?.penaltyDue}</Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>₹{transaction?.rebateAmount}</Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>
                      ₹{transaction?.totalAmountReceivable}
                    </Typography>
                  </td>
                  <td
                    style={{
                      minWidth: "200px",
                    }}
                  >
                    <Typography>
                      ₹{transaction?.receivedAgainstCurrentDue}
                    </Typography>
                  </td>
                </tr>
              );
            })}
            <tfoot
              style={{
                backgroundColor: "#e9ecef",
                fontWeight: "bold",
                borderTop: "2px solid #dee2e6",
              }}
            >
              <tr>
                <td
                  style={{ padding: "16px", borderBottom: "1px solid #dee2e6" }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Total:
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {properties.map((property, index) => (
                  <td
                    key={index}
                    style={{
                      padding: "16px",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    <TextField
                      disabled
                      value={calculateTotal(property)}
                      variant="outlined"
                      fullWidth
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          backgroundColor: "white",
                          fontWeight: "bold",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px 14px",
                          color: "#000",
                        },
                        "& .MuiInputAdornment-root": {
                          marginRight: "8px",
                        },
                      }}
                    />
                  </td>
                ))}
              </tr>
            </tfoot>
          </TableContainer>
        </StyledPaper>

        <StyledPaper>
          <Typography
            variant="h6"
            gutterBottom
            color={"primary"}
            sx={{
              marginBottom: "1rem",
            }}
          >
            Attached Documents
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {transaction?.attachedFiles?.length > 0 ? (
              transaction.attachedFiles.map((file, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    width: 150,
                    height: 150,
                    cursor: "pointer",
                    overflow: "hidden",
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" },
                  }}
                  onClick={() => {
                    setIsOpen(true);
                    setImageUrl(file.files[0].url);
                  }}
                >
                  <img
                    src={file.files[0].url}
                    alt={`Document ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Paper>
              ))
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "grey.100",
                  borderRadius: 1,
                }}
              >
                <Typography color="text.secondary">No Attachments</Typography>
              </Box>
            )}
          </Box>
        </StyledPaper>
      </Box>
      <ImageViewer
        isOpen={isOpen}
        setIsOpen={(value) => {
          setIsOpen(value);
          setImageUrl(null);
        }}
        url={imageUrl}
      />
    </Box>
  );
};

export default TransactionPage;

const InfoItem = ({ label, value }) => {
  if (!value) return null;
  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        display: "flex",
        padding: "15px 20px",
        alignItems: "center",
        justifyContent: "space-between",
        border: "2px solid #E7ECFD",
        borderRadius: "10px",
        boxShadow: "1px 1px 8px 0px #3465FF1F",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: "17px", fontWeight: 500, color: "black" }}>
        {value}
      </Typography>
    </Paper>
  );
};
