import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StandardContainer from "../../styled/generic/StandardContainer";
import HorizBox from "../../styled/generic/HorizBox";
import Button from "../../styled/generic/Button";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import { Add, SettingsOutlined } from "@material-ui/icons";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import { LuMessagesSquare } from "react-icons/lu";
import { PiHammerDuotone } from "react-icons/pi";
import Api from "../../../helpers/Api";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import Bookings from "./Bookings";
import Listings from "../Listings";
import Transaction from "../transaction/Transaction";
import DemandDeeds from "../demanddeed/DemandDeeds";
import CreateDemandDeedDrawer from "./demandDeed/CreateDemandDeedDrawer";
import CreateTransactionModal from "./CreateTransactionModal";
import BookingOffers from "../offers/BookingOffers";
import CreateOfferModel from "../offers/CreateOfferModel";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BookingAnalytics from "../analytics/BookingAnalytics";

const BookingHome = () => {
  const history = useHistory();
  const location = useLocation();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [tab, setTab] = useState("Booking");
  const [showCreateDemandDeedDrawer, setShowCreateDemandDeedDrawer] =
    useState(false);
  const [showCreateTransactionModal, setShowCreateTransactionModal] =
    useState(false);
  const [walletData, setWalletData] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showCreateOfferModel, setShowCreteOfferModel] = useState(false);

  const getTabFromQuery = () => {
    const params = new URLSearchParams(location.search);
    if (params.get("tab")) {
      return params.get("tab");
    } else {
      setQuery("tab", "Booking");
      return "Booking";
    }
  };
  useEffect(() => {
    setTab(getTabFromQuery());
  }, []);

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId }).then((data) => {
        if (data) {
          setWalletData(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Failed to fetch wallet data",
            },
          });
        }
        setPageLoading(false);
      });
    }
  }, [profileId, dispatch]);

  useEffect(() => {
    if (walletData?.defaultLibrary) {
      getBookings();
    }
  }, [walletData]);

  const getBookings = async () => {
    const data = await Api.post("/listing/booking/get", {
      libraryId: walletData?.defaultLibrary,
    });
    if (data) {
      setBookings(data.bookings);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch bookings",
        },
      });
    }
  };

  const createBooking = async () => {
    const response = await Api.post("/listing/booking/create", {
      libraryId: walletData?.defaultLibrary,
    });
    if (response?.data) {
      history.push(`/booking/edit/${response.data._id}`);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to create booking",
        },
      });
    }
  };

  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setShowCreateTransactionModal(true);
  };

  const setQuery = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set("tab", value);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const handleTabChange = (val) => {
    setTab(val);
    console.log(val);
    setQuery("tab", val);
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Booking"
      loading={pageLoading}
      appBarActions={
        <HorizBox>
          <Tooltip title="Issues" enterDelay={1500}>
            <IconButton
              onClick={() => history.push(`/booking/issues/${profileId}`)}
            >
              <PiHammerDuotone style={{ fontSize: "1.3rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Messages" enterDelay={1500}>
            <IconButton
              onClick={() => history.push(`/booking/messages/${profileId}`)}
            >
              <LuMessagesSquare style={{ fontSize: "1.3rem" }} />
            </IconButton>
          </Tooltip>
          {tab === "Booking" && (
            <Button
              variant="contained"
              color="primary"
              onClick={createBooking}
              startIcon={<Add />}
            >
              New Booking
            </Button>
          )}
          {tab === "Transaction" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreateTransactionModal(true)}
              startIcon={<Add />}
            >
              New Transaction
            </Button>
          )}
          {tab === "Demand Deed" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreateDemandDeedDrawer(true)}
              startIcon={<Add />}
            >
              New Demand Deed
            </Button>
          )}
          {tab === "Offer" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreteOfferModel(true)}
              startIcon={<Add />}
            >
              New Offer
            </Button>
          )}
          <IconButton
            variant="primary"
            onClick={() => history.push(`/booking/settings/${profileId}`)}
          >
            <SettingsOutlined />
          </IconButton>
        </HorizBox>
      }
    >
      {walletData?.defaultLibrary ? (
        <>
          <StandardAppContainerRounded>
            <TabsWithActions
              onTabChange={handleTabChange}
              currentTabVariable={tab}
              tabs={[
                { title: "Booking" },
                { title: "Listing" },
                { title: "Demand Deed" },
                { title: "Transaction" },
                { title: "Offer" },
                {
                  title: "Analytics",
                },
              ]}
            />
          </StandardAppContainerRounded>
          <Box sx={{ width: "100%", height: "81vh", overflowY: "scroll" }}>
            {tab === "Booking" && (
              <Bookings libraryId={walletData?.defaultLibrary} />
            )}
            {tab === "Listing" && <Listings />}
            {tab === "Demand Deed" && <DemandDeeds bookings={bookings} />}
            {tab === "Transaction" && (
              <Transaction
                libraryId={walletData?.defaultLibrary}
                setOldTransaction={handleEditTransaction}
              />
            )}
            {tab === "Offer" && (
              <BookingOffers libraryId={walletData?.defaultLibrary} />
            )}
            {tab === "Analytics" && (
              <BookingAnalytics libraryId={walletData?.defaultLibrary} />
            )}
          </Box>
          <CreateOfferModel
            open={showCreateOfferModel}
            setOpen={setShowCreteOfferModel}
            libraryId={walletData?.defaultLibrary}
          />
          <CreateTransactionModal
            isOpen={showCreateTransactionModal}
            onClose={() => {
              setShowCreateTransactionModal(false);
              setSelectedTransaction(null);
            }}
            initialData={selectedTransaction}
            libraryId={walletData?.defaultLibrary}
          />
          <CreateDemandDeedDrawer
            isOpen={showCreateDemandDeedDrawer}
            setIsOpen={setShowCreateDemandDeedDrawer}
          />
        </>
      ) : (
        <Box sx={{ padding: "20px" }}>
          <Typography variant="body1">
            No Library exists in the current directory or any library still
            didn't selected as default library.
            <br />
            Click{" "}
            <Typography
              component="span"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                history.push(`/offerings-categories/management/${profileId}`)
              }
            >
              here
            </Typography>{" "}
            to create and manage your library(s), category(s), product(s) and
            service(s)
          </Typography>
        </Box>
      )}
    </StandardContainer>
  );
};

export default BookingHome;
