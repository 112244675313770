import {
  Button,
  CircularProgress,
  Grid,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormBox from "../../styled/generic/FormBox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CiFilter } from "react-icons/ci";
import { MdExpandMore } from "react-icons/md";
import Api from "../../../helpers/Api";
import { ArrowLeft } from "@mui/icons-material";
import { Add, ArrowRight } from "@material-ui/icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import {
  Payment as PaymentIcon,
  Schedule as ScheduleIcon,
  Receipt as TransactionIcon,
  AccountBalance as ProjectIcon,
  Apartment as BlockIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";

const FinancialCard = ({ data }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const history = useHistory();
  const { profileId } = useParams();

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        "&:hover": { boxShadow: 6, transition: "box-shadow 0.3s" },
      }}
      onClick={() => {
        history.push(
          `/booking/settings/${profileId}?view=demandDeedSettingsId&settingid=${data._id}`
        );
      }}
    >
      <CardContent>
        <Typography variant="h6" color="primary" gutterBottom>
          {data.title || "Undefined"}
        </Typography>
        <Box
          sx={{ my: 2, display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <FinancialDetail
            label="Project Name"
            value={data?.project?.displayName}
            icon={
              <ProjectIcon
                sx={{ color: "primary.main", mr: 1, fontSize: "1.2rem" }}
              />
            }
          />
          <FinancialDetail
            label="Block Name"
            value={data?.projectBlock?.name}
            icon={
              <BlockIcon
                sx={{ color: "primary.main", mr: 1, fontSize: "1.2rem" }}
              />
            }
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Chip
            label={`Transactions ${data.transactions.length}`}
            size="small"
            variant="outlined"
            sx={{
              ml: 1,
              bgcolor: "primary.light",
              color: "primary.contrastText",
            }}
          />
        </Box>
      </CardContent>
      <TransactionModal
        transactions={data.transactions}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Card>
  );
};

const TransactionModal = ({ transactions, open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Transactions</DialogTitle>
    <DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell>{transaction.date}</TableCell>
              <TableCell>{transaction.amount}</TableCell>
              <TableCell>{transaction.type}</TableCell>
              <TableCell>{transaction.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
  </Dialog>
);

const FinancialDetail = ({ label, value, icon }) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
    {icon}
    <Typography variant="body1" sx={{ color: "text.secondary", mr: 1 }}>
      {label}:
    </Typography>
    <Typography variant="body1" sx={{ fontWeight: "medium" }}>
      {value || "N/A"}
    </Typography>
  </Box>
);

const DemandDeedSettings = ({ libraryId }) => {
  const [schedules, setSchedules] = useState([]);
  const [paymentTables, setPaymentTables] = useState([]);
  const [selectedSchedules, setSelectSchedules] = useState([]);
  const [selectedPaymentTables, setSelectPaymentTables] = useState([]);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [demandDeedSettings, setDemandDeedSettings] = useState([]);
  const { profileId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchTableAndSchedule = async () => {
    const [paymentTablesData, schedulesData] = await Promise.all([
      Api.post("/listing/booking/payment/table/get", {
        libraryId: libraryId,
      }),
      Api.post("/listing/booking/schedule/get", {
        libraryId,
      }),
    ]);
    setSchedules(schedulesData.data);
    setPaymentTables(paymentTablesData.data);
  };

  useEffect(() => {
    if (libraryId) {
      fetchTableAndSchedule();
    }
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchDemandDeedSettings = async (page = 1, limit = 12) => {
    // Make API request to fetch data
    const data = await Api.post(
      `/listing/booking/payment/demanddeedsetting/get?page=${page}&limit=${limit}`,
      {
        libraryId: libraryId,
        paymentTableFilter: "",
        paymentScheduleFilter: "",
      }
    );

    if (data) {
      const { demandDeedSettings, totalPages, currentPage } = data;
      setDemandDeedSettings(demandDeedSettings);
      setTotalPages(totalPages);
      setCurrentPage(currentPage);
    } else {
      console.log("No data found");
      // Consider using a more user-friendly notification system
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while fetching demand deed settings try again later",
        },
      });
    }
  };

  useEffect(() => {
    fetchDemandDeedSettings(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);
    if (selectedPaymentTables && selectedSchedules) {
      fetchDemandDeedSettings(currentPage).finally(() => {
        setLoading(false);
      });
    }
  }, [selectedPaymentTables, selectedSchedules]);

  return (
    <Box>
      <Box
        sx={{
          marginBottom: "1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">Demand deed Settings</Typography>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            history.push(
              `/booking/settings/${profileId}?view=createdemanddeed`
            );
          }}
        >
          Create
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "15px",
        }}
      >
        <FormBox
          label={"Select Schedule"}
          sx={{
            width: "250px",
          }}
        >
          <Select
            placeholder={"Schedule"}
            fullWidth={true}
            size="small"
            onChange={(e) => {
              setSelectSchedules(e.target.value);
            }}
          >
            {schedules
              ?.filter((x) => x._id !== selectedSchedules)
              .map((schedule, index) => {
                if (!schedule?.title) return null;
                return (
                  <MenuItem key={index} value={schedule?._id}>
                    {schedule?.title}
                  </MenuItem>
                );
              })}
            {schedules
              ?.filter((x) => x._id === selectedSchedules)
              .map((schedule, index) => {
                if (!schedule?.title) return null;
                return (
                  <MenuItem
                    sx={{
                      display: "none",
                    }}
                    key={index}
                    value={schedule?._id}
                  >
                    {schedule?.title}
                  </MenuItem>
                );
              })}
          </Select>
        </FormBox>
        <FormBox
          label={"Select Payment Table"}
          sx={{
            width: "300px",
          }}
        >
          <Select
            label={"Payment Table"}
            fullWidth={true}
            size="small"
            onChange={(e) => {
              setSelectPaymentTables(e.target.value);
            }}
          >
            {paymentTables
              ?.filter((x) => x._id !== selectedSchedules)
              .map((paymentTable, index) => {
                if (!paymentTable?.title) return null;
                return (
                  <MenuItem key={index} value={paymentTable?._id}>
                    {paymentTable?.title}
                  </MenuItem>
                );
              })}
            {paymentTables
              ?.filter((x) => x._id === selectedSchedules)
              .map((paymentTable, index) => {
                if (!paymentTable?.title) return null;
                return (
                  <MenuItem
                    sx={{
                      display: "none",
                    }}
                    key={index}
                    value={paymentTable?._id}
                  >
                    {paymentTable?.title}
                  </MenuItem>
                );
              })}
          </Select>
        </FormBox>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          marginBottom: "1.5rem",
        }}
      >
        <TextField
          placeholder="Search demand deed"
          fullWidth
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <div>
          <Button
            disabled={true}
            onClick={handleClick}
            size="large"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            startIcon={<CiFilter />}
            endIcon={<MdExpandMore />}
          >
            Filter
          </Button>
          <Menu
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              width: "100%",
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
        </div>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "65vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            minHeight: "65vh",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Grid
            container
            spacing={1.5}
            sx={{
              display: "grid",
              gridTemplateColumns: ["repeat(1, minmax(0, 1fr))", "repeat(2, minmax(0, 1fr))", "repeat(3, minmax(0, 1fr))", "repeat(4, minmax(0, 1fr))"],
            }}
          >
            {demandDeedSettings
              .filter((data) => {
                const regex = new RegExp(search, "i");
                return regex.test(data.title);
              })
              .map((data, index) => {
                return (
                  <Grid
                    item
                    sx={{
                      gridColumn: "span 1 / span 1",
                    }}
                  >
                    <FinancialCard data={data} key={index} />
                  </Grid>
                );
              })}
          </Grid>
          <Box
            sx={{
              width: "100%",
              gap: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              disabled={currentPage <= 1}
              onClick={() => handlePageChange(Number(currentPage) - 1)}
              variant="text"
              startIcon={<ArrowLeft />}
            >
              Previous
            </Button>
            <Typography variant="body1">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button
              disabled={currentPage >= totalPages || totalPages === 0}
              onClick={() => handlePageChange(Number(currentPage) + 1)}
              variant="text"
              endIcon={<ArrowRight />}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DemandDeedSettings;
