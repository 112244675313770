import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Button, IconButton, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    inputsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        "& .halfInput": {
            width: "49%",
            padding: "10px"
        },
        "& .fullInput": {
            width: "100%",
            padding: "10px"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
        }
    },
    accountsInputsCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        borderRadius: "5px",
        border: "1px solid #ececec",
        marginTop: "25px",
        position: "relative"
    },
    bankheader: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 10px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500",
        }
    },
    deleteBtn: {
        position: "absolute",
        right: "-25px",
        top: "-25px",
        color: theme.palette.primary.main
    }
}));

export default function VendorForm({
    vendorData, setVendorData
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    return (
        <div className={classes.inputsCont} >
            <div className="halfInput" >
                <p>GST No.</p>
                <TextField
                    placeholder={`Enter GST No.`}
                    fullWidth
                    value={vendorData?.gst}
                    onChange={(e) => {
                        setVendorData({
                            ...vendorData,
                            gst: e.target.value
                        })
                    }}
                    variant="outlined"
                    size="small"
                />
            </div>
            <div className="halfInput" >
                <p>Pan No.</p>
                <TextField
                    placeholder={`Enter Pan No.`}
                    fullWidth
                    value={vendorData?.pan}
                    onChange={(e) => {
                        setVendorData({
                            ...vendorData,
                            pan: e.target.value
                        })
                    }}
                    variant="outlined"
                    size="small"
                />
            </div>

            <div className={classes.bankheader} >
                <h3>Bank Accounts</h3>

                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        let locVendorData = JSON.parse(JSON.stringify(vendorData))
                        let locBanks = locVendorData?.banks || []
                        let newObj = {
                            beneficiaryName: "",
                            bankName: "",
                            accountNumber: "",
                            ifsc: "",
                            branchName: ""
                        }
                        let arr = [newObj, ...locBanks]
                        locVendorData.banks = arr;
                        setVendorData(locVendorData)
                    }}
                >
                    Add New
                </Button>
            </div>

            {vendorData?.banks && vendorData.banks.map((bank, index) => (
                <div className={classes.accountsInputsCont} >
                    <IconButton 
                    className={classes.deleteBtn} 
                    onClick={()=>{
                        let locVendorData = JSON.parse(JSON.stringify(vendorData))
                        let locBanks = locVendorData?.banks || []
                        locBanks.splice(index, 1)
                        locVendorData.banks = locBanks;
                        setVendorData(locVendorData)
                    }}
                    >
                        <CancelIcon style={{ fontSize: "35px" }} />
                    </IconButton>
                    <div className="fullInput" >
                        <p>Beneficiary Name</p>
                        <TextField
                            placeholder={`Enter Beneficiary Name`}
                            fullWidth
                            value={bank?.beneficiaryName}
                            onChange={(e) => {
                                let locVendorData = JSON.parse(JSON.stringify(vendorData))
                                let locBanks = locVendorData?.banks || [];
                                locBanks[index].beneficiaryName = e.target.value;
                                locVendorData.banks = locBanks;
                                setVendorData(locVendorData)
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div className="halfInput" >
                        <p>Bank Name</p>
                        <TextField
                            placeholder={`Enter Bank Name`}
                            fullWidth
                            value={bank?.bankName}
                            onChange={(e) => {
                                let locVendorData = JSON.parse(JSON.stringify(vendorData))
                                let locBanks = locVendorData?.banks || [];
                                locBanks[index].bankName = e.target.value;
                                locVendorData.banks = locBanks;
                                setVendorData(locVendorData)
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </div>

                    <div className="halfInput" >
                        <p>Account Number</p>
                        <TextField
                            placeholder={`Enter Account Number`}
                            fullWidth
                            value={bank?.accountNumber}
                            onChange={(e) => {
                                let locVendorData = JSON.parse(JSON.stringify(vendorData))
                                let locBanks = locVendorData?.banks || [];
                                locBanks[index].accountNumber = e.target.value;
                                locVendorData.banks = locBanks;
                                setVendorData(locVendorData)
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </div>

                    <div className="halfInput" >
                        <p>IFSC Code</p>
                        <TextField
                            placeholder={`Enter IFSC Code`}
                            fullWidth
                            value={bank?.ifsc}
                            onChange={(e) => {
                                let locVendorData = JSON.parse(JSON.stringify(vendorData))
                                let locBanks = locVendorData?.banks || [];
                                locBanks[index].ifsc = e.target.value;
                                locVendorData.banks = locBanks;
                                setVendorData(locVendorData)
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </div>

                    <div className="halfInput" >
                        <p>Branch Name</p>
                        <TextField
                            placeholder={`Enter Branch Name`}
                            fullWidth
                            value={bank?.branchName}
                            onChange={(e) => {
                                let locVendorData = JSON.parse(JSON.stringify(vendorData))
                                let locBanks = locVendorData?.banks || [];
                                locBanks[index].branchName = e.target.value;
                                locVendorData.banks = locBanks;
                                setVendorData(locVendorData)
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </div>

                </div>
            ))}
        </div>
    );
}