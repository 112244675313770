import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    createFileDocs, docFileRemoveAndUpdate, docFileRemoveAndDelete
} from '../../finance2o/commonComponent/transaction/api';
import emptyFile from "../../../Assets/emptyData.svg"
import { updateFileInParent } from './api.call';
import { TextField, Button } from "@mui/material";
import CustomFileUploadButton from '../../file/Uploader/CustomFileUploadButton';
import { MdAddPhotoAlternate } from "react-icons/md";
import AllKindOfDocViews from './AllKindOfDocViews';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    flexCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "30px",
        "& h2": {
            fontSize: "18px",
            fontWeight: "600",
        },
    },
    fileUploadCont: {
        width: "300px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    emptyCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            height: "200px",
            width: "auto",
            marginBottom: "10px"
        },
        "& p": {
            fontSize: "14px",
            color: "gray"
        }
    },
}));

export default function AttachmentsHandler({
    parentData, setParentData, parentModel, setLoadingBool, needToMakeDisable
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);

    const [docName, setDocName] = useState("Receipt Copy")
    const [imageTitleArr, setImageTitleArr] = useState([])

    const intitalizeImageTitle = () => {
        const txDocs = parentData?.attachedFiles || []
        let arrData = [];
        txDocs.map((docData) => {
            const docFile = docData?.files || []
            docFile.map((fileData) => {
                if (fileData?.url) {
                    arrData.push({
                        ...fileData,
                        numDocFile: docFile.length,
                        name: docData?.title || "",
                        docId: docData?._id
                    })
                }
            })
        })
        setImageTitleArr(arrData)
    }

    useEffect(() => {
        intitalizeImageTitle()
    }, [parentData, parentData?.attachedFiles])

    const createNewDocs = async () => {
        if (createdFileIds.length > 0) {
            setLoadingBool(true)
            await createFileDocs({
                title: docName,
                description: "Invoice Uploaded File",
                files: createdFileIds,
                profile: user?.profile,
                user: user?._id,
                parent: parentData?._id,
                parentModelName: parentModel
            })
                .then(async (data) => {
                    const txDocs = parentData?.attachedFiles || []
                    let docIds = []
                    txDocs.length > 0 && txDocs.map((txDoc) => {
                        docIds.push(txDoc?._id)
                    })

                    let newDocsArr = [...docIds, data._id]

                    await updateFileInParent({
                        parentModel,
                        parentId: parentData?._id,
                        updateObj: {
                            attachedFiles: newDocsArr,
                        }
                    }).then((updatedTxRes) => {
                        txDocs.push(data)

                        let updatedTx = {
                            ...parentData,
                            attachedFiles: txDocs
                        }

                        setParentData(updatedTx)
                        intitalizeImageTitle()
                    })
                        .catch((err) => {
                            console.log(err)
                        })
                })
                .catch((err) => {
                    console.log(err);
                })

            dispatch({ type: "FileUploadReset" });
            setDocName("Receipt Copy")
            setLoadingBool(false)
        }
    }

    const deleteFile = async (fileId) => {
        const fileData = imageTitleArr.filter((s) => s?._id == fileId)[0]
        setLoadingBool(true)
        if (fileData?.numDocFile > 1) {
            await docFileRemoveAndUpdate({
                docId: fileData?.docId,
                fileId: fileData?._id
            })
                .then((data) => {
                    const txDocs = parentData?.attachedFiles || []
                    let docData = []
                    txDocs.length > 0 && txDocs.map((txDoc) => {
                        if (txDoc?._id === fileData?.docId) {
                            docData.push(data)
                        } else {
                            docData.push(txDoc)
                        }
                    })
                    let updatedTx = {
                        ...parentData,
                        attachedFiles: docData
                    }
                    setParentData(updatedTx)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            await docFileRemoveAndDelete({
                docId: fileData?.docId,
                fileId: fileData?._id
            })
                .then(async (data) => {
                    const txDocs = parentData?.attachedFiles || []
                    let docIds = []
                    let docData = []
                    txDocs.length > 0 && txDocs.map((txDoc) => {
                        if (txDoc?._id !== fileData?.docId) {
                            docData.push(txDoc)
                            docIds.push(txDoc?._id)
                        }
                    })
                    await updateFileInParent({
                        parentModel,
                        parentId: parentData?._id,
                        updateObj: {
                            attachedFiles: docIds,
                        }
                    })
                        .then((updatedTxRes) => {
                            let updatedTx = {
                                ...parentData,
                                attachedFiles: docData
                            }
                            setParentData(updatedTx)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        intitalizeImageTitle()
        setLoadingBool(false)
    }

    return (
        <div className={classes.root} >
            <div className={classes.flexCont} >
                <h2>Attachments</h2>
                <div className={classes.fileUploadCont} >
                    <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        size="small"
                        placeholder="Enter title"
                        style={{ width: "225px" }}
                        value={docName}
                        disabled={needToMakeDisable}
                        onChange={(e) => { setDocName(e.target.value) }}
                    />

                    {!needToMakeDisable && docName?.length > 2 ? (
                        <CustomFileUploadButton
                            showComponent={
                                <Button variant="outlined" color="primary">
                                    <MdAddPhotoAlternate style={{ color: theme.palette.primary.main, fontSize: "27px" }} />
                                </Button>
                            }
                            parentType={parentModel}
                            parentId={parentData?._id}
                            fileNum={25}
                            givenFileTypes={["image", "video", "html", "xhtml", "csv", "doc", "docx", "pdf", "rtf", "plain", "ppt", "pptx", "xls", "xlsx"]}
                            givenMaxSize={26214400}
                            closeFunCall={createNewDocs}
                        />
                    ) : (
                        <Button variant="outlined" color="primary" disabled >
                            <MdAddPhotoAlternate style={{ color: "gray", fontSize: "27px" }} />
                        </Button>
                    )}
                </div>
            </div>
            {imageTitleArr && imageTitleArr.length > 0 ? (<>
                <AllKindOfDocViews
                    files={imageTitleArr}
                    removeFun={deleteFile}
                    isDeletable={true}
                />
            </>) : (<div className={classes.emptyCont} >
                <img src={emptyFile} alt="empty image" />
                <p>No attachment available. Click above button to add attachment(s)</p>
            </div>)}
        </div>
    );
}