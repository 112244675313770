import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "./BoxSpaceBetween";
import Select from "./Select";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import HorizBox from "./HorizBox";
import Api from "../../../helpers/Api";

const BankAccountPicker = ({
  size = "medium",
  selectedBankAccount,
  setSelectedBankAccount,
  defaultBankAccountId,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const MODES = ["User", "Project", "Organization"];
  const [mode, setMode] = useState("User");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        setProjects((prev) => [...prev, team?.parent]);
      }
    }
  };

  useEffect(() => {
    setUpOrgs();
    setUpProjects();
  }, []);

  const getBankAccountsByMode = async () => {
    try {
      let _id =
        mode === "Organization"
          ? selectedOrg
          : mode === "Project"
          ? selectedProject
          : user._id;

      if (!_id) return console.log("No _id found");

      const { data } = await Api.post("/wallet/bank/getBanksByMode", {
        modeId: _id,
        mode: mode,
      });
      setBankAccounts(data);
    } catch (err) {
      console.error(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getBankAccountsByMode();
  }, [mode, selectedOrg, selectedProject]);

  useEffect(() => {
    setSelectedBankAccount();
  }, [mode]);

  useEffect(() => {
    if (defaultBankAccountId) {
      console.log("Setting default BankAccount", defaultBankAccountId);
      setSelectedBankAccount(defaultBankAccountId);
    }
  }, [defaultBankAccountId]);

  return (
    <HorizBox>
      <FormControl>
        <InputLabel id="mode">Mode</InputLabel>
        <Select
          value={mode}
          onChange={(e) => setMode(e.target.value)}
          label="Mode"
          sx={{ minWidth: "120px" }}
          size={size}
        >
          {MODES.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {mode === "Project" && (
        <FormControl>
          <InputLabel id="Project">Project</InputLabel>
          <Select
            label="Project"
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            sx={{ minWidth: "120px" }}
            size={size}
          >
            {projects.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {mode === "Organization" && (
        <FormControl>
          <InputLabel id="Organization">Organization</InputLabel>
          <Select
            label="Organization"
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
          >
            {orgs.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <InputLabel id="BankAccount">Bank Account</InputLabel>
        <Select
          label="BankAccount"
          value={selectedBankAccount}
          onChange={(e) => setSelectedBankAccount(e.target.value)}
          sx={{ minWidth: "120px" }}
        >
          <MenuItem value="">Select</MenuItem>
          {bankAccounts?.map((item) => (
            <MenuItem key={item?._id} value={item?._id}>
              {item?.bankName || "Untitled"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </HorizBox>
  );
};

export default BankAccountPicker;
