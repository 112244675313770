import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import StatusChip from "../../styled/generic/StatusChip";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
  IconButton,
  MenuItem,
  Stack,
  Button,
  Typography,
  Pagination,
  useTheme,
  Select,
  Menu,
} from "@mui/material";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import { useHistory } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import config from "../../../config/index";

const DemandDeeds = ({ bookings }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [demandDeeds, setDemandDeeds] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedDemandDeeds, setSelectedDemandDeeds] = useState(new Set());
  const [statusFilter, setStatusFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [bulkActionAnchor, setBulkActionAnchor] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDialogMessage, setSelectedDialogMessage] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const confirmationDialogContent = [
    {
      title: "Confirm Status Change to Sent",
      message:
        "Are you sure you want to set the status to Sent? Once confirmed, the demand deed will become uneditable, and this action cannot be undone.",
      confirmFn: () => {
        handleBulkStatusUpdate("Sent");
      },
      cancelFn: () => {
        setDialogOpen(false);
      },
    },
    {
      title: "Action Restricted: Verification Required",
      message:
        "You cannot set the status to Sent because your business email has not been verified. Please verify your email to proceed. Click Confirm to go to the Accounts section.",
      confirmFn: () => {
        var isDev = config?.mode == "dev";
        const redirectUrl = isDev
          ? `${config.BASE_URL}account`
          : `https://accounts.reallist.ai/account`;
        window.open(redirectUrl);
      },
      cancelFn: () => {
        setDialogOpen(true);
      },
    },
  ];

  const getDemandDeeds = async () => {
    setPageLoading(true);
    const data = await Api.post(
      "/listing/booking/payment/demanddeed/get-by-bookings",
      {
        bookingIds: bookings?.map((booking) => booking?._id),
        page,
        limit: 10,
        searchTerm,
        sortBy,
        sortOrder,
        status: statusFilter,
      }
    );
    if (data) {
      setDemandDeeds(data.data);
      setTotalPages(data.totalPages);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setPageLoading(false);
  };

  useEffect(() => {
    getDemandDeeds();
  }, [bookings]);

  useEffect(() => {
    const timeout = setTimeout(getDemandDeeds, 800);
    return () => clearTimeout(timeout);
  }, [page, sortBy, sortOrder, statusFilter]);

  useEffect(() => {
    const timeout = setTimeout(getDemandDeeds, 1500);
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = new Set(filteredDemandDeeds.map((n) => n._id));
      setSelectedDemandDeeds(newSelected);
    } else {
      setSelectedDemandDeeds(new Set());
    }
  };

  const handleSingleSelect = (id) => {
    setSelectedDemandDeeds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const filteredDemandDeeds = demandDeeds;

  const handleSave = () => {
    if (!user?.defaultBusinessEmail) {
      setDialogOpen(true);
      return;
    }
    setDialogOpen(true);
    setSelectedDialogMessage(0);
    return;
  };

  const handleBulkStatusUpdate = async (newStatus) => {
    try {
      const response = await Api.post(
        "/listing/booking/payment/demanddeed/update-bulk",
        {
          demandDeedsIds: Array.from(selectedDemandDeeds),
          status: newStatus,
        }
      );

      if (response) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: response.message,
          },
        });
        getDemandDeeds();
        setSelectedDemandDeeds(new Set());
      } else {
        throw new Error("Failed to update demand deeds");
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message:
            error.message || "An error occurred while updating demand deeds",
        },
      });
    }
    setBulkActionAnchor(null);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSortChange = (event) => {
    const [newSortBy, newSortOrder] = event.target.value.split(":");
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setPage(1);
  };

  if (pageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="85vh"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StandardAppContainerRounded>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ flexGrow: 1, mr: 2 }}
          />
          <Select
            label="Status"
            variant="outlined"
            size="small"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            sx={{ minWidth: 150, mr: 2 }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="Checking">Checking</MenuItem>
            <MenuItem value="Sent">Sent</MenuItem>
            <MenuItem value="Paid">Paid</MenuItem>
          </Select>
          <Select
            label="Sort"
            variant="outlined"
            size="small"
            value={`${sortBy}:${sortOrder}`}
            onChange={handleSortChange}
            sx={{ minWidth: 150, mr: 2 }}
          >
            <MenuItem value="createdAt:desc">Newest First</MenuItem>
            <MenuItem value="createdAt:asc">Oldest First</MenuItem>
            <MenuItem value="title:asc">Title (A-Z)</MenuItem>
            <MenuItem value="title:desc">Title (Z-A)</MenuItem>
          </Select>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setStatusFilter("All");
              setSelectedDemandDeeds(new Set());
              setEnableSelect(!enableSelect);
            }}
          >
            {enableSelect ? "Disable Select" : "Enable Select"}
          </Button>
          {enableSelect && selectedDemandDeeds.size > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setBulkActionAnchor(e.currentTarget)}
              sx={{ ml: 2 }}
            >
              Bulk Actions
            </Button>
          )}
        </Box>

        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: enableSelect
                ? "auto 3fr 2fr 2fr 1fr 1fr 1fr 1fr auto"
                : "3fr 2fr 2fr 1fr 1fr 1fr 1fr auto",
              gap: 2,
              p: 2,
              bgcolor: "action.hover",
              alignItems: "center",
            }}
          >
            {enableSelect && (
              <Checkbox
                onChange={handleSelectAll}
                checked={selectedDemandDeeds.size === demandDeeds.length}
                indeterminate={
                  selectedDemandDeeds.size > 0 &&
                  selectedDemandDeeds.size < demandDeeds.length
                }
              />
            )}
            {[
              "Title",
              "Booking",
              "Slabs",
              "Current Due",
              "Total Paid",
              "Booking Date",
              "Status",
              "Action",
            ].map((title, index) => (
              <Typography key={index} variant="subtitle2" fontWeight="bold">
                {title}
              </Typography>
            ))}
          </Box>
          <Divider />
          {demandDeeds.length > 0 ? (
            demandDeeds.map((demandDeed) => (
              <DemandDeedCard
                key={demandDeed._id}
                enableSelect={enableSelect}
                demandDeed={demandDeed}
                selectedDemandDeeds={selectedDemandDeeds}
                onClick={() => history.push(`demanddeed/${demandDeed._id}`)}
                handleChecked={() => handleSingleSelect(demandDeed._id)}
              />
            ))
          ) : (
            <Box
              sx={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">No Demand Deeds found</Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Stack>

      <Menu
        anchorEl={bulkActionAnchor}
        open={Boolean(bulkActionAnchor)}
        onClose={() => setBulkActionAnchor(null)}
      >
        <MenuItem onClick={handleSave}>Set as Sent</MenuItem>
      </Menu>

      <ConfirmationDialog
        cancelButtonText="Cancel"
        cancelListener={
          confirmationDialogContent[selectedDialogMessage].cancelFn
        }
        successButtonText="Confirm"
        successListener={
          confirmationDialogContent[selectedDialogMessage].confirmFn
        }
        open={dialogOpen}
        setOpen={setDialogOpen}
        message={confirmationDialogContent[selectedDialogMessage].message}
        title={confirmationDialogContent[selectedDialogMessage].title}
      />
    </StandardAppContainerRounded>
  );
};

const DemandDeedCard = ({
  demandDeed,
  onClick,
  enableSelect,
  selectedDemandDeeds,
  handleChecked,
}) => {
  const currentDue = demandDeed.transactions.reduce(
    (acc, current) => Number(current.paymentDue) + acc,
    0
  );

  const totalPaid = demandDeed.transactions.reduce(
    (acc, current) => Number(current.amountPaid) + acc,
    0
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: enableSelect
          ? "auto 3fr 2fr 2fr 1fr 1fr 1fr 1fr auto"
          : "3fr 2fr 2fr 1fr 1fr 1fr 1fr auto",
        alignItems: "center",
        gap: 2,
        p: 2,
        borderBottom: "1px solid",
        borderColor: "divider",
        "&:hover": { bgcolor: "action.hover" },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {enableSelect && (
        <Checkbox
          checked={selectedDemandDeeds.has(demandDeed._id)}
          onChange={handleChecked}
          onClick={(e) => e.stopPropagation()}
        />
      )}

      <Typography variant="body2" noWrap>
        <div dangerouslySetInnerHTML={{ __html: demandDeed?.title }} />
      </Typography>

      <SingleUserWithCaptionTableCell
        label={demandDeed?.profile?.parent?.displayName || "Unknown"}
        url={demandDeed?.profile?.parent?.displayPicture?.url}
        caption={`${demandDeed?.booking?.unitType}`}
      />

      <Typography variant="body2" noWrap>
        {demandDeed?.table?.title}
      </Typography>

      <Typography variant="body2">₹{currentDue.toLocaleString()}</Typography>

      <Typography variant="body2">₹{totalPaid.toLocaleString()}</Typography>

      <Typography variant="body2">
        {new Date(demandDeed?.booking?.bookingDate).toLocaleDateString() ||
          "N/A"}
      </Typography>

      <StatusChip
        label={demandDeed?.status}
        variant={getStatusVariant(demandDeed?.status)}
        size="small"
      />

      <IconButton size="small" onClick={(e) => e.stopPropagation()}>
        <MoreVert />
      </IconButton>
    </Box>
  );
};

const getStatusVariant = (status) => {
  switch (status.toLowerCase()) {
    case "draft":
      return "default";
    case "checking":
      return "info";
    case "sent":
      return "warning";
    case "paid":
      return "success";
    default:
      return "default";
  }
};

export default DemandDeeds;
