import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    wrapperCont: {
        width: "100%",
        "& p": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "& h4": {
            fontSize: "12px",
            fontWeight: "400",
            color: "red"
        }
    },
    inputCont: {
        width: "100%",
        display: "flex",
        padding: "2px",
        alignItems: "center",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
        margin: "2px 0px",
        '& .MuiInput-underline:before': {
            borderBottom: 'none !important'
        },
        "& .MuiFormControl-root": {
            margin: "0px"
        }
    }
}));

export default function InputWrapper({
    lable, inputComp, validation
}) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();


    return (
        <div className={classes.wrapperCont} >
            <p>{lable}</p>
            <div className={classes.inputCont} >
                {inputComp}
            </div>
            <h4>{validation}</h4>
        </div>
    );
}