import React, { useState, useRef, useEffect } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import {
  Box,
  InputAdornment,
  TextField,
  Button,
  Typography,
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Card,
  Divider,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Paper,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import Picker from "emoji-picker-react";
import { Close, EmojiEmotions } from "@mui/icons-material";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import FileUploadButton from "../../file/Uploader/FileUploadButton";
import {
  updateDeleteFlagForManyFiles,
  updateDeleteFlagForSingleFiles,
} from "../../profileChat/apiCall";
import FilesViewer from "../../file/Viewer/FilesViewer";
import { LoadingButton } from "@mui/lab";

const ChatMessage = ({ message, isOwn, user, date, files }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isOwn ? "flex-end" : "flex-start",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "start",
          flexDirection: isOwn ? "row-reverse" : "row",
        }}
      >
        <Avatar sx={{ marginRight: 1 }} src={user?.parent?.displayPicture?.url}>
          {user?.parent?.displayname}
        </Avatar>
        <Box
          sx={{
            maxWidth: "60%",
            backgroundColor: isOwn ? "primary.main" : "grey.200",
            color: isOwn ? "white" : "text.primary",
            borderRadius: 2,
            padding: 1.5,
          }}
        >
          {files?.length > 0 && (
            <Box>
              <img src={files[0].url} alt="hello" />
            </Box>
          )}
          <Typography variant="body1" color={"inherit"}>
            {message}
          </Typography>
          <Typography variant="caption" color={"inherit"}>
            {date}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const BookingMessage = () => {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [pageLoading, setPageLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [filter, setFilter] = useState(false);
  const { createdFileIds } = useSelector((state) => state.file);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [showFilter, setShowFilter] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleEmojiClick = (emojiObject) => {
    setInputMessage((prevInput) => prevInput + emojiObject.emoji);
  };
  useEffect(scrollToBottom, [messages]);

  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [listings, setListings] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const [filterScreen, setFilterScreen] = useState({
    label: "Projects",
    size: projects.length,
  });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const BOOKING_STATUS = ["DRAFT", "SENT", "CHECKING", "PAID"];
  const FILTER_SCREENS = [
    { label: "Projects", size: projects.length },
    { label: "Listings", size: listings.length },
    {
      label: "Organization",
      size: organizations.length,
    },
  ];

  useEffect(() => {
    const getFilterData = async () => {
      const data = await Api.post(`/profile/get/admin/project/organization`, {
        givenProfiles: [user?.profile],
      });
      setOrganizations(() => data.data.organizations || []);
      console.log("organizations", data.data.organizations || []);
    };
    if (user) {
      getFilterData();
    }
  }, [user]);

  useEffect(() => {
    const getListingData = async () => {
      const listingData = await Promise.all(
        projects
          .filter((item) =>
            selectedProjects.length > 0
              ? selectedProjects.includes(item._id)
              : item
          )
          .map((project) =>
            Api.post(`/listing/getListingsByProject`, {
              projectId: project._id,
            })
          )
      );
      const AllListings = listingData.reduce((acc, item) => {
        return [...acc, ...item?.data];
      }, []);
      console.log(AllListings);
      setListings(AllListings);
    };
    if (projects?.length > 0) {
      getListingData();
    }
  }, [projects, selectedProjects]);

  const handleClearAll = () => {
    setSelectedProjects([]);
    setSelectedOrganizations([]);
    setSelectedListings([]);
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        if (projects.includes(team?.parent)) {
          continue;
        } else {
          setProjects((prev) => [...prev, team?.parent]);
        }
      }
    }
  };

  useEffect(() => {
    if (!projects.length > 0) {
      setUpProjects();
    }
  }, []);

  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      const data = await Api.post("chat/message", {
        conversation: selectedConversation.conversation._id,
        user: user.profile,
        text: inputMessage,
        files: createdFileIds,
      });
      if (data) {
        console.log(data);
        setMessages([
          ...messages,
          {
            _id: data?.data?.messages[0]?._id,
            text: data?.data?.messages[0]?.text,
            isOwn: true,
            user: data?.data?.messages[0]?.user,
            createdAt: new Date(
              data?.data?.messages[0]?.createdAt
            ).toLocaleDateString(),
            files: data?.data?.messages[0]?.files,
          },
        ]);
        setInputMessage("");
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred 2",
          },
        });
      }
    }
  };

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId: profileId })
        .then(async (data1) => {
          setWalletData(data1);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPageLoading(false);
        });
    }
  }, [profileId]);

  const getConversations = async () => {
    const payload = {
      libraryId: walletData?.defaultLibrary,
      projectIds: selectedProjects,
      organizationIds: selectedOrganizations,
      listingIds: selectedListings,
    };
    const data = await Api.post("/bookings/conversations/get", payload);
    return data;
  };

  useEffect(() => {
    if (listings.length > 0) {
      const updatedSelectedListings = selectedListings.filter((id) =>
        listings.some((item) => item._id === id)
      );
      setSelectedListings(updatedSelectedListings);
    }
  }, [listings]);

  useEffect(() => {
    setPageLoading(true);
    if (walletData?.defaultLibrary) {
      getConversations().then((response) => {
        if (response) {
          const data = response.data.conversations;
          setConversations(data);
        } else {
          console.log("No conversation found");
        }
        setPageLoading(false);
      });
    }
  }, [walletData]);

  const handleConversationChange = (index) => {
    setSelectedConversation(conversations[index]);
    console.log(conversations[index].messages);
    const structuredMessages = conversations[index].messages.map((item) => ({
      _id: item?._id,
      text: item?.text,
      isOwn: item?.user._id === user?.profile,
      user: item?.user,
      createdAt: new Date(item?.createdAt).toLocaleDateString(),
      files: item?.files,
    }));
    console.log("structuredMessages", structuredMessages);
    setMessages(structuredMessages);
  };

  useEffect(() => {
    if (conversations.length > 0) {
      handleConversationChange(0);
    }
  }, [conversations]);

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });

    await updateDeleteFlagForSingleFiles({ fileId: selectedId })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeImgFromReducerAndDelete = async () => {
    updateDeleteFlagForManyFiles({ fileIds: createdFileIds })
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch({ type: "FileUploadReset" });
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Messages"
      loading={pageLoading}
    >
      <Box
        sx={{
          display: "grid",
          height: "calc(100vh - 92px)",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        }}
      >
        {/* Left sidebar */}
        <Box
          sx={{
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            display: "flex",
            flexDirection: "column",
            padding: 2,
            gridColumn: "span 1 / span 1",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 2,
              gap: 1,
            }}
          >
            <TextField
              placeholder="Search Booking"
              size="small"
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiSearch />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  backgroundColor: "white",
                },
              }}
            />
            <Button variant="outlined" onClick={() => setShowFilter(true)}>
              Filter
            </Button>
          </Box>
          <Divider sx={{ marginBottom: 2 }} />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              height: "calc(100vh - 380px)",
            }}
          >
            {conversations.map(({ conversation, booking, messages }, index) => {
              return (
                <Paper key={index}>
                  <Card
                    key={index}
                    sx={{
                      borderRadius: 2,
                      padding: 2,
                      cursor: "pointer",
                      color:
                        selectedConversation?.conversation?._id ===
                          conversation?._id && "white",
                      backgroundColor:
                        selectedConversation?.conversation?._id ===
                          conversation?._id && "primary.main",

                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                    onClick={() => handleConversationChange(index)}
                    variant="outlined"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <Box>
                        <Avatar
                          sx={{ marginRight: 1 }}
                          src={conversation?.groupDP}
                          alt={"user name"}
                        >
                          {conversation?.groupName[0]}
                        </Avatar>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1">
                          {conversation?.groupName}-
                          {booking?.projectBlock?.project?.displayName}-
                          {booking?.unit?.name}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          {messages[messages.length - 1].text}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {new Date(
                            conversation.lastActive
                          ).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Paper>
              );
            })}
          </Box>
        </Box>

        {/* Right content area */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gridColumn: "span 3 / span 3",
          }}
        >
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
              backgroundColor: "primary.main",
            }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <SingleUserWithCaptionTableCell
                labelStyleProps={{
                  fontSize: 20,
                  fontWeight: 400,
                  color: "white",
                }}
                label={`${selectedConversation?.conversation?.groupName}-
                        ${selectedConversation?.booking?.projectBlock?.project?.displayName}-
                        ${selectedConversation?.booking?.unit?.name}`}
                url={selectedConversation?.conversation?.groupDP?.url}
              />
              <Tooltip title="More">
                <IconButton>
                  <MoreVertIcon color="white" />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              padding: 2,
              overflowY: "scroll",
              height: "calc(100vh - 230px)",
            }}
          >
            {createdFileIds.length > 0 && (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "95%",
                      height: "100%",
                      display: "flex",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      paddingTop: "22px",
                      overflowY: "auto",
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "20px",
                        right: "25px",
                      }}
                      onClick={() => {
                        removeImgFromReducerAndDelete();
                      }}
                    >
                      <Close />
                    </IconButton>
                    <div
                      style={{
                        maxWidth: "100%",
                        maxHeight: "84%",
                      }}
                    >
                      <FilesViewer
                        fileIds={createdFileIds}
                        deletable={true}
                        styleBody={{
                          width: "65%",
                          height: "auto",
                        }}
                        handler={removeSingleImgFromReducerAndDelete}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {messages.map((msg, index) => {
              return (
                <ChatMessage
                  key={index}
                  message={msg.text}
                  isOwn={msg.isOwn}
                  user={msg.user}
                  date={msg.createdAt}
                  files={msg.files}
                />
              );
            })}
            <div ref={messagesEndRef} />
          </Box>

          <Box
            sx={{
              width: "100%",
              padding: 2,
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "fit-content",
                }}
              >
                {showEmoji && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "100%",
                      left: 0,
                      zIndex: 1,
                    }}
                  >
                    <Picker
                      onEmojiClick={handleEmojiClick}
                      disableAutoFocus
                      native
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: 1,
              }}
            >
              <IconButton
                onClick={() => {
                  setShowEmoji((prev) => !prev);
                }}
              >
                <EmojiEmotions />
              </IconButton>
              <TextField
                placeholder="Type a message..."
                fullWidth
                value={inputMessage}
                onFocus={() => setShowEmoji(false)}
                onChange={(e) => setInputMessage(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <FileUploadButton
                        parentType="Message"
                        used={false}
                        parentId={null}
                      />
                    </IconButton>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                  },
                }}
              />
              <Button
                variant="contained"
                startIcon={
                  <SendIcon
                    style={{
                      transform: inputMessage.length > 0 && "rotate(-35deg)",
                      transition: "all 100ms  ease-in",
                      transitionDelay: "500",
                    }}
                  />
                }
                onClick={handleSendMessage}
                sx={{
                  minWidth: "100px",
                  borderRadius: 3,
                }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={showFilter}
        onClose={() => {
          if (!gridLoading) {
            setShowFilter(false);
          }
        }}
        variant="temporary"
        sx={{
          width: 500,
          flexShrink: 0,
          "& .MuiDrawer-paper": { width: 500 },
        }}
      >
        <Box
          sx={{
            px: 1.5,
          }}
        >
          <Box
            sx={{
              p: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
            }}
          >
            <Typography variant="h6">Filters</Typography>
            <Button
              disabled={gridLoading}
              variant="text"
              size="small"
              onClick={() => {
                setGridLoading(true);
                handleClearAll();
                getConversations().then((response) => {
                  if (response) {
                    const data = response.data.conversations;
                    setConversations(data);
                  } else {
                    console.log("No conversation found");
                  }
                  setGridLoading(false);
                });
              }}
            >
              Reset all
            </Button>
          </Box>
          <Box display={"flex"}>
            <List
              sx={{
                flex: 1.5,
              }}
            >
              {FILTER_SCREENS.map((filter, index) => (
                <ListItem
                  key={index}
                  button
                  sx={{
                    display: "flex",
                  }}
                  onClick={() => setFilterScreen(filter.label)}
                >
                  <ListItemText primary={filter.label} />
                  <Typography>{filter.size}</Typography>
                </ListItem>
              ))}
            </List>
            {filterScreen === "Projects" && (
              <List
                sx={{
                  flex: 2.5,
                }}
              >
                {projects.map((project, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (selectedProjects.includes(project._id)) {
                        setSelectedProjects((prev) =>
                          prev.filter((item) => item !== project._id)
                        );
                      } else {
                        setSelectedProjects((prev) => [...prev, project._id]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectedProjects.includes(project._id)}
                    />
                    <ListItemText primary={project.displayName} />
                  </ListItem>
                ))}
              </List>
            )}
            {filterScreen === "Listings" && (
              <List
                sx={{
                  flex: 2.5,
                }}
              >
                {listings.map((listing, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (selectedListings.includes(listing._id)) {
                        setSelectedListings((prev) =>
                          prev.filter((item) => item !== listing._id)
                        );
                      } else {
                        setSelectedListings((prev) => [...prev, listing._id]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectedListings.includes(listing._id)}
                    />
                    <ListItemText primary={listing?.title} />
                  </ListItem>
                ))}
              </List>
            )}
            {filterScreen === "Organization" && (
              <List
                sx={{
                  flex: 2.5,
                }}
              >
                {organizations.map((organization, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      if (selectedOrganizations.includes(organization._id)) {
                        setSelectedOrganizations((prev) =>
                          prev.filter((item) => item !== organization._id)
                        );
                      } else {
                        setSelectedOrganizations((prev) => [
                          ...prev,
                          organization._id,
                        ]);
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectedOrganizations.includes(organization._id)}
                    />
                    <ListItemText primary={organization?.parent?.displayName} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1.5rem",
              gap: "15px",
            }}
          >
            <Button
              disabled={gridLoading}
              variant="outlined"
              onClick={() => {
                setShowFilter(false);
              }}
            >
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={gridLoading}
              onClick={() => {
                setGridLoading(true);
                getConversations().then((response) => {
                  if (response) {
                    const data = response.data.conversations;
                    setConversations(data);
                  } else {
                    console.log("No conversation found");
                    setConversations([]);
                  }
                  setGridLoading(false);
                });
              }}
            >
              APPLY
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
    </StandardContainer>
  );
};

export default BookingMessage;
