import {
  Box,
  MenuItem,
  Typography,
  Select,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../styled/generic/Button";
import {
  Add,
  DeleteOutline,
  EditOutlined,
  MoreVert,
} from "@mui/icons-material";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
// import  from "../../styled/generic/Select";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import Api from "../../../helpers/Api";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import TableContainer from "../../styled/generic/TableContainer";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import CalculatorBox from "./CalculatorBox";
import { DataGrid } from "@mui/x-data-grid";
import { changePaymentTableComponentOrder } from "./api";
import HorizBox from "../../styled/generic/HorizBox";
import PopupMenuList from "../../styled/generic/PopupMenuList";

const PaymentComponents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { profileId } = useParams();

  const [openComponentsDrawer, setOpenComponentsDrawer] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [componentType, setComponentType] = useState("Fixed");
  const [componentValue, setComponentValue] = useState("");
  const [expression, setExpression] = useState("");
  const [tableId, setTableId] = useState("");
  const [draggedIndex, setDraggedIndex] = useState(0);

  const [components, setComponents] = useState([]);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [componentId, setComponentId] = useState("");
  const [hideInTable, setHideInTable] = useState(false);

  // Get query Id named tableId
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tableId = searchParams.get("tableId");
    setTableId(tableId);
  }, [location.search]);

  const saveComponent = async () => {
    if (edit) {
      setLoading(true);
      const data = await Api.patch(
        `/listing/booking/payment/component/${componentId}`,
        {
          title: componentName,
          type: componentType,
          value: componentValue ?? "",
          expression: expression ?? "",
          hideInTable: hideInTable,
        }
      );
      if (data) {
        setLoading(false);
        setOpenComponentsDrawer(false);
        setComponentName("");
        setComponentType("Fixed");
        setComponentValue("");
        setExpression("");
        setEdit(false);
        setComponents(
          components.map((item) => (item._id === data._id ? data : item))
        );
        setFilteredComponents(
          components.map((item) => (item._id === data._id ? data : item))
        );
        setComponentId("");
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Payment component updated successfully",
          },
        });
      } else {
        setLoading(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } else {
      try {
        setLoading(true);
        const { data } = await Api.post(
          "/listing/booking/payment/component/create",
          {
            title: componentName,
            type: componentType,
            value: componentValue ?? "",
            expression: expression ?? "",
            profile: profileId,
            table: tableId,
          }
        );
        if (data) {
          setOpenComponentsDrawer(false);
          setComponentName("");
          setComponentType("Fixed");
          setComponentValue("");
          setFilteredComponents((prev) => [...prev, data]);
          setComponents([...components, data]);
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "Payment component created successfully",
            },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getComponents = async () => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/component/get",
        {
          tableId: tableId,
        }
      );
      if (data) {
        const filteredData = data?.filter(
          (item) => item?.hideInTable === false
        );
        setFilteredComponents(data);
        setComponents(filteredData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tableId) {
      getComponents();
    }
  }, [tableId]);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex !== null && draggedIndex !== index) {
      const newData = [...components];
      const draggedItem = newData[draggedIndex];
      newData.splice(draggedIndex, 1);
      newData.splice(index, 0, draggedItem);
      setComponents(newData);
      const filteredData = filteredComponents.filter(
        (item) => item.hideInTable === true
      );
      setFilteredComponents([...filteredData, ...newData]);
      setDraggedIndex(index);
    }
  };

  const handleChecked = async (component, isChecked, index) => {
    if (!component) {
      return;
    }
    const data = await Api.patch(
      `/listing/booking/payment/component/${component._id}`,
      {
        milestoneCalculation: isChecked,
      }
    );
    if (data) {
      setComponents(
        components.map((item, i) =>
          i === index ? { ...item, milestoneCalculation: isChecked } : item
        )
      );
      setFilteredComponents(
        filteredComponents.map((item, i) =>
          i === index ? { ...item, milestoneCalculation: isChecked } : item
        )
      );
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const handleAggrementCheck = async (component, isChecked, index) => {
    if (!component) {
      return;
    }
    const data = await Api.patch(
      `/listing/booking/payment/component/${component._id}`,
      {
        aggrementBased: isChecked,
      }
    );
    if (data) {
      setComponents(
        components.map((item, i) =>
          i === index ? { ...item, aggrementBased: isChecked } : item
        )
      );
      setFilteredComponents(
        filteredComponents.map((item, i) =>
          i === index ? { ...item, aggrementBased: isChecked } : item
        )
      );
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const move = async (index, tableId, componentId) => {
    let { data } = await changePaymentTableComponentOrder({
      componentId: componentId,
      newOrder: index,
      tableId: tableId,
    });
  };
  const handleDragEnd = () => {
    move(draggedIndex, components[0]?.table, components[draggedIndex]?._id);
    console.log("Moving: ", components[draggedIndex]);
    setDraggedIndex(null);
  };

  function convertExpression(expression) {
    function getTitleById(id) {
      const obj = components.find((item) => item._id === id);
      return obj ? obj.title : null;
    }
    const idRegex = /[0-9a-f]{24}/g;
    const convertedExpression = expression.replace(idRegex, (match) => {
      const title = getTitleById(match);
      return title !== null ? title : match;
    });

    return convertedExpression;
  }

  return (
    <Box>
      <BoxSpaceBetween sx={{ my: "1.5rem" }}>
        <Typography variant="h6">Components</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setOpenComponentsDrawer(true);
          }}
        >
          New Component
        </Button>
      </BoxSpaceBetween>

      <TableContainer
        columns={[
          "Component Title",
          "Component Type",
          "Component Value",
          "Milestone Calculation",
          "Aggrement based",
        ]}
        data={components}
        loading={loading}
      >
        {components.map((component, index) => {
          const convertedExpression = convertExpression(component?.expression);
          return (
            <tr
              key={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={() => handleDragOver(index)}
              onDragEnd={handleDragEnd}
            >
              <td>{component?.title || "Untitled"}</td>
              <td>{component?.type || "-"}</td>
              <td>{component?.value || convertedExpression || ""}</td>
              <td align="center">
                <HorizBox>
                  <Tooltip
                    id={`tooltip-${index * 4}`}
                    title="Set checked if you want this in slab calculation."
                    enterDelay={1500}
                  >
                    <Checkbox
                      defaultChecked={component?.milestoneCalculation}
                      onChange={(e) => {
                        handleChecked(component, e.target.checked, index);
                      }}
                    />
                  </Tooltip>
                </HorizBox>
              </td>
              <td align="center">
                <HorizBox>
                  <Tooltip
                    id={`tooltip-${index}`}
                    title="Set checked if you want add this in aggrement."
                    enterDelay={1500}
                  >
                    <Checkbox
                      defaultChecked={component?.aggrementBased}
                      onChange={(e) => {
                        handleAggrementCheck(
                          component,
                          e.target.checked,
                          index
                        );
                      }}
                    />
                  </Tooltip>
                </HorizBox>
              </td>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <HorizBox>
                  <IconButton
                    onClick={() => {
                      setOpenComponentsDrawer(true);
                      setComponentName(component?.title);
                      setComponentType(component?.type);
                      setExpression(component?.expression);
                      setComponentValue(component?.value);
                      setEdit(true);
                      setComponentId(component?._id);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton onClick={() => alert("not implemented")}>
                    <DeleteOutline />
                  </IconButton>
                </HorizBox>
              </td>
            </tr>
          );
        })}
      </TableContainer>

      <DrawerContainer
        title={
          componentName ? "Create Payment Component" : "Edit Payment Component"
        }
        open={openComponentsDrawer}
        setOpen={setOpenComponentsDrawer}
        width="10%"
      >
        <FormBox label="Component Name">
          <TextField
            value={componentName}
            onChange={(e) => setComponentName(e.target.value)}
            fullWidth={true}
            placeholder="Enter"
          />
        </FormBox>
        <FormBox label="Component Type">
          <Select
            fullWidth={true}
            value={componentType}
            onChange={(e) => setComponentType(e.target.value)}
          >
            <MenuItem value="Fixed">Fixed</MenuItem>
            <MenuItem value="Expression">Expression</MenuItem>
            <MenuItem value="Variable">Variable</MenuItem>
          </Select>
        </FormBox>
        {componentType === "Fixed" && (
          <FormBox label="Component Value">
            <TextField
              fullWidth={true}
              value={componentValue}
              onChange={(e) => {
                setComponentValue(e.target.value);
              }}
              placeholder="Enter"
            />
          </FormBox>
        )}

        {componentType === "Expression" && (
          <FormBox label="Component Expression">
            <CalculatorBox
              expression={expression}
              setExpression={setExpression}
              components={
                componentId && filteredComponents
                  ? filteredComponents.slice(
                      0,
                      filteredComponents.findIndex(
                        (obj) => obj?._id === componentId
                      ) !== -1
                        ? filteredComponents.findIndex(
                            (obj) => obj?._id === componentId
                          )
                        : 0
                    )
                  : filteredComponents
                  ? filteredComponents
                  : []
              }
            />
          </FormBox>
        )}

        <FormControlLabel
          control={
            <Checkbox
              value={hideInTable}
              onChange={(e, checked) => {
                setHideInTable(checked);
              }}
            />
          }
          label="Hide in table"
        />

        <DuoButtonGroup
          primaryButtonText="Save"
          primaryButtonListener={() => saveComponent()}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setOpenComponentsDrawer(false)}
          loadingPrimary={loading}
        />
      </DrawerContainer>
    </Box>
  );
};

export default PaymentComponents;
