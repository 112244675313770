import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import TableContainer from "../../styled/generic/TableContainer";
import dayjs from "dayjs";
import Button from "../../styled/generic/Button";
import { Add } from "@material-ui/icons";

const DemandDeedsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { bookingId } = useParams();
  const [demandDeeds, setDemandDeeds] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const createDemandDeed = async () => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/demanddeed/create",
        {
          booking: bookingId,
          profile: user?.profile,
        }
      );
      if (data) {
        history.push(`/booking/demanddeed/${data?._id}`);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getDemandDeeds = async () => {
    const { data } = await Api.post("/listing/booking/payment/demanddeed/get", {
      bookingId: bookingId,
    });
    if (data) {
      setDemandDeeds(data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
    setPageLoading(false);
  };

  useEffect(() => {
    getDemandDeeds();
  }, []);

  return (
    <Box
      sx={{
        padding: "10px",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Demand Deeds</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            createDemandDeed();
          }}
        >
          Create Demand Deed
        </Button>
      </Box>

      {pageLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : demandDeeds.length > 0 ? (
        <TableContainer
          columns={["Demand Deed Title", "Created At"]}
          data={demandDeeds}
          loading={pageLoading}
        >
          {demandDeeds?.map((item) => (
            <tr
              onClick={() => {
                history.push(`/booking/demanddeed/${item?._id}`);
              }}
            >
              <td>{item?.title || "Untitled"}</td>
              <td>{dayjs(item?.createdAt).format("DD/MM/YYYY")}</td>
            </tr>
          ))}
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20vh",
          }}
        >
          <Typography color={"GrayText"}>Demand deeds not found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default DemandDeedsList;
