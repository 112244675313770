import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  MonetizationOn,
  CardGiftcard,
  Star,
  Share,
  AssignmentReturn,
  LocalOffer,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";

const getOfferIcon = (offerType) => {
  switch (offerType) {
    case "Discount":
      return <MonetizationOn />;
    case "Freebie":
      return <CardGiftcard />;
    case "Upgrade":
      return <Star />;
    case "Referral Discounts":
      return <Share />;
    case "Money-Back Guarantee":
      return <AssignmentReturn />;
    case "Other":
      return <LocalOffer />;
    default:
      return <LocalOffer />;
  }
};

const fetchOffers = async (profileId, filters, page, limit) => {
  const queryParams = new URLSearchParams({
    page,
    limit,
    ...filters,
  });
  const data = await Api.post(`/booking/offers?${queryParams.toString()}`, {
    project: profileId,
  });
  return data;
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const BookingOffers = () => {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [filters, setFilters] = useState({
    offerType: "",
    isActive: "",
    minValue: "",
    maxValue: "",
    startDate: "",
    endDate: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
  });

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
  };

  const getOffers = async () => {
    try {
      const data = await fetchOffers(
        profileId,
        filters,
        pagination.page,
        pagination.limit
      );
      setOffers(data.offers);
      setPagination({
        ...pagination,
        totalPages: data.totalPages,
      });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch offers",
        },
      });
    }
  };

  useEffect(() => {
    if (profileId) {
      getOffers().finally(() => {
        setPageLoading(false);
      });
    }
  }, [profileId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getOffers();
    }, 500);
    return () => clearTimeout(timer);
  }, [filters, pagination.page, pagination.limit]);

  if (pageLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 200px)",
        }}
      >
        <CircularProgress />
        <Typography variant="body1">Loading Offers...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Offer Type</InputLabel>
              <Select
                name="offerType"
                value={filters.offerType}
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Discount">Discount</MenuItem>
                <MenuItem value="Freebie">Freebie</MenuItem>
                <MenuItem value="Upgrade">Upgrade</MenuItem>
                <MenuItem value="Referral Discounts">
                  Referral Discounts
                </MenuItem>
                <MenuItem value="Money-Back Guarantee">
                  Money-Back Guarantee
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Active Status</InputLabel>
              <Select
                name="isActive"
                defaultValue="true"
                value={filters.isActive}
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "none",
            }}
          >
            <TextField
              fullWidth
              type="number"
              name="minValue"
              label="Min Value"
              value={filters.minValue}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "none",
            }}
          >
            <TextField
              fullWidth
              type="number"
              name="maxValue"
              label="Max Value"
              value={filters.maxValue}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              type="date"
              name="startDate"
              label="Start Date"
              InputLabelProps={{ shrink: true }}
              value={filters.startDate}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              type="date"
              name="endDate"
              label="End Date"
              InputLabelProps={{ shrink: true }}
              value={filters.endDate}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: "1rem" }} />
      <Grid
        container
        spacing={2}
        minHeight={{
          xs: "calc(100vh - 300px)",
          sm: "calc(100vh - 350px)",
          md: "calc(100vh - 450px)",
        }}
      >
        {offers.length > 0 ? (
          offers.map((offer) => (
            <Grid item xs={12} sm={6} md={3} key={offer?._id}>
              <OfferCard offer={offer} />
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "30vh",
            }}
          >
            <Typography>No Offers Found</Typography>
          </Box>
        )}
      </Grid>
      <Divider sx={{ my: "1rem" }} />
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={pagination.totalPages}
          page={pagination.page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default BookingOffers;

const OfferCard = ({ offer }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" gutterBottom color={"Highlight"}>
          {getOfferIcon(offer?.offerType)} {offer?.offerType} Offer
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {offer?.description}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Discount:</strong> {offer?.value}%
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Expires:</strong> {formatDate(offer?.expiryDate)}
        </Typography>
        <Typography
          variant="body1"
          display="block"
          gutterBottom
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          <strong>Terms:</strong> {offer?.terms}
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "5px",
          }}
        >
          <Typography variant="body1" display="block" gutterBottom>
            <strong>Actions:</strong>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {offer?.actions.slice(0, 3).map((action) => (
              <Chip variant="outlined" key={action} label={action} />
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
