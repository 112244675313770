import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const PageLoader = ({ boxHeight = "40vh", boxSx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: boxHeight,
        width: "100%",
        ...boxSx,
      }}
    >
      <CircularProgress />
      <Box mt={1}>
        <Typography variant="h5">Loading...</Typography>
      </Box>
    </Box>
  );
};

export default PageLoader;
