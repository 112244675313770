import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Avatar,
  Typography,
  Tooltip, Chip
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BusinessIcon from "@mui/icons-material/Business";
import { AvatarGroup } from "@material-ui/lab";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell";
import HorizBox from "../styled/generic/HorizBox";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import {
  CallOutlined,
  KeyboardArrowDown,
  MoreVertOutlined,
  RadioButtonChecked,
  SmsOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import PopupMenuList from "../styled/generic/PopupMenuList";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import { DeleteOutline } from "@material-ui/icons";
import ConfirmationDialog from "../global/ConfirmationDialog";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

const CRMCard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const issue = props?.issue;
  // console.log("Issues ::",issue)
  // console.log("issue issue?.user?.displayPicture?.thumbUrl ::", issue?.user?.displayPicture?.url)
  console.log("Issues assigned ::", issue?.assigned)
  const statusOptions = props?.statusOptions;
  const onIssueUpdate = props?.onIssueUpdate;
  const onIssueDelete = props?.onIssueDelete;
  const hideStatus = props?.hideStatus || false;

  const updateIssue = async (statusId) => {
    try {
      const res = await Api.post("issue/updateFromKanban", {
        issueId: issue?._id,
        status: statusId,
      });
      if (res?.success) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Status updated successfully",
          },
        });
        onIssueUpdate({
          ...issue,
          status: statusOptions.find((status) => status._id === statusId),
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: res?.message || "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const deleteIssue = async () => {
    try {
      const res = await Api.post("issue/delete", {
        issueId: issue?._id,
      });
      if (res) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Issue deleted successfully",
          },
        });
        onIssueDelete(issue?._id);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: res?.message || "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  let priority_color = "grey";
  let priority_text = "low";
  switch (issue?.priority) {
    case 1:
      priority_color = "grey";
      priority_text = "Very Low";
      break;
    case 2:
      priority_color = "green";
      priority_text = "Low";
      break;
    case 3:
      priority_color = "yellow";
      priority_text = "Medium";
      break;
    case 4:
      priority_color = "orange";
      priority_text = "High";
      break;
    case 5:
      priority_color = "red";
      priority_text = "Urgent";
      break;
  }

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Box
        sx={{
          p: 2,
          boxShadow: "2px 4px 6px rgba(0,0,0,0.10)",
          borderRadius: "8px",
          overflow: "hidden",
          "&:hover": {
            boxShadow:
              "0px 2px 8px 0px rgba(52, 101, 255, 0.12), 0px -2px 8px 0px rgba(52, 101, 255, 0.12)",
          },
          cursor: "pointer",
        }}
      >
        <BoxSpaceBetween spacing={0} sx={{ mt: 0, flexDirection: 'column', alignItems: 'flex-start' }}
          onClick={() => {
            var path = "/issue/edit/" + issue?._id;
            history.push(path);
          }}>
          {/* First Row: User Name and Three Dots */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0, color: 'black', fontWeight: 'bold' }}>
            <SingleUserWithCaptionTableCell
              label={issue?.user?.displayName || "N/A"}
              url={issue?.user?.displayPicture?.thumbUrl || issue?.user?.displayPicture?.url}
              labelStyleProps={{ fontSize: 20 }}
              avatarTextGap={1}
              avatarSx={{ width: 24, height: 24 }}
            />
            {!hideStatus && (
              <Box
                onClick={(e) => e.stopPropagation()}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <PopupMenuList
                  appearContent={
                    <IconButton size="small">
                      <MoreVertOutlined fontSize="small" />
                    </IconButton>
                  }
                  actions={statusOptions.map((status) => ({
                    title: status?.text,
                    action: () => updateIssue(status?._id),
                  }))}
                  secondaryActions={[
                    {
                      title: "Delete",
                      action: () => setShowConfirmationDialog(true),
                    },
                  ]}
                />
              </Box>
            )}
          </Box>

          {/* Second and Third Rows: Date and Color Box in One Row */}
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box sx={{ fontSize: 10, color: 'gray' }}>
              {moment(issue?.createdAt).format("DD MMM YYYY")}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                backgroundColor: issue?.status?.color || "#E48900",
                color: "white",
                fontSize: "10px",
                padding: "2px 8px",
              }}
            >
              {issue?.status?.text}
            </Box>
          </Box>

          {/* Fourth Row: Title */}
          <Box sx={{ textAlign: 'left', width: '100%', mb: 0 }}>
            <Typography variant="h6" component="span" sx={{ fontSize: 18 }}>
              {issue?.template?.isCRM
                ? issue?.finrel?.profile?.parent?.displayName
                : issue?.title || "Untitled"}
            </Typography>
          </Box>
        </BoxSpaceBetween>

        <BoxSpaceBetween spacing={0} onClick={() => {
          var path = "/issue/edit/" + issue?._id;
          history.push(path);
        }} >
          {String(issue?.description).trim().length > 0 ? (
            <Box>{ReactHtmlParser(issue?.description)}</Box>
          ) : (
            <Box sx={{ fontStyle: "italic", color: "grey" }}></Box>
          )}
        </BoxSpaceBetween>

        <BoxSpaceBetween spacing={0} onClick={() => {
          var path = "/issue/edit/" + issue?._id;
          history.push(path);

        }} sx={{ mt: 1 }} >
          <Box sx={{ fontSize: 15, display: "flex", alignItems: "center", mb: 0 }}>
            <WorkOutlineIcon sx={{ mr: 1 }} />
            <Box sx={{ fontSize: 15 }}>
              {issue?.project?.displayName || "N/A"}
            </Box>
          </Box>
        </BoxSpaceBetween>

        <BoxSpaceBetween spacing={0} onClick={() => {
          var path = "/issue/edit/" + issue?._id;
          history.push(path);
        }}>
          <Box sx={{ fontSize: 15, display: "flex", alignItems: "center", mb: 0 }}>
            <BusinessIcon sx={{ mr: 1 }} />
            <Box sx={{ fontSize: 15 }}>
              {issue?.organization?.displayName || "N/A"}
            </Box>
          </Box>
        </BoxSpaceBetween>

        {issue?.template?.isCRM && (
          <Box>
            <BoxSpaceBetween spacing={0}>
              <Box sx={{ fontSize: 16 }}>Listings:</Box>
              <Box sx={{ fontSize: 16, color: "#2D76E0" }}>
                {issue?.finrel?.listing?.title || "Untitled"}
              </Box>
            </BoxSpaceBetween>
            <BoxSpaceBetween spacing={0} >
              <Box sx={{ fontSize: 16 }}>Requirements :</Box>
              <Box sx={{ fontSize: 16 }}>
                {issue?.finrel?.requirement?.title || "Untitled"}
              </Box>
            </BoxSpaceBetween>
          </Box>
        )}


        <Box sx={{ mb: 2 }}>

          <HorizBox>
            {issue?.labels?.map((item) => (
              <Box
                key={item.id}
                sx={{
                  backgroundColor: item?.colorCode + "33",
                  color: item?.colorCode,
                  padding: "4px 8px",
                  borderRadius: "10px",
                  fontSize: '0.875rem',
                  marginRight: '4px',
                  marginBottom: '4px',
                }}
              >
                {item?.name}
              </Box>
            ))}
          </HorizBox>
        </Box>


        <BoxSpaceBetween
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}
          onClick={() => {
            var path = "/issue/edit/" + issue?._id;
            history.push(path);
          }}
        >
          <Box sx={{ fontSize: 16 }}>Priority:</Box>
          <Chip color="primary" variant="outlined" size="small" label={priority_text} />
        </BoxSpaceBetween>



        <BoxSpaceBetween
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          onClick={() => {
            var path = "/issue/edit/" + issue?._id;
            history.push(path);
          }}
        >
          <Box sx={{ fontSize: 16 }}>Assigned:</Box>
          <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <AvatarGroup max={4} sx={{ display: 'flex', alignItems: 'center' }}>
              {issue?.assigned?.length > 0 ? (
                issue.assigned.map((userData, i) => (
                  <Tooltip
                    key={i}
                    title={userData?.parent?.displayName || 'No Name'}
                    placement="top"
                    arrow
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        border: 'none',
                        boxShadow: 'none',
                        backgroundColor: 'inherit',
                        padding: 0,  // Remove padding to fit the image
                      },
                      '& .MuiTooltip-arrow': {
                        color: 'inherit',
                      },
                    }}
                  >
                    <span>
                      <Avatar
                        src={
                          userData?.parent?.displayPicture?.thumbUrl || userData?.parent?.displayPicture?.url
                        }
                        sx={{ width: 25, height: 25, border: 'none' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://via.placeholder.com/25';
                        }}
                      />
                    </span>
                  </Tooltip>
                ))
              ) : (
                <Box sx={{ fontSize: 16 }}></Box>
              )}
            </AvatarGroup>
          </Box>


        </BoxSpaceBetween>








        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></Box>

        <ConfirmationDialog
          title="Delete Ticket"
          message="Are you sure you want to delete this ticket? This action cannot be undone."
          successButtonText="Yes"
          cancelButtonText="Cancel"
          successListener={() => {
            deleteIssue();
            setShowConfirmationDialog(false);
          }}
          cancelListener={() => {
            setShowConfirmationDialog(false);
          }}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
        />
      </Box>
    </Grid>

  );
};

export default CRMCard;
