import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../styled/generic/TableContainer";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import PaymentComponents from "./PaymentComponents";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import Button from "../../styled/generic/Button";

const PaymentTable = () => {
  const history = useHistory();
  const location = useLocation();
  const { profileId } = useParams();
  const [tableId, setTableId] = useState("");
  const [table, setTable] = useState([]);
  const [rows, setRows] = useState([]);
  const capitalizeFullName = (input) =>
    input?.charAt(0).toUpperCase() + input?.slice(1).toLowerCase();

  // Get query Id named tableId
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tableId = searchParams.get("tableId");
    setTableId(tableId);
  }, [location.search]);

  // Fetch table data
  const getTable = async () => {
    try {
      const { data } = await Api.post(
        `/listing/booking/payment/table/get-by-id`,
        {
          tableId: tableId,
        }
      );
      if (data) {
        setTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tableId) {
      getTable();
    }
  }, [tableId]);

  return (
    <Box>
      <BoxSpaceBetween sx={{ mb: "1.5rem" }}>
        <Typography variant="h5">
          {capitalizeFullName(table?.title) || "Undefined"}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            history.push(
              `/booking/settings/${profileId}?view=demanddeedsettings&tableId=${table?._id}`
            );
          }}
        >
          Demand Deed Settings
        </Button>
      </BoxSpaceBetween>
      <PaymentComponents />
    </Box>
  );
};

export default PaymentTable;
