import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Api from "../../../helpers/Api";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Paper,
  Grid,
  Divider,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Edit,
  Visibility,
  DateRange,
  Receipt,
  ReceiptLong,
  Delete,
} from "@mui/icons-material";
import StatusChip from "../../styled/generic/StatusChip";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import { SubjectInput } from "./DemandDeedSettingsCreate";
import SubjectIcon from "@mui/icons-material/Subject";
import TableContainer from "../../styled/generic/TableContainer";
import FormBox from "../../styled/generic/FormBox";
import DemandDeedSettingTable from "./DemandDeedSettingTable";
import BankAccountPicker from "../../styled/generic/BankAccountPickerDropdown";
import {
  Payment as PaymentIcon,
  Schedule as ScheduleIcon,
  Receipt as TransactionIcon,
  AccountBalance as ProjectIcon,
  Apartment as BlockIcon,
} from "@mui/icons-material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const InfoItem = ({ icon, label, value }) => (
  <>
    <Grid item xs={12} sm={6} md={3}>
      <Box display="flex" alignItems="center">
        {icon}
        <Typography variant="subtitle2" color="text.secondary" sx={{ ml: 1 }}>
          {label}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="body1">{value}</Typography>
    </Grid>
  </>
);

const DemandDeedSettingView = ({ libraryId }) => {
  const location = useLocation();
  const history = useHistory();
  const [settingId, setSettingId] = useState("");
  const [demandDeedSetting, setDemandDeedSetting] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [schedules, setSchedules] = useState([]); // this schedules state holds all payment schedules
  const [tables, setTables] = useState([]); // this tables state holds all payment tables
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [components, setComponents] = useState([]); // this components state holds all payment components
  const [transactions, setTransactions] = useState([]);
  const [usedComponentIds, setUsedComponentIds] = useState([]);

  console.log("tables", tables);
  console.log("schedules", schedules);

  useEffect(() => {
    if (!Array.isArray(transactions) || transactions.length === 0) {
      console.log("No transactions available");
      setUsedComponentIds([]);
      return;
    }

    const uniqueComponentIds = new Set();
    transactions.forEach((transaction) => {
      if (Array.isArray(transaction?.billItems)) {
        transaction.billItems.forEach((billItem) => {
          uniqueComponentIds.add(billItem.component);
        });
      }
    });
    const usedComponentIds = Array.from(uniqueComponentIds);
    setUsedComponentIds(usedComponentIds);
  }, [transactions]);

  console.log(components);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("settingid");
    console.log("id", id);
    setSettingId(id);
  }, [location.search]);

  const getComponents = async () => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/component/get",
        {
          tableId: selectedTable,
        }
      );
      if (data) {
        setComponents(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedTable && editMode) {
      getComponents();
    }
  }, [selectedTable, editMode]);

  const fetchDemandDeedSetting = async () => {
    try {
      const data = await Api.post(
        "/listing/booking/payment/demanddeedsetting/get-by-id",
        {
          settingId: settingId,
        }
      );
      if (data) {
        setDemandDeedSetting(data);
        console.log(data);
        setSelectedSchedule(data.paymentSchedule);
        setSelectedTable(data.paymentTable);
        setTransactions(data.transactions);
        setSubject(data.subject);
        setTitle(data.title);
        setStatus(data.status);
      } else {
        console.log("Error: Unable to fetch demand deed setting");
      }
    } catch (error) {
      console.error("Error fetching demand deed setting:", error);
    }
  };

  const updateDemandDeedSetting = async () => {
    try {
      const payload = {
        paymentTable: selectedTable._id,
        paymentSchedule: selectedSchedule._id,
        title,
        subject,
        project: selectedSchedule?.project,
        projectBlock: selectedSchedule?.projectBlock,
        library: libraryId,
        transactions,
      };

      Object.keys(payload).forEach(
        (key) => payload[key] === undefined && delete payload[key]
      );

      const response = await Api.post(
        `/listing/booking/payment/demanddeedsetting/update/${settingId}`,
        payload
      );

      if (response) {
        console.log("Demand deed setting updated successfully:", response.data);
        fetchDemandDeedSetting();
        setEditMode(false);
      } else {
        console.log("Error: Unable to update demand deed setting");
        fetchDemandDeedSetting();
        setEditMode(false);
      }
    } catch (error) {
      console.error("Error updating demand deed setting:", error);
    }
  };

  const fetchTableAndSchedule = async () => {
    const [paymentTablesData, schedulesData] = await Promise.all([
      Api.post("/listing/booking/payment/table/get", {
        libraryId: libraryId,
      }),
      Api.post("/listing/booking/schedule/get", {
        libraryId,
      }),
    ]);
    setSchedules(schedulesData.data);
    setTables(paymentTablesData.data);
  };

  useEffect(() => {
    if (libraryId) {
      fetchTableAndSchedule();
    }
  }, []);

  useEffect(() => {
    if (settingId) {
      fetchDemandDeedSetting();
    }
  }, [settingId]);

  console.log("demandDeedSetting", demandDeedSetting);

  const handleDelete = (index) => {
    const filteredTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(filteredTransactions);
  };

  const updateTransactionBillItems = (updatedBillItems, transactionIndex) => {
    setTransactions((prevTransactions) => {
      const updatedTransactions = [...prevTransactions];
      updatedTransactions[transactionIndex] = {
        ...updatedTransactions[transactionIndex],
        billItems: updatedBillItems,
      };
      return updatedTransactions;
    });
  };

  if (!demandDeedSetting) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <StyledPaper elevation={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          mb={2}
          gap={2}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              width: "100%",
            }}
          >
            {editMode ? (
              <FormBox label={"Enter title"}>
                <TextField
                  size="small"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                />
              </FormBox>
            ) : (
              <Typography variant="h5" gutterBottom>
                <Receipt sx={{ verticalAlign: "middle", mr: 1 }} />
                {demandDeedSetting.title}
              </Typography>
            )}
            {editMode ? (
              <SubjectInput
                subject={subject}
                setSubject={setSubject}
                title="Demand Deed Setting Subject"
                demandDeed={true}
              />
            ) : (
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SubjectIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: demandDeedSetting.subject,
                  }}
                />
              </Typography>
            )}
          </Box>
          <Box>
            <Tooltip
              title={editMode ? "Switch to view mode" : "Switch to edit mode"}
            >
              <IconButton
                color={editMode ? "primary" : "default"}
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? <Visibility /> : <Edit />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid
          container
          spacing={2}
          sx={{
            mb: "5px",
          }}
        >
          <InfoItem
            icon={<ProjectIcon color="action" />}
            label={"Project"}
            value={demandDeedSetting?.project?.displayName}
          />
          <InfoItem
            icon={<BlockIcon color="action" />}
            label={"Project Block"}
            value={demandDeedSetting?.projectBlock?.name}
          />
        </Grid>

        <Grid container spacing={2}>
          <InfoItem
            icon={<DateRange color="action" />}
            label="Created At"
            value={new Date(demandDeedSetting.createdAt).toLocaleDateString()}
          />
          <InfoItem
            icon={<DateRange color="action" />}
            label="Updated At"
            value={new Date(demandDeedSetting.updatedAt).toLocaleDateString()}
          />
        </Grid>

        <Divider
          sx={{
            my: 2,
          }}
        />

        <Typography variant="h6" gutterBottom mt={3}>
          Payment Table
        </Typography>

        {editMode ? (
          <FormBox
            sx={{
              mt: 2,
            }}
          >
            <Select
              size="small"
              sx={{ minWidth: "300px" }}
              value={selectedTable ? selectedTable._id : ""}
              onChange={(e) =>
                setSelectedTable(
                  tables.find((table) => table._id === e.target.value)
                )
              }
            >
              {tables
                .filter((item) => item.title)
                .map((item) => (
                  <MenuItem
                    key={item._id}
                    value={item._id}
                    sx={{
                      backgroundColor:
                        item._id === selectedTable._id && "Background",
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ))}
            </Select>
          </FormBox>
        ) : (
          <Typography variant="body1">
            {demandDeedSetting.paymentTable?.title || "N/A"}
          </Typography>
        )}

        <Typography variant="h6" gutterBottom mt={3}>
          Payment Schedule
        </Typography>
        {editMode ? (
          <FormBox>
            {demandDeedSetting?.paymentSchedule && !selectedSchedule ? (
              <Typography>demandDeedSetting?.paymentSchedule.title</Typography>
            ) : (
              <Select
                value={selectedSchedule ? selectedSchedule._id : ""}
                onChange={(e) =>
                  setSelectedSchedule(
                    schedules.find(
                      (schedule) => schedule._id === e.target.value
                    )
                  )
                }
                size="small"
                sx={{ minWidth: "300px" }}
              >
                {schedules
                  .filter((item) => item.title)
                  .map((item) => (
                    <MenuItem
                      key={item._id}
                      value={item._id}
                      sx={{
                        backgroundColor:
                          item._id === selectedSchedule._id && "Background",
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormBox>
        ) : (
          <Typography variant="body1">
            {demandDeedSetting.paymentSchedule?.title || "N/A"}
          </Typography>
        )}
        <Divider
          sx={{
            my: 2,
          }}
        />

        {transactions.map((transaction, index) => (
          <>
            {editMode ? (
              <>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6">
                      Transaction {index + 1}
                    </Typography>
                    <Tooltip title={"Delete transaction"} enterDelay={2000}>
                      <Button
                        color="error"
                        variant={"outlined"}
                        startIcon={<Delete />}
                        onClick={() => handleDelete(index)}
                        sx={{
                          borderStyle: "dotted",
                        }}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                  >
                    <SubjectInput
                      title={"Transaction Subject"}
                      subject={transaction.subject}
                      setSubject={(subject1) => {
                        const updatedTransactions = [...transactions];
                        updatedTransactions[index] = {
                          ...updatedTransactions[index],
                          subject: subject1,
                        };
                        setTransactions(updatedTransactions);
                      }}
                    />
                    <FormBox
                      label="Select Bank"
                      sx={{
                        flex: 3,
                      }}
                    >
                      <BankAccountPicker
                        selectedBankAccount={transaction.bankAccountId}
                        setSelectedBankAccount={(id) => {
                          const updatedTransactions = [...transactions];
                          updatedTransactions[index] = {
                            ...updatedTransactions[index],
                            bankAccountId: id,
                          };
                          setTransactions(updatedTransactions);
                        }}
                      />
                    </FormBox>
                  </Box>
                  <DemandDeedSettingTable
                    components={components}
                    usedComponentIds={usedComponentIds}
                    billItems={transaction.billItems}
                    onBillItemsChange={(updatedBillItems) =>
                      updateTransactionBillItems(updatedBillItems, index)
                    }
                  />
                </Box>
              </>
            ) : (
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "1rem",
                  }}
                >
                  <ReceiptLong sx={{ verticalAlign: "middle", mr: 1 }} />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: transaction.subject,
                    }}
                  />
                </Typography>
                <Box
                  sx={{
                    mb: "1rem",
                  }}
                >
                  <TableContainer
                    columns={[
                      "CHARGE TYPE",
                      "Penalty Amount",
                      "Discount Amount",
                    ]}
                    data={["defljds"]}
                    containerHeight=""
                  >
                    {transaction?.billItems?.map((billItems) => (
                      <tr key={index}>
                        <td>{billItems?.component?.title || "undefined"}</td>
                        <td>{billItems?.penaltyAmount || "NaN"}</td>
                        <td>{billItems?.rebateAmount || "NaN"}</td>
                      </tr>
                    ))}
                  </TableContainer>
                </Box>
              </Box>
            )}

            <Divider
              sx={{
                my: 2,
              }}
            />
          </>
        ))}

        {editMode && (
          <Box mt={3}>
            <DuoButtonGroup
              primaryButtonText="Save"
              primaryButtonListener={updateDemandDeedSetting}
              hideSecondary={true}
              loadingPrimary={buttonLoading}
            />
          </Box>
        )}
      </StyledPaper>
    </Box>
  );
};

export default DemandDeedSettingView;
