import React, { useState, useEffect } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import {
  Box,
  InputAdornment,
  TextField,
  Button,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import CRMCard from "../../issue/issue.crm.card";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";

const BookingIssue = () => {
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [issues, setIssues] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [projects, setProjects] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [selectedOrganizationIds, setSelectedOrganizations] = useState([]);
  const [selectedAccountManagerIds, setSelectedAccountManagerIds] = useState(
    []
  );
  const [searchStr, setSearchStr] = useState("");
  const [dataLoading, setDataLoading] = useState(true);

  const [walletData, setWalletData] = useState([]);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const setUpProjects = () => {
      for (let i = 0; i < teamIds.length; i++) {
        let id = teamIds[i];
        let team = teamDictionary[id];
        if (team?.parent?.model === "Project") {
          if (projects.includes(team?.parent)) {
            continue;
          } else {
            setProjects((prev) => [...prev, team?.parent]);
          }
        }
      }
    };

    if (!projects.length > 0) {
      setUpProjects();
    }
  }, []);

  console.log("bookings", organizations);

  const searchUsers = async () => {
    const response = await Api.post(`/users/get/pagination`);
    if (response.data.length > 0) {
      setUsers(response.data);
    } else {
      setUsers([]);
    }
  };

  useEffect(() => {
    searchUsers();
  }, []);

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId: profileId })
        .then(async (data1) => {
          setWalletData(data1);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setPageLoading(false);
        });
    }
  }, [profileId]);

  const getBookings = async () => {
    const data = await Api.post("/listing/booking/get", {
      libraryId: walletData?.defaultLibrary,
    });
    if (data) {
      setBookings(data?.bookings);
    } else {
    }
  };

  useEffect(() => {
    if (walletData?.defaultLibrary) {
      getBookings();
    }
  }, [walletData]);

  useEffect(() => {
    const getFilterData = async () => {
      const data = await Api.post(`/profile/get/admin/project/organization`, {
        givenProfiles: [user?.profile],
      });
      setOrganizations(() => data.data.organizations || []);
      console.log("organizations", data.data.organizations || []);
    };
    if (user) {
      getFilterData();
    }
  }, [user]);

  const getIssues = async () => {
    const { data } = await Api.post(
      `issue/project/getByProjectId?q=${searchStr}`,
      {
        profileId: profileId,
        managerIds: selectedAccountManagerIds,
        organizationIds: selectedOrganizationIds,
        projectIds: selectedProjectIds,
        libraryId: walletData?.defaultLibrary,
      }
    );
    if (data) {
      setIssues(data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something wrong while fetching issues",
        },
      });
    }
  };

  useEffect(() => {
    getIssues().finally(() => {
      setDataLoading(false);
    });
  }, []);

  useEffect(() => {
    const getFilterDataTimeout = setTimeout(() => {
      getIssues();
    }, 2000);
    return () => clearTimeout(getFilterDataTimeout);
  }, [selectedAccountManagerIds, selectedOrganizationIds, selectedProjectIds]);

  useEffect(() => {
    const getFilterDataTimeout = setTimeout(() => {
      getIssues();
    }, 1000);
    return () => clearTimeout(getFilterDataTimeout);
  }, [searchStr]);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Issues"
      loading={pageLoading}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
          gap: 1,
        }}
      >
        <TextField
          placeholder="Search Issues"
          size="small"
          fullWidth
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiSearch />
              </InputAdornment>
            ),
          }}
          sx={{
            maxWidth: "350px",
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
              backgroundColor: "white",
            },
          }}
        />
        <FormControl sx={{ minWidth: "300px" }} size="small">
          <InputLabel id="project-select-label">Select projects</InputLabel>
          <Select
            labelId="project-select-label"
            placeholder="Select projects"
            label="Select projects"
            variant="outlined"
            size="small"
            multiple
            value={selectedProjectIds}
            renderValue={(selected) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  {selected.map((value) => {
                    const project = projects.find((p) => p._id === value);
                    return (
                      <Chip
                        variant="filled"
                        key={value}
                        avatar={<Avatar src={project?.displayPicture?.url} />}
                        label={project.displayName}
                      />
                    );
                  })}
                </Box>
              );
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const project = projects.find((p) => p._id === value);
                    return (
                      <Box
                        key={value}
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <Avatar src={project?.displayPicture?.url} />
                        {project?.displayName}
                      </Box>
                    );
                  })}
                </Box>
              );
            }}
            onChange={(e) => {
              setSelectedProjectIds(e.target.value);
            }}
            sx={{
              maxWidth: "350px",
            }}
          >
            {projects.map((project, index) => {
              return (
                <MenuItem
                  key={index}
                  value={project._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "primary",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Avatar src={project?.displayPicture?.url} />
                    <Typography variant="body1">
                      {project?.displayName}
                    </Typography>
                  </Box>
                  {selectedProjectIds.includes(project._id) && (
                    <CheckIcon color="primary" />
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: " 300px" }} size="small">
          <InputLabel id="organizations-select-label">
            Select organizations
          </InputLabel>
          <Select
            labelId="organizations-select-label"
            placeholder="Select organizations"
            label="Select organizations"
            variant="outlined"
            size="small"
            multiple
            value={selectedOrganizationIds}
            renderValue={(selected) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  {selected.map((value) => {
                    const organization = organizations.find(
                      (p) => p.parent._id === value
                    );
                    return (
                      <Chip
                        variant="filled"
                        key={value}
                        avatar={
                          <Avatar
                            src={organization?.parent?.displayPicture?.url}
                          />
                        }
                        label={organization?.parent?.displayName}
                      />
                    );
                  })}
                </Box>
              );
            }}
            onChange={(e) => {
              setSelectedOrganizations(e.target.value);
            }}
            sx={{
              maxWidth: "350px",
            }}
          >
            {organizations.map((organization, index) => {
              return (
                <MenuItem
                  key={index}
                  value={organization.parent._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "primary",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Avatar src={organization?.parent?.displayPicture?.url} />
                    <Typography variant="body1">
                      {organization?.parent?.displayName}
                    </Typography>
                  </Box>
                  {selectedOrganizationIds.includes(organization._id) && (
                    <CheckIcon color="primary" />
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: " 300px" }} size="small">
          <InputLabel id="account-manager-select-label">
            Select account managers
          </InputLabel>
          <Select
            labelId="account-manager-select-label"
            placeholder="Select account manager"
            label="Select account manager"
            variant="outlined"
            size="small"
            multiple
            value={selectedAccountManagerIds}
            renderValue={(selected) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  {selected.map((value) => {
                    const user = users.find((p) => p._id === value);
                    return (
                      <Chip
                        variant="filled"
                        key={value}
                        avatar={<Avatar src={user?.displayPicture?.url} />}
                        label={user?.displayName}
                      />
                    );
                  })}
                </Box>
              );
            }}
            onChange={(e) => {
              setSelectedAccountManagerIds(e.target.value);
            }}
            sx={{
              maxWidth: "350px",
            }}
          >
            {users.map((user, index) => {
              return (
                <MenuItem
                  key={index}
                  value={user._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "primary",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Avatar src={user?.displayPicture?.url} />
                    <Typography variant="body1">{user?.displayName}</Typography>
                  </Box>
                  {selectedAccountManagerIds.includes(user._id) && (
                    <CheckIcon color="primary" />
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
      <Grid
        container
        spacing={2}
        sx={{
          paddingY: "20px",
        }}
      >
        {dataLoading ? (
          <Box>
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={30} />
            </Box>
          </Box>
        ) : !issues.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">No Issues found</Typography>
          </Box>
        ) : (
          issues.map((issue, index) => (
            <CRMCard
              hideStatus={true}
              key={index}
              issue={issue}
              onIssueUpdate={(newIssue) => {
                const newIssues = issues.map((oldIssue) => {
                  if (oldIssue._id === newIssue._id) {
                    return newIssue;
                  }
                  return oldIssue;
                });
                setIssues(newIssues);
              }}
              onIssueDelete={(deletedIssueId) => {
                const newIssues = issues.filter(
                  (oldIssue) => String(oldIssue._id) !== String(deletedIssueId)
                );
                setIssues(newIssues);
              }}
            />
          ))
        )}
        <Box></Box>
      </Grid>
    </StandardContainer>
  );
};

export default BookingIssue;
