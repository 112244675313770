import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Api from "../../../helpers/Api";
import { useSelector } from "react-redux";
import Select from "./Select";
import HorizBox from "./HorizBox";

const ProjectBlockAndUnitPickerDropdown = ({
  projectId,
  listingId,
  hideLabel,
  size,
  selectedProjectBlock,
  setSelectedProjectBlock,
  selectedProjectUnit,
  setSelectedProjectUnit,
  fullWidth = false,
}) => {
  const [projectBlocks, setProjectBlocks] = useState([]);
  const [units, setUnits] = useState([]);

  const getProjectBlocks = async () => {
    console.log("projectId", projectId);
    if (!projectId) {
      if (listingId) {
        try {
          const { data } = await Api.post("/public/project/block/get", {
            listingId: listingId,
          });
          if (data && data?.length > 0) {
            setProjectBlocks(data);
            setSelectedProjectBlock(data[0]);
          } else {
            setSelectedProjectBlock("");
            setProjectBlocks([]);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        return;
      }
    } else {
      try {
        const { data } = await Api.post("/public/project/block/get", {
          projectId: projectId,
        });

        if (data && data?.length > 0) {
          setProjectBlocks(data);
          setSelectedProjectBlock(data[0]);
        } else {
          setSelectedProjectBlock("");
          setProjectBlocks([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUnits = async (blockId) => {
    try {
      const { data } = await Api.post("/project/unit/getUnitsByBlock", {
        blockId: blockId,
      });

      if (data && data?.length > 0) {
        setSelectedProjectUnit(data[0]);
        setUnits(data);
      } else {
        setSelectedProjectUnit("");
        setUnits([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjectBlocks();
  }, [projectId, listingId]);

  useEffect(() => {
    if (selectedProjectBlock) {
      getUnits(selectedProjectBlock._id);
    }
  }, [selectedProjectBlock]);

  return (
    <HorizBox>
      <FormControl fullWidth={fullWidth} size={size}>
        {!hideLabel && (
          <InputLabel
            id="demo-simple-select-helper-label"
            shrink={selectedProjectBlock !== null}
          >
            {selectedProjectBlock ? "Project Block" : "Select Project Block"}
          </InputLabel>
        )}

        <Select
          value={selectedProjectBlock}
          onChange={(evt) => setSelectedProjectBlock(evt.target.value)}
          label={
            selectedProjectBlock ? "Project Block" : "Select Project Block"
          }
          notched={hideLabel ? false : selectedProjectBlock !== null}
          displayEmpty
        >
          <MenuItem value="">
            {selectedProjectBlock?.displayName || "Select"}
          </MenuItem>
          {projectBlocks.length > 0 &&
            projectBlocks.map((obj) => (
              <MenuItem key={obj._id} value={obj}>
                {obj?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {selectedProjectBlock && (
        <FormControl fullWidth={fullWidth} size={size}>
          {!hideLabel && (
            <InputLabel
              id="demo-simple-select-helper-label"
              shrink={selectedProjectUnit !== null}
            >
              {selectedProjectUnit ? "Project Unit" : "Select Project Unit"}
            </InputLabel>
          )}
          <Select
            value={selectedProjectUnit}
            onChange={(evt) => setSelectedProjectUnit(evt.target.value)}
            label={selectedProjectUnit ? "Project Unit" : "Select Project Unit"}
            notched={hideLabel ? false : selectedProjectUnit !== null}
            displayEmpty
          >
            <MenuItem value="">
              {selectedProjectUnit?.displayName || "Select"}
            </MenuItem>
            {units?.length > 0 &&
              units?.map((obj) => (
                <MenuItem key={obj._id} value={obj}>
                  {obj?.name || "Untitled"}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </HorizBox>
  );
};

export default ProjectBlockAndUnitPickerDropdown;
