import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import TableContainer from "../../styled/generic/TableContainer";
import { BiPlus } from "react-icons/bi";
import { Delete } from "@mui/icons-material";
import { Typography } from "@mui/material";

const CreateTranscationTable = ({
  components,
  setTransactions = () => {},
  transactions,
  bookingId = "",
  status = "Draft",
  usedComponentIds = [],
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const properties = ["totalAmountReceivable", "receivedAgainstCurrentDue"];

  useEffect(() => {
    async function updateData() {
      if (components.length === 0) return;

      setTableLoading(true);

      const newData = components.map((component) => ({
        table: component?.table,
        title: component?.title || "Undefined",
        ratePerSqFt: 0,
        componentRef: component._id,
        intApp: false,
        chargeAmount: 0,
        rebateAmount: 0,
        penaltyDue: 0,
        amountReceivable: 0,
        currentDue: 0,
        dueReceivable: 0,
        amountReceived: 0,
        netReceivable: 0,
        bookingId: bookingId,
        hideInTable: component?.hideInTable || false,
        type: component?.type,
        milestoneCalculation: component?.milestoneCalculation || false,
      }));

      try {
        const fetchedData = await Api.post(
          "listing/booking/payment/table/detail/get-or-create",
          {
            tableId: components[0]?.table,
            componentIds: components.map((item) => item?._id),
            bookingId: bookingId,
            titles: components.map((item) => item?.title),
          }
        );
        const updatedData = newData.map((component) => {
          const fetchedComponent = fetchedData.find(
            (item) => item?.componentRef === component.componentRef
          );
          if (fetchedComponent) {
            return {
              ...component,
              ...fetchedComponent,
              dueReceivable:
                fetchedComponent.dueReceivable ||
                fetchedComponent.currentDue + fetchedComponent.penaltyDue,
            };
          }
          return component;
        });

        setData(updatedData);

        if (!transactions) {
          setTransactions([
            {
              component: "",
              chargeType: "",
              details: "",
              chargeAmount: 0,
              rebateAmount: 0,
              totalAmountReceivable: 0,
              currentDue: 0,
              penaltyDue: 0,
              receivedAgainstCurrentDue: 0,
              amountReceived: 0,
            },
          ]);
        }
      } catch (error) {
        console.error("Error updating component data:", error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error updating component data. Please try again.",
          },
        });
      } finally {
        setTableLoading(false);
      }
    }

    if (bookingId) {
      updateData();
    }
  }, [components, bookingId, dispatch, transactions]);

  const handleInputChange = (index, field, value) => {
    if (field === "chargeType") {
      const newData = data.filter((item) => item?.hideInTable === false);
      setTransactions((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          component: newData[index]?.componentRef,
          details: newData[index]?._id,
          chargeAmount: newData[index]?.chargeAmount,
          rebateAmount: 0,
          totalAmountReceivable: newData[index]?.currentDue,
          currentDue: newData[index]?.currentDue,
          penaltyDue: 0,
          receivedAgainstCurrentDue: newData[index]?.currentDue,
          amountReceived: newData[index]?.amountReceived,
          chargeType: value,
        };
        return updatedData;
      });
    } else {
      setTransactions((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [field]: Number.isInteger(Number(value)) ? Number(value) : value,
        };
        updatedData[index].receivedAgainstCurrentDue =
          updatedData[index].currentDue;

        updatedData[index].totalAmountReceivable =
          Number(updatedData[index].currentDue) +
          Number(updatedData[index].penaltyDue) -
          Number(updatedData[index].rebateAmount);

        console.log(updatedData[index].totalAmountReceivable);

        return updatedData;
      });
    }
  };

  const calculateTotal = (property) => {
    const totalAmount = transactions
      .reduce((total, component) => {
        return total + (parseFloat(component[property]) || 0);
      }, 0)
      .toFixed(2);
    return totalAmount || 0;
  };

  function createTransactionItem() {
    const data = {
      component: "",
      chargeType: "",
      details: "",
      chargeAmount: 0,
      rebateAmount: 0,
      totalAmountReceivable: 0,
      currentDue: 0,
      penaltyDue: 0,
      receivedAgainstCurrentDue: 0,
      amountReceived: 0,
    };
    setTransactions((prev) => [...prev, data]);
  }

  const filteredOptions = useMemo(() => {
    const usedChargeTypes = new Set(
      transactions.map((t) => t?.chargeType).filter(Boolean)
    );
    return data.reduce(
      (acc, item) => {
        if (item.hideInTable === false) {
          if (
            !usedChargeTypes.has(item?.title) &&
            !usedComponentIds.includes(item?.title)
          ) {
            acc.available.push(item);
          } else {
            acc.unavailable.push(item);
          }
        }
        return acc;
      },
      { available: [], unavailable: [] }
    );
  }, [data, transactions, usedComponentIds]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <TableContainer
        columns={[
          "CHARGE TYPE",
          "Charge Amount",
          "Total Amount Received",
          "Current Due",
          "PENALTY AMOUNT",
          "Discount AMOUNT",
          "Total Payable Amount",
          "RECEIVED AGAINST CURRENT DUE",
          "Action",
        ]}
        containerHeight={""}
        data={components}
      >
        {transactions?.map((transaction, index) => {
          return (
            <tr key={index}>
              <td>
                <Select
                  label={"Select Type"}
                  fullWidth={true}
                  value={transaction?.chargeType}
                  onChange={(e) =>
                    handleInputChange(index, "chargeType", e.target.value)
                  }
                >
                  {filteredOptions.available.map((item, idx) => (
                    <MenuItem key={`available-${idx}`} value={item?.title}>
                      {item?.title}
                    </MenuItem>
                  ))}
                  {filteredOptions.unavailable.map((item, idx) => (
                    <MenuItem
                      sx={{ display: "none" }}
                      disabled={true}
                      key={`unavailable-${idx}`}
                      value={item?.title}
                    >
                      {item?.title}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              <td>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  value={transaction.chargeAmount}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  value={transaction.amountReceived}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  value={transaction.currentDue}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <TextField
                  disabled={status === "CHECKING" || status === "PAID"}
                  fullWidth={true}
                  value={transaction.penaltyDue}
                  type="number"
                  onChange={(e) => {
                    handleInputChange(index, "penaltyDue", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <TextField
                  fullWidth={true}
                  disabled={status === "CHECKING" || status === "PAID"}
                  value={transaction.rebateAmount}
                  type="number"
                  onChange={(e) => {
                    handleInputChange(index, "rebateAmount", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <TextField
                  disabled={true}
                  fullWidth={true}
                  value={transaction.totalAmountReceivable}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>

              <td>
                <TextField
                  disabled={status === "CHECKING" ? false : true}
                  fullWidth={true}
                  value={transaction.receivedAgainstCurrentDue}
                  type="number"
                  onChange={(e) => {
                    handleInputChange(
                      index,
                      "receivedAgainstCurrentDue",
                      e.target.value
                    );
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  onClick={() => {
                    setTransactions((prev) =>
                      prev.filter((_, i) => i !== index)
                    );
                  }}
                >
                  <Tooltip title="Delete" placement="bottom" arrow>
                    <Delete />
                  </Tooltip>
                </IconButton>
              </td>
            </tr>
          );
        })}
        <tfoot
          style={{
            backgroundColor: "#e9ecef",
            fontWeight: "bold",
            borderTop: "2px solid #dee2e6",
            width: "100%",
          }}
        >
          <tr>
            <td style={{ padding: "16px", borderBottom: "1px solid #dee2e6" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Total:
              </Typography>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span></span>
            </td>
            {properties.map((property, index) => (
              <td
                key={index}
                style={{ padding: "16px", borderBottom: "1px solid #dee2e6" }}
              >
                <TextField
                  disabled
                  value={calculateTotal(property)}
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      backgroundColor: "white",
                      fontWeight: "bold",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 14px",
                      color: "#000",
                    },
                    "& .MuiInputAdornment-root": {
                      marginRight: "8px",
                    },
                  }}
                />
              </td>
            ))}
          </tr>
        </tfoot>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Button
          variant={"outlined"}
          color={"primary"}
          size="large"
          onClick={createTransactionItem}
          startIcon={<BiPlus />}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};

export default CreateTranscationTable;
