import React, { useEffect, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import Api from "../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import HorizBox from "../../styled/generic/HorizBox";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import StatusChip from "../../styled/generic/StatusChip";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import DemandDeedsList from "../demanddeed/DemandDeedsList";
import { EditOutlined } from "@material-ui/icons";
import { BiTrash } from "react-icons/bi";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import IssuesList from "./IssuesList";
import PaymentTable from "./PaymentTable";
import TransactionsList from "./TransactionsList";

const BookingView = () => {
  const history = useHistory();
  const { bookingId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [booking, setBooking] = useState();
  const [openConformationDialog, setOpenConformationDialog] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [template, setTemplate] = useState({});
  const [creatingTicket, setCreatingTicket] = useState(false);
  const [paymentComponents, setPaymentComponents] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const profileId = user.project;

  console.log("booking", booking);
  const getBooking = async () => {
    const { data } = await Api.post("/listing/booking/get-by-id", {
      bookingId: bookingId,
    });
    if (data) {
      setBooking(data);
      setTemplate(data?.team);
    } else {
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getBooking().finally(() => {
      setPageLoading(false);
    });
  }, []);

  const getPaymentTableComponents = async () => {
    const data = await Api.post("listing/booking/payment/component/get", {
      tableId: booking?.paymentTable?._id,
    });
    if (data) {
      setPaymentComponents(data.data);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong.",
        },
      });
    }
    setTableLoading(false);
  };

  useEffect(() => {
    if (booking?.paymentTable?._id) {
      getPaymentTableComponents();
    }
  }, [booking]);

  const handleDeleteBooking = async () => {
    const data = await Api.post("/listing/booking/delete", {
      bookingId: bookingId,
    });
    if (data) {
      history.goBack();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Booking and associated objects deleted successfully",
        },
      });
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const createApi = async () => {
    setCreatingTicket(true);
    const issueShared = template?.issueShared || [];
    const pipeline = template?.pipeline;
    const userProfileId = user?.profile;
    const sharedSet = new Set([...issueShared, profileId, userProfileId]);

    let issueObject = {
      user: user._id,
      template: template._id,
      profile: profileId,
      shared: Array.from(sharedSet),
      project: { _id: booking?.project },
      parent: booking._id,
      parentModelName: "Booking",
      shared: booking?.accountManagers?.map((item) => item._id),
      tags: [
        {
          data: booking._id,
          dataModel: "Booking",
        },
        {
          data: booking?.project,
          dataModel: "Project",
        },
        {
          data: booking?.library,
          dataModel: "BuildingCodeLibrary",
        },
      ].filter((item) => item.data),
    };

    if (pipeline?.length > 0) {
      issueObject = { ...issueObject, status: pipeline[0]?._id };
    }

    const res = await Api.post("issue/create", issueObject);
    const data = res?.data;
    const issueId = data?._id;
    const path = "/issue/edit/" + issueId;
    history.push(path);
  };

  const [tab, setTab] = useState("Table");
  if (pageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="85vh"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Booking"
      appBarActions={
        <HorizBox>
          <LoadingButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={createApi}
            loading={creatingTicket}
          >
            Add Issue
          </LoadingButton>
        </HorizBox>
      }
    >
      <StandardAppContainerRounded>
        <HorizBox>
          <SingleUserWithCaptionTableCell
            url={booking?.customerProfile?.parent?.displayPicture?.url}
            label={booking?.customerProfile?.parent?.displayName}
          />
          <StatusChip label="Sale" variant="Warning" rounded={true} />
          <StatusChip
            label={booking?.bookingStatus}
            variant="Completed"
            rounded={true}
          />

          <StatusChip
            variant="Info"
            label={`Invite code: ${booking?.inviteCode}`}
            rounded={true}
          />
        </HorizBox>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Listing Name
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {booking?.listing?.title}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Sanctioned/Unsanctioned
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {booking?.sanctioned ? "Sanctioned" : "Unsanctioned"}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Booking Date
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {new Date(booking?.bookingDate).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Closing Manager
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {booking?.closingManager?.parent?.displayName ?? "NaN"}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Registration Date
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              {new Date(booking?.registrationDate).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Aggreement value
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              ₹{booking?.aggreementValue ?? 0}
            </Typography>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" gap={"10px"}>
          <Button variant="outlined" color="primary">
            View More
          </Button>
          <IconButton
            onClick={() => {
              history.push(`/booking/edit/${bookingId}`);
            }}
            color="primary"
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenConformationDialog(true);
            }}
            color="primary"
          >
            <BiTrash />
          </IconButton>
        </Box>
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <TabsWithActions
          onTabChange={(val) => setTab(val)}
          currentTabVariable={tab}
          tabs={[
            {
              title: "Table",
            },
            {
              title: "Demand Deeds",
            },
            {
              title: "Transactions",
            },
            {
              title: "Issues",
            },
          ]}
        />
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        {tab === "Table" && (
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              width: "100%",
            }}
          >
            {tableLoading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "40vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} />
              </Box>
            ) : !paymentComponents.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <Typography color={"GrayText"}>
                  Payment table not found
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <PaymentTable
                  displayOnly={true}
                  setComponents={setPaymentComponents}
                  components={paymentComponents}
                  setPaymentDetails={() => {}}
                  bookingId={booking._id}
                  slabs={[]}
                  area={parseInt(booking?.area)}
                />
              </Box>
            )}
          </Box>
        )}
        {tab === "Demand Deeds" && <DemandDeedsList />}
        {tab === "Transactions" && (
          <TransactionsList
            bookingId={booking?._id}
            profileId={booking?.project?.profile?._id}
            booking={booking}
          />
        )}
        {tab === "Issues" && <IssuesList bookingId={booking?._id} />}
      </StandardAppContainerRounded>
      <ConfirmationDialog
        open={openConformationDialog}
        setOpen={setOpenConformationDialog}
        cancelButtonText={"Cancel"}
        cancelListener={() => {
          setOpenConformationDialog(false);
        }}
        successButtonText={"Confirm"}
        title={"Are you sure?"}
        successListener={handleDeleteBooking}
        message={"This action cannot revert back"}
      />
    </StandardContainer>
  );
};

export default BookingView;
