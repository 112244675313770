import React from "react";
import "./DocCustomActionBar.css";

const DocCustomActionBar = (
    state,
    previousDocument,
    nextDocument
) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
        return null;
    }

    return (
        <div className="actionbar">
            <div className="title">
                {state?.currentDocument?.name || ""}
                <div className="index">
                    {state.currentFileNo + 1 + " of " + state.documents.length}
                </div>
            </div>
            <div className="controls">
                <button
                    className="prev"
                    onClick={previousDocument}
                    disabled={state.currentFileNo === 0}
                >
                    Previous Document
                </button>
                <button
                    className="next"
                    onClick={nextDocument}
                    disabled={state.currentFileNo >= state.documents.length - 1}
                >
                    Next Document
                </button>
            </div>
        </div>
    );
};
export default DocCustomActionBar;