import { Box, Button, IconButton } from "@mui/material";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CreateTranscationTable from "../../transaction/CreateTranscationTable";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BankAccountPicker from "../../../styled/generic/BankAccountPickerDropdown";
import { Delete } from "@material-ui/icons";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Close } from "@mui/icons-material";

const TransactionSection = ({
  bookingId,
  components,
  index,
  setTransactions,
  usedComponentIds,
  initialData = {},
}) => {
  console.log(initialData);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [billItems, setBillItems] = useState(initialData.billItems || []);
  const [selectedBankAccount, setSelectedBankAccount] = useState(
    initialData.transactionData?.bankAccountId || ""
  );
  const [subject, setSubject] = useState(
    initialData.transactionData?.subject || ""
  );
  const [initialBank, setInitialBank] = useState(
    initialData.transactionData?.bankAccountId
      ? { bankName: "Initial Bank" }
      : null
  );

  const handleSave = () => {
    const newTransaction = {
      transactionData: {
        booking: bookingId,
        subject: subject,
        bankAccountId: selectedBankAccount,
        paymentStatus: "draft",
        status: "draft",
        profile: user?.profile,
        paymentDue: billItems.reduce(
          (acc, item) => acc + parseInt(item.currentDue || 0, 10),
          0
        ),
        amountPaid: billItems.reduce(
          (acc, item) =>
            acc + parseInt(item.receivedAgainstCurrentDue || 0, 10),
          0
        ),
        amountPayable: billItems.reduce(
          (acc, item) => acc + parseInt(item.totalAmountReceivable || 0, 10),
          0
        ),
      },
      billItems: billItems,
    };

    setTransactions((prev) =>
      prev.map((transaction, i) => (i === index ? newTransaction : transaction))
    );
  };

  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      handleSave();
    }
  }, [billItems, subject, selectedBankAccount]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          borderRadius: "15px",
          boxShadow: "2px 1px 8px 0px #3465FF1F",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "10px",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              flex: 3,
            }}
          >
            <Typography variant="h5">Transaction {index + 1}</Typography>
            <TextField
              placeholder="Enter subject"
              fullWidth="true"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Subject</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              paddingRight: `10px`,
              flex: 1,
            }}
          >
            <Typography variant="h6">Select Bank Account</Typography>
            {initialBank ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "3px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <AccountBalanceIcon />
                <Typography>{initialBank?.bankName}</Typography>
                <IconButton onClick={() => setInitialBank(null)}>
                  <Close />
                </IconButton>
              </Box>
            ) : (
              <BankAccountPicker
                selectedBankAccount={selectedBankAccount}
                setSelectedBankAccount={(id) => {
                  setSelectedBankAccount(id);
                  setInitialBank(null); // Clear initialBank when a new account is selected
                }}
              />
            )}
          </Box>
          <IconButton
            onClick={() =>
              setTransactions((prev) =>
                prev.filter((_, transactionIndex) => index !== transactionIndex)
              )
            }
          >
            <Delete />
          </IconButton>
        </Box>

        <CreateTranscationTable
          setTransactions={setBillItems}
          transactions={billItems}
          bookingId={bookingId}
          components={components}
          usedComponentIds={usedComponentIds}
        />
      </Box>
    </Box>
  );
};

export default TransactionSection;
