import React from "react";
import { Box, Typography, Grid, Paper, Divider } from "@mui/material";
import {
  TrendingUp,
  TrendingDown,
  CurrencyRupee,
  Percent,
  LocalMall,
} from "@mui/icons-material";
import { PiBookOpenLight, PiSelectionBackground } from "react-icons/pi";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  Area,
  YAxis,
  Tooltip,
  AreaChart,
  ResponsiveContainer,
} from "recharts";

const getIcon = (change) => {
  const sx = {
    fontSize: 20,
    color: "primary.main",
  };
  switch (change) {
    case 0:
      return <LocalMall sx={sx} />;
    case 1:
      return <CurrencyRupee sx={sx} />;
    case 2:
      return <Percent sx={sx} />;
    case 3:
      return <CurrencyRupee sx={sx} />;
    default:
      return null;
  }
};

const MetricCard = ({ title, value, change, unit, index }) => (
  <Paper elevation={2} sx={{ p: 2, height: "100%" }}>
    <Box
      display={"flex"}
      alignItems={"start"}
      justifyContent={"space-between"}
      mb={"0.5rem"}
    >
      <Box>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="h4"
          component="div"
          fontWeight="bold"
          sx={{ mb: 1 }}
        >
          {unit}
          {value}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: 1,
          borderRadius: "50%",
          border: "1px solid",
          borderColor: "#bfdbfe",
          boxShadow: "0px 0px 5px 0px #bfdbfe",
        }}
      >
        {getIcon(index)}
      </Box>
    </Box>
    <Box display="flex" alignItems="center">
      {parseFloat(change) > 0 ? (
        <TrendingUp
          size={16}
          sx={{
            color: "green",
          }}
        />
      ) : (
        <TrendingDown
          size={16}
          sx={{
            color: "red",
          }}
        />
      )}
      <Typography
        variant="body2"
        color={parseFloat(change) > 0 ? "success.main" : "error.main"}
        sx={{ ml: 0.5 }}
      >
        {change}% from last month
      </Typography>
    </Box>
  </Paper>
);

const BookingAnalytics = () => {
  const metrics = [
    { title: "Total Bookings", value: "1,234", change: "+10", unit: "" },
    { title: "Total Revenue", value: "125,678", change: "+15", unit: "₹" },
    {
      title: "Occupancy Rate",
      value: "82",
      change: "-5",
      unit: "",
      suffix: "%",
    },
    { title: "Average Daily Rate", value: "150", change: "+3", unit: "₹" },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" fontWeight={500} gutterBottom>
        Booking Analytics
      </Typography>
      <Grid container spacing={1}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <MetricCard
              index={index}
              title={metric.title}
              value={metric.value + (metric.suffix || "")}
              change={metric.change}
              unit={metric.unit}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: "1rem" }} />
      <Grid container spacing={2} gridColumn={12}>
        {Array(10)
          .fill()
          .map((_, i) => (
            <Grid item xs={12} sm={6} md={6} key={i} sx={{}}>
              <Paper
                elevation={2}
                variant="elevation"
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "1rem",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mb: "1rem",
                  }}
                >
                  Bookings
                </Typography>
                <BookingCharts />
              </Paper>
            </Grid>
          ))}
        <Grid item xs={12} sm={6} md={8} sx={{}}>
          <Paper
            elevation={2}
            variant="elevation"
            sx={{
              width: "100%",
              height: "100%",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: "1rem",
              }}
            >
              Bookings
            </Typography>
            <BookingCharts />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingAnalytics;

const BookingCharts = () => {
  const data = generateFakeData(5);
  const xAxis = [
    {
      dataKey: "name",
      tick: "custom",
    },
  ];
  return (
    <ResponsiveContainer width="100%" height={280}>
      <AreaChart data={data}>
        <Line type="linear" dataKey="uv" stroke="#3b82f6" />
        <XAxis dataKey="name" />
        <YAxis />
        <Area type="linear" dataKey="pv" stroke="#82ca9d" fill="#3b82f6" />
        <Tooltip />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const generateFakeData = (numPoints) => {
  const data = [];
  for (let i = 0; i < numPoints; i++) {
    data.push({
      name: `Page ${String.fromCharCode(65 + i)}`,
      uv: Math.floor(Math.random() * 1000),
      pv: Math.floor(Math.random() * 3000),
      amt: Math.floor(Math.random() * 3000),
    });
  }
  return data;
};

const renderCustomAxisTick = ({ x, y, payload }) => {
  let path = "";

  switch (payload.value) {
    case "Page A":
      path =
        "M899.072 99.328q9.216 13.312 17.92 48.128t16.384 81.92 13.824 100.352 11.264 102.912 9.216 90.112 6.144 60.928q4.096 30.72 7.168 70.656t5.632 79.872 4.096 75.264 2.56 56.832q-13.312 16.384-30.208 25.6t-34.304 11.264-34.304-2.56-30.208-16.896q-1.024-10.24-3.584-33.28t-6.144-53.76-8.192-66.56-8.704-71.68q-11.264-83.968-23.552-184.32-7.168 37.888-11.264 74.752-4.096 31.744-6.656 66.56t-0.512 62.464q1.024 18.432 3.072 29.184t4.608 19.968 5.12 21.504 5.12 34.304 5.12 56.832 4.608 90.112q-11.264 24.576-50.688 42.496t-88.576 29.696-97.28 16.896-74.752 5.12q-18.432 0-46.08-2.56t-60.416-7.168-66.048-12.288-61.952-17.92-49.664-24.064-28.16-30.208q2.048-55.296 5.12-90.112t5.632-56.832 5.12-34.304 5.12-21.504 4.096-19.968 3.584-29.184q2.048-27.648-0.512-62.464t-6.656-66.56q-4.096-36.864-11.264-74.752-13.312 100.352-24.576 184.32-5.12 35.84-9.216 71.68t-8.192 66.56-6.656 53.76-2.56 33.28q-13.312 12.288-30.208 16.896t-34.304 2.56-33.792-11.264-29.696-25.6q0-21.504 2.048-56.832t4.096-75.264 5.632-79.872 6.656-70.656q2.048-20.48 6.144-60.928t9.728-90.112 11.776-102.912 13.824-100.352 16.384-81.92 17.92-48.128q20.48-12.288 56.32-25.6t73.216-26.624 71.168-25.088 50.176-22.016q10.24 13.312 16.896 61.44t13.312 115.712 15.36 146.432 23.04 153.6l38.912-334.848-29.696-25.6 43.008-54.272 15.36 2.048 15.36-2.048 43.008 54.272-29.696 25.6 38.912 334.848q14.336-74.752 23.04-153.6t15.36-146.432 13.312-115.712 16.896-61.44q16.384 10.24 50.176 22.016t71.168 25.088 73.216 26.624 56.32 25.6";
      break;
    case "Page B":
      path =
        "M662.528 451.584q10.24 5.12 30.208 16.384t46.08 31.744 57.856 52.736 65.024 80.896 67.072 115.2 64.512 154.624q-15.36 9.216-31.232 21.504t-31.232 22.016-31.744 15.36-32.768 2.56q-44.032-9.216-78.336-8.192t-62.976 7.68-53.248 16.896-47.616 19.968-46.08 16.384-49.664 6.656q-57.344-1.024-110.592-16.896t-101.376-32.256-89.6-25.088-75.264 4.608q-20.48 8.192-41.984 1.024t-38.912-18.432q-20.48-13.312-39.936-33.792 37.888-116.736 86.016-199.68t92.672-136.704 78.848-81.408 43.52-33.792q9.216-5.12 10.24-25.088t-1.024-40.448q-3.072-24.576-9.216-54.272l-150.528-302.08 180.224-29.696q27.648 52.224 53.76 79.36t50.176 36.864 45.568 5.12 39.936-17.92q43.008-30.72 80.896-103.424l181.248 29.696q-20.48 48.128-45.056 99.328-20.48 44.032-47.616 97.28t-57.856 105.472q-12.288 34.816-13.824 57.344t1.536 36.864q4.096 16.384 12.288 25.6z";
      break;
    default:
      path = "";
  }

  return (
    <svg
      x={x - 12}
      y={y + 4}
      width={24}
      height={24}
      viewBox="0 0 1024 1024"
      fill="#666"
    >
      <path d={path} />
    </svg>
  );
};
