import React, { useState, useEffect, useMemo } from "react";
import {
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
  Box,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { BiPlus } from "react-icons/bi";
import TableContainer from "../../styled/generic/TableContainer";

const DemandDeedSettingTable = ({
  usedComponentIds,
  components = [],
  billItems: initialBillItems,
  onBillItemsChange,
}) => {
  const [billItems, setBillItems] = useState(initialBillItems || [{}]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (components.length > 0) {
      const newData = components.map((component) => {
        return {
          chargeType: component.title || "",
          penaltyAmount: component.penaltyAmount || 0,
          rebateAmount: component.rebateAmount || 0,
          component: component._id,
          hideInTable: component.hideInTable,
        };
      });
      setData(newData.filter((item) => !item?.hideInTable));
    }
  }, [components]);

  const filteredOptions = useMemo(() => {
    const usedChargeTypes = new Set(
      billItems?.map((t) => t?.component).filter(Boolean)
    );
    return data.reduce(
      (acc, item) => {
        if (item.hideInTable === false) {
          if (
            !usedChargeTypes?.has(item?.component) &&
            !usedComponentIds?.includes(item?.component)
          ) {
            acc.available.push(item);
          } else {
            acc.unavailable.push(item);
          }
        }
        return acc;
      },
      { available: [], unavailable: [] }
    );
  }, [data, billItems, usedComponentIds]);

  const handleInputChange = (index, field, value) => {
    setBillItems((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: field === "component" ? value : parseFloat(value) || value,
      };
      return updatedData;
    });
  };

  const createBillItem = () => {
    setBillItems((prev) => [
      ...prev,
      {
        chargeType: "",
        penaltyAmount: 0,
        component: "",
        rebateAmount: 0,
      },
    ]);
  };

  useEffect(() => {
    onBillItemsChange(billItems);
  }, [billItems]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <TableContainer
        columns={["CHARGE TYPE", "Penalty Amount", "Discount AMOUNT", "Action"]}
        data={billItems}
        containerHeight=""
      >
        {billItems.map((billItem, index) => {
          console.log("billitems", billItem);
          return (
            <tr key={index}>
              <td>
                <Select
                  label="Select Type"
                  fullWidth
                  value={billItem.component._id || billItem.component}
                  defaultValue={billItem.component._id || billItem.component}
                  onChange={(e) => {
                    console.log(e.target.value);
                    handleInputChange(index, "component", e.target.value);
                  }}
                >
                  {filteredOptions.available.map((item, idx) => {
                    return (
                      <MenuItem
                        key={`available-${idx}`}
                        value={billItem.component._id || item.component}
                      >
                        {billItem.component.title || item.chargeType}
                      </MenuItem>
                    );
                  })}
                  {filteredOptions.unavailable.map((item, idx) => (
                    <MenuItem
                      key={`available-${idx}`}
                      value={item.component}
                      sx={{
                        display: "none",
                      }}
                    >
                      {item.chargeType}
                    </MenuItem>
                  ))}
                </Select>
              </td>
              <td>
                <TextField
                  fullWidth
                  value={billItem.penaltyAmount}
                  type="number"
                  onChange={(e) =>
                    handleInputChange(index, "penaltyAmount", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <TextField
                  fullWidth
                  value={billItem.rebateAmount}
                  type="number"
                  onChange={(e) =>
                    handleInputChange(index, "rebateAmount", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
              <td>
                <IconButton
                  onClick={() => {
                    setBillItems((prev) => prev.filter((_, i) => i !== index));
                  }}
                >
                  <Tooltip title="Delete" placement="bottom" arrow>
                    <Delete />
                  </Tooltip>
                </IconButton>
              </td>
            </tr>
          );
        })}
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={createBillItem}
          startIcon={<BiPlus />}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};

export default DemandDeedSettingTable;
